// @flow

export default Object.freeze({
  blog: 'https://www.getatomi.com/blog',
  careers: 'https://www.getatomi.com/careers',
  contact: 'https://www.getatomi.com/contact',
  feedback: {
    tasks: 'https://getatomi.typeform.com/to/RhKr7RCZ',
  },
  legacyLogin: 'https://app.getatomi.com/login',
  terms: 'https://www.getatomi.com/terms-and-conditions',
  subscriptionAgreement: 'https://www.getatomi.com/subscription-agreement',
  support: {
    addUsersToAccount: 'https://support.getatomi.com/en/articles/1371914-adding-users-to-your-school-account',
    bulkAddStudentsToClass: 'https://support.getatomi.com/for-schools/getting-started/bulk-adding-students-to-a-class',
    bulkImportUsers:
      'https://support.getatomi.com/for-schools/user-management/bulk-importing-users-to-your-school-account',
    cancellingSubscription: 'https://support.getatomi.com/en/articles/1312605-how-do-i-cancel-my-subscription',
    cancellingFamilySubscription:
      'http://support.getatomi.com/en/articles/5431498-how-do-i-cancel-my-family-subscription',
    creatingRevision: 'https://support.getatomi.com/en/articles/4379647-creating-a-revision-session',
    credit: 'https://support.getatomi.com/faqs/billing-and-subscriptions/how-do-credits-work',
    deleteAccount: 'https://support.getatomi.com/faqs/settings/how-do-i-delete-my-account',
    downloadData: 'https://support.getatomi.com/security-privacy-and-trust/how-do-i-download-my-data',
    home: 'https://support.getatomi.com',
    insights: {
      school: 'https://support.getatomi.com/en/articles/4166381-atomi-insights-for-teachers-and-schools',
      student: 'https://support.getatomi.com/en/articles/4166386-atomi-insights-for-students',
      parent: 'https://support.getatomi.com/en/articles/5378535-atomi-insights-for-parents',
    },
    legacyLogin: 'https://support.getatomi.com/for-schools/getting-started/issues-logging-into-the-new-atomi-platform',
    markbook: 'https://support.getatomi.com/en/articles/7212893-getting-started-with-mark-book',
    progress: 'https://support.getatomi.com/en/articles/2688724-informing-your-teaching-with-data',
    refundPolicy: 'https://support.getatomi.com/en/articles/1312608-what-is-your-refund-policy',
    registrationStates: 'https://support.getatomi.com/faqs/general/dont-see-your-state-during-registration',
    schoolRolesAndPermissions:
      'https://support.getatomi.com/for-schools/user-management/roles-and-permissions-on-a-school-account',
    subjects: 'https://support.getatomi.com/faqs/settings/cant-find-your-subject',
    subjectsLimit:
      'https://support.getatomi.com/for-students/settings/why-can-i-only-pick-a-certain-amount-of-subjects-for-my-account',
    subscriptions: 'https://support.getatomi.com/for-students',
    upgrading: {
      school: 'https://support.getatomi.com/for-schools/how-do-i-upgrade-to-a-school-account',
      student: 'https://support.getatomi.com/en/articles/1312604-how-do-i-upgrade-my-free-student-account',
      parent: 'https://support.getatomi.com/en/articles/5378534-how-do-i-upgrade-my-family-account',
    },
    setupMode: 'https://support.getatomi.com/for-schools/getting-started/setup-mode',
    switchingBetweenAccounts:
      'https://support.getatomi.com/security-privacy-and-trust/switching-between-multiple-accounts',
    task: {
      creating: 'https://support.getatomi.com/en/articles/2727178-assigning-lessons-to-your-students',
      drafting: 'https://support.getatomi.com/en/articles/6549575-saving-a-task-as-a-draft',
      scheduling: 'https://support.getatomi.com/en/articles/6549565-scheduling-a-task',
    },
    technologyRequirements:
      'https://support.getatomi.com/faqs/technical/what-are-the-technology-requirements-to-use-atomi',
    validatingEmail:
      'https://support.getatomi.com/en/articles/1312620-i-haven-t-received-a-confirmation-or-validation-email-upon-sign-up-what-do-i-do',
    joiningYourSchoolAccount: 'https://support.getatomi.com/en/articles/3291828-joining-your-school-account-on-atomi',
    joiningYourFamilyAccount: 'http://support.getatomi.com/en/articles/5464942-joining-your-family-account-on-atomi',
    // @TODO update "samlLogin" link when article is published
    samlLogin:
      'https://support.getatomi.com/en/articles/5123022-resetting-your-password-when-using-your-school-login-details-on-atomi',
    parentUsersLimit: 'https://support.getatomi.com/en/articles/5378558-how-do-family-accounts-work',
  },
});
