// @flow
import { useState } from 'react';
import invariant from 'invariant';
import { useQuery } from '@apollo/client';
import Helmet from 'react-helmet';

import type { GetMarkbookTasks, GetMarkbookTasksVariables } from 'src/graphql/types/generated/GetMarkbookTasks';
import type {
  DateRangeInput,
  GetMarkbookExpandedTasks,
  GetMarkbookExpandedTasksVariables,
} from 'src/graphql/types/generated/GetMarkbookExpandedTasks';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import taskPublicationStatuses from 'src/constants/taskPublicationStatuses';

import MarkbookLoader from '../components/MarkbookLoader';
import MarkbookControls from '../components/MarkbookControls';
import renderEmptyState from '../utils/renderEmptyState';
import { transformTasksData } from './tasksTransformer';
import TasksView from './TasksView';
import GET_MARKBOOK_EXPANDED_TASKS from './GetMarkbookExpandedTasks.graphql';
import GET_MARKBOOK_TASKS from './GetMarkbookTasks.graphql';

type Props = {
  params: {
    classId: string,
    subscriptionId: string,
  },
};

export default function TasksContainer(props: Props) {
  const { params } = props;
  const { classId, subscriptionId: accountId } = params;

  const expandedColumnState = useState<?DateRangeInput>(null);
  const [expandedPeriodRange, setExpandedPeriodRange] = expandedColumnState;
  const hasExpandedColumn = expandedPeriodRange !== null;
  const variables: GetMarkbookTasksVariables = {
    accountId,
    classId,
    filters: {
      includeInvited: false,
    },
  };

  const { data, error, loading } = useQuery<GetMarkbookTasks, GetMarkbookTasksVariables>(GET_MARKBOOK_TASKS, {
    variables,
  });

  const {
    data: tasksData,
    error: tasksError,
    loading: tasksLoading,
  } = useQuery<GetMarkbookExpandedTasks, GetMarkbookExpandedTasksVariables>(GET_MARKBOOK_EXPANDED_TASKS, {
    skip: !hasExpandedColumn,
    variables: {
      accountId,
      classId,
      filters: {
        status: taskPublicationStatuses.published,
        dateDue: expandedPeriodRange,
      },
    },
  });

  const documentTitle = (
    <Helmet>
      <title>Task completion | Mark book</title>
    </Helmet>
  );

  if (loading && !data) {
    return (
      <>
        {documentTitle}
        <MarkbookControls isLoading params={props.params} activeLink="tasks" />
        <MarkbookLoader assistiveText="Loading the list of students and their task completion data." />
      </>
    );
  }

  if (error) {
    return (
      <>
        {documentTitle}
        <MarkbookControls isErrorState params={props.params} activeLink="tasks" />
        <GraphQLError error={error} description="We couldn’t load the Mark Book." />
      </>
    );
  }

  const accountData = data?.me?.account;
  const classData = accountData?.class;

  invariant(accountData && classData, 'Tasks account and class should be defined');
  invariant(classData.metrics, 'Tasks metrics should be defined');
  invariant(classData.students, 'Tasks students should be defined');

  const expandedAccountData = tasksData?.me?.account;
  const expandedClassData = expandedAccountData?.class;

  if (hasExpandedColumn && !tasksLoading && !tasksError) {
    invariant(expandedAccountData && expandedClassData, 'Class data should be defined in tasks response');
  }
  const expandedColumn = {
    periodRange: expandedPeriodRange,
    tasks: expandedClassData ? expandedClassData.tasks.edges.map((edge) => edge.node) : [],
  };

  if (tasksError) {
    setExpandedPeriodRange(null);
  }

  const preparedData = transformTasksData({
    accountData,
    classData,
    expandedColumn,
  });

  const { isClassEmpty, isFreePlan, region } = preparedData;
  const emptyState = renderEmptyState(isClassEmpty, isFreePlan, params, region);

  return (
    <>
      {documentTitle}
      {emptyState ?? (
        <TasksView
          className={preparedData.className}
          expandedColumnState={expandedColumnState}
          params={props.params}
          preparedData={preparedData}
        />
      )}
    </>
  );
}
