// @flow
import keyMirror from 'keymirror';

// Maximum file size for task attachments (1e8 ≅ 100MB)
export const taskUploadMaxFileSize = 1e8;

export const taskAttachmentTypes = keyMirror({
  FILE: null,
  LINK: null,
});
