// @flow
import { Box, Breadcrumbs, BreadcrumbsItem } from '@getatomi/neon';

import SubjectBadge from 'src/components/SubjectBadge/SubjectBadge';
import { getClassUrl } from 'src/utils/routes';
import { trackingEvents, trackingCtas } from 'src/constants/tracking';
import { trackEvent } from 'src/utils/tracking';

type ClassBreadcrumbItemProps = {
  currentClass: {
    id: string,
    name: string,
  },
  isRetailPlan: boolean,
  subject: {
    color: string,
    groupCode: string,
    name: string,
  },
  subscriptionId: string,
};

type ClassBreadcrumbsProps = ClassBreadcrumbItemProps & {
  children?: React.Node,
};

function ClassBreadcrumbItem(props: ClassBreadcrumbItemProps) {
  const { currentClass, isRetailPlan, subject, subscriptionId } = props;

  const classUrl = getClassUrl(subscriptionId, currentClass.id);

  if (isRetailPlan) {
    return <BreadcrumbsItem href={classUrl}>{subject.name}</BreadcrumbsItem>;
  }

  return (
    <BreadcrumbsItem
      href={classUrl}
      insertBefore={
        <Box as="span" marginRight="spacingSmall1X">
          <SubjectBadge color={subject.color} groupCode={subject.groupCode}>
            {subject.name}
          </SubjectBadge>
        </Box>
      }
    >
      {currentClass.name}
    </BreadcrumbsItem>
  );
}

export default function ClassBreadcrumbs(props: ClassBreadcrumbsProps) {
  const { children, ...rest } = props;

  function handleBreadcrumbClicked({ count, index }: { count: number, index: number }) {
    trackEvent(trackingEvents.ctaClicked, {
      cta: trackingCtas.breadcrumb,
      item: index + 1,
      totalItems: count,
    });
  }

  return (
    <Breadcrumbs onAction={handleBreadcrumbClicked}>
      <ClassBreadcrumbItem {...rest} />
      {children}
    </Breadcrumbs>
  );
}
