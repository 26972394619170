// @flow
import { Box, EmptyState, Illustration } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import links from 'src/constants/links';

import type { TaskStatus } from './useGetClassTasks';

type Props = {
  status?: TaskStatus,
};

export default function ClassTasksListEmptyState(props: Props) {
  const { status } = props;

  if (status === 'scheduled') {
    return (
      <Box marginTop="spacingLarge3X">
        <EmptyState
          media={<Illustration name="emptystate-tasks" />}
          description="There are no scheduled tasks right now."
          helpInfo={
            <Link href={links.support.task.scheduling} isExternal variant="monochrome">
              Learn how to schedule a task.
            </Link>
          }
        />
      </Box>
    );
  }

  if (status === 'draft') {
    return (
      <Box marginTop="spacingLarge3X">
        <EmptyState
          media={<Illustration name="emptystate-tasks" />}
          description="There are no drafts right now."
          helpInfo={
            <Link href={links.support.task.drafting} isExternal variant="monochrome">
              Learn more about drafts.
            </Link>
          }
        />
      </Box>
    );
  }

  return (
    <Box marginTop="spacingLarge3X">
      <EmptyState
        media={<Illustration name="emptystate-tasks" />}
        description="There are no published tasks right now."
      />
    </Box>
  );
}
