// @flow
import type {
  GetClassTasks_me_account_class_tasks_edges_node_contents as InputContent,
  GetClassTasks_me_account_class_tasks_edges_node_attachments as InputAttachment,
} from 'src/graphql/types/generated/GetClassTasks';
import type { GetClassTask_me_account_class_tasks_edges_node_contents as IndividualPageContent } from 'src/graphql/types/generated/GetClassTask';
import { getPostUrl, getRevisionUrl } from 'src/utils/routes';
import type { ChallengeTier, PostType } from 'src/types';
import postTypes from 'src/constants/postTypes';

import type { TaskAttachment } from '../types';

export type ClassTaskContent = {
  countAssignedStudents: number,
  countInProgressStudents: number,
  duration: number,
  id: string,
  moduleId: ?string,
  name: string,
  tier?: ChallengeTier,
  type: PostType,
  url: string,
  viewer: 'teacher',
};

export type TaskStatus = 'draft' | 'published' | 'scheduled';

export function attachmentTransformer(attachment: InputAttachment): TaskAttachment {
  return {
    externalId: attachment.externalId || '',
    fileName: attachment.fileName || '',
    id: attachment.id,
    isInfected: attachment.isInfected ?? false,
    title: attachment.title,
    type: attachment.type,
    url: attachment.url,
  };
}

export function contentTransformer({
  content,
  countAssignedStudents,
  subscriptionId,
}: {
  content: InputContent | IndividualPageContent,
  countAssignedStudents: number,
  subscriptionId: string,
}): ClassTaskContent {
  const transformedTypename = content.__typename
    .toLowerCase()
    .replace('locked', '')
    .replace('class', '')
    .replace('lesson', '');
  const type: PostType = postTypes[transformedTypename];

  let tier;
  if (content.__typename === 'ClassChallengeLesson') {
    tier = content.tier;
  }

  let moduleId = null;
  let url = '';
  let countInProgressStudents = 0;
  let name = '';
  if (
    content.__typename === 'ClassChallengeLesson' ||
    content.__typename === 'ClassTextLesson' ||
    content.__typename === 'ClassVideoLesson'
  ) {
    name = content.name;
    moduleId = content.categories[0]?.id;
    url = getPostUrl(subscriptionId, content.classId, moduleId, content.id);
    countInProgressStudents = content.metrics.progress.engagedStudentCount;
  }

  let { duration } = content;
  if (content.__typename === 'ClassRevision') {
    name = content.title;
    url = getRevisionUrl(subscriptionId, content.classId, content.id);
    countInProgressStudents = content.metrics.progress.engagedStudentCount;

    if (content.timeLimitInSeconds) {
      duration = content.timeLimitInSeconds;
    }
  }

  return {
    countInProgressStudents,
    countAssignedStudents,
    duration,
    id: content.id,
    moduleId,
    name,
    tier,
    type,
    url,
    viewer: 'teacher',
  };
}
