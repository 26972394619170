import { Text } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import links from 'src/constants/links';

export default function ForgotPasswordHelp() {
  return (
    <Text
      as="p"
      variant="bodyRoot"
      color="colorTextInverted"
      marginTop="spacingLarge7X"
      marginBottom={{ base: 'spacingNone', tablet: 'spacingLarge6X' }}
    >
      If you still need help,{' '}
      <Link href={links.support.home} isExternal variant="inverted">
        contact support
      </Link>
      .<br />
      <Link to="/login" variant="inverted">
        Back to login
      </Link>
      .
    </Text>
  );
}
