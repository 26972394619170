// @flow
import type { Index } from 'algoliasearch';
import { useCallback, useRef, useState, useEffect } from 'react';

import Logger from 'src/utils/Logger';

const log = new Logger('hooks/useSearchIndex');

export default function useSearchIndex(name: string) {
  const [error, setError] = useState<?{ error: Error }>(null);
  const [isReady, setIsReady] = useState<boolean>(false);
  const index = useRef<?Index>(null);

  const load = useCallback(async () => {
    try {
      const algoliasearch = (await import(/* webpackChunkName: "algoliasearch" */ 'algoliasearch')).default;
      const client = algoliasearch(process.env.NEXT_PUBLIC_ALGOLIA_APP_ID, process.env.NEXT_PUBLIC_ALGOLIA_API_KEY);
      setError(null);
      index.current = client.initIndex(name);
      setIsReady(true);
    } catch (err) {
      setError(err);
      log.error('Error loading algolia', err);
    }
  }, [name]);

  useEffect(() => {
    load();
  }, [load]);

  return {
    error,
    index,
    isReady,
    retry: load,
  };
}
