// @flow
import { useMemo, useRef } from 'react';

import { TabNav, Item } from 'src/components/TabNav/TabNav';
import { getClassTasksUrl } from 'src/utils/routes';

import type { TaskCounts } from './useGetClassTasks';

type Props = {|
  classId: string,
  counts: ?TaskCounts,
  subscriptionId: string,
|};

function withCount(name: string, count: ?number) {
  return count ? `${name} (${count})` : name;
}

export default function ClassTasksNav(props: Props) {
  const { classId, counts, subscriptionId } = props;

  // Store counts in a ref to avoid re-rendering with null values
  // when switching between tabs
  const cachedCounts = useRef(counts);
  if (counts) {
    cachedCounts.current = counts;
  }

  const items = useMemo(
    () => [
      { href: getClassTasksUrl(subscriptionId, classId), name: 'Published' },
      {
        href: getClassTasksUrl(subscriptionId, classId, 'scheduled'),
        name: withCount('Scheduled', cachedCounts.current?.scheduled),
      },
      {
        href: getClassTasksUrl(subscriptionId, classId, 'draft'),
        name: withCount('Drafts', cachedCounts.current?.draft),
      },
    ],
    [classId, counts, subscriptionId] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <TabNav ariaLabel="Class tasks navigation" items={items} variant="secondary">
      {items.map((item) => (
        <Item key={item.href} href={item.href} routerOptions={{ state: { refocus: false, scrollTop: false } }}>
          {item.name}
        </Item>
      ))}
    </TabNav>
  );
}
