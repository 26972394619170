// @flow
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

import possibleTypes from '../generated/possibleTypes';
import apolloTypePolicies from '../apolloTypePolicies';
import { createLinks } from './createLinks';

if (__DEVELOPMENT__) {
  loadDevMessages();
  loadErrorMessages();
}

export const cache = new InMemoryCache({
  possibleTypes,
  typePolicies: apolloTypePolicies(),
});

const client = new ApolloClient<any>({
  name: '@app/carbon',
  version: process.env.NEXT_PUBLIC_PLATFORM_VERSION,
  link: createLinks({ uri: process.env.NEXT_PUBLIC_FEDERATED_GRAPH_URI }),
  cache,
  assumeImmutableResults: true,
  connectToDevTools: process.env.NEXT_PUBLIC_PLATFORM_ENV !== 'production',
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
  },
});

export default client;
