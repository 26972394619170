// @flow
import { pickBy } from 'lodash';

import isoCodes from './isoCodes';

const regions = {
  nsw: { code: 'nsw', countryCode: isoCodes.au, name: 'New South Wales', allowRegistration: true },
  au_qld: { code: 'au_qld', countryCode: isoCodes.au, name: 'Queensland', allowRegistration: true },
  au_sa: { code: 'au_sa', countryCode: isoCodes.au, name: 'South Australia', allowRegistration: true },
  au_vic: { code: 'au_vic', countryCode: isoCodes.au, name: 'Victoria', allowRegistration: true },
  au_tas: { code: 'au_tas', countryCode: isoCodes.au, name: 'Tasmania', allowRegistration: true },
  au_wa: { code: 'au_wa', countryCode: isoCodes.au, name: 'Western Australia', allowRegistration: true },
  sg: { code: 'sg', countryCode: isoCodes.sg, name: 'Singapore', allowRegistration: false },
  uk: { code: 'uk', countryCode: isoCodes.gb, name: 'UK', allowRegistration: false },
  ca_on: { code: 'ca_on', countryCode: isoCodes.ca, name: 'Ontario', allowRegistration: true },
};

export default Object.freeze(regions);

// Used to short list dropdown fields such as with the phone input
export const preferredCountries = [isoCodes.au, isoCodes.ca];

export const registrationRegions = pickBy(regions, (region) => region.allowRegistration);
