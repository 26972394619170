// @flow
import { Box, Container, Text, Skeleton } from '@getatomi/neon';
import _ from 'lodash';

import getSubjectColors from 'src/utils/getSubjectColors';

import styles from './TaskList.module.scss';

type RequiredSubjectProps = {
  color: string,
  groupCode: string,
};

type TaskListSectionProps<TTask> = {|
  renderTask: (task: TTask) => React.Node,
  tasks: $ReadOnlyArray<
    TTask & {
      id: string,
      subject: $PropertyType<TTask, 'subject'> & RequiredSubjectProps,
    }
  >,
  title: string,
|};

function TaskListSection<TTask>(props: TaskListSectionProps<TTask>) {
  const { renderTask, tasks, title } = props;

  return (
    <Box as="li" className={styles.section}>
      {title && (
        <Box className={styles.sectionTitle}>
          <Text as="h2" fontSize="fontSizeSmall2X" fontWeight="fontWeightSemiBold" textTransform="uppercase">
            {title}
          </Text>
        </Box>
      )}
      {tasks.map((task) => {
        const indicatorColor = getSubjectColors(task.subject.groupCode, task.subject.color).icon;
        return (
          <Box
            className={styles.task}
            _after={{
              backgroundColor: indicatorColor,
            }}
            key={task.id}
          >
            {renderTask(task)}
          </Box>
        );
      })}
    </Box>
  );
}

function TaskListLoader() {
  return (
    <Box as="ol" testHook="task-list-loader">
      <Box as="li" className={styles.section}>
        <Box className={styles.title} backgroundColor="colorBackgroundNeutral" borderRadius="borderRadiusRoot">
          <Skeleton height={20} />
        </Box>
        {_.times(3, (index) => (
          <Box
            className={styles.task}
            _after={{
              backgroundColor: 'colorBackgroundNeutral',
            }}
            key={index}
          >
            <Skeleton animation="wave" height={140} />
          </Box>
        ))}
      </Box>
    </Box>
  );
}

type TaskListProps = {|
  children: React.Node,
|};

function TaskList(props: TaskListProps) {
  const { children } = props;
  return <Box as="ol">{children}</Box>;
}

type TaskListContainerProps = {|
  children: React.Node,
|};

function TaskListContainer(props: TaskListContainerProps) {
  const { children } = props;
  return (
    <Container maxWidth="sizeContainerRoot">
      <Box marginInline={{ tablet: 'spacingLarge6X' }}>{children}</Box>
    </Container>
  );
}

TaskList.Container = TaskListContainer;
TaskList.Section = TaskListSection;
TaskList.Loader = TaskListLoader;

export default TaskList;
