// @flow
import { Fragment, useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { withRouter, type ContextRouter } from 'react-router';
import {
  ActionBar,
  Box,
  Button,
  Container,
  Divider,
  Heading,
  HelpInfo,
  OptionGroup,
  OptionGroupItem,
  Text,
  useMediaQuery,
  useToast,
} from '@getatomi/neon';

import type { BoundAction, Dispatch, GetState, ReduxState, RegistrationRegionCode, Subject } from 'src/types';
import Link from 'src/components/Link/Link';
import FindSubjectHelpLink from 'src/components/FindSubjectHelpLink/FindSubjectHelpLink';
import SubjectIcon from 'src/components/SubjectIcon/SubjectIcon';
import { getUserLevel, getUserRegion } from 'src/reducers/auth';
import { isSubjectsLoaded, getAllSubjectsOrderedByGroupCode } from 'src/reducers/subjects';
import { getActiveSubscriptionId } from 'src/reducers/subscriptions';
import { loadLevelById } from 'src/actions/levels';
import { saveFreeRetailStudentSubjects } from 'src/actions/registration';
import connectData from 'src/decorators/connectData';
import { getSubjectLimit } from 'src/utils/config';
import Logger from 'src/utils/Logger';
import { getDashboardUrl } from 'src/utils/routes';
import links from 'src/constants/links';

const SUBJECT_GROUPS = ['english', 'maths', 'science', 'hsie', 'pdhpe', 'creative_arts', 'technologies', 'generic'];
const log = new Logger('domains/Registration/RegistrationStudentSubjects');

const fetchData = (getState: GetState, dispatch: Dispatch) => {
  const state = getState();
  const { id: levelId } = getUserLevel(state);

  if (!isSubjectsLoaded(state)) {
    return dispatch(loadLevelById(levelId));
  }
};

const mapStateToProps = (state: ReduxState) => ({
  subscriptionId: getActiveSubscriptionId(state),
  subjects: getAllSubjectsOrderedByGroupCode(state),
  isSavingSubjects: state.registration.isSavingSubjects,
  regionCode: getUserRegion(state),
});

export type RegistrationStudentSubjectsProps = {|
  isSavingSubjects: boolean,
  regionCode: RegistrationRegionCode,
  router: ContextRouter,
  saveFreeRetailStudentSubjectsAction: BoundAction<typeof saveFreeRetailStudentSubjects>,
  subjects: {
    [subjectGroupCode: string]: Array<Subject>,
  },
  subscriptionId: number,
|};

function RegistrationStudentSubjects(props: RegistrationStudentSubjectsProps) {
  const { isSavingSubjects, regionCode, router, saveFreeRetailStudentSubjectsAction, subjects, subscriptionId } = props;

  const [selectedSubjects, setSelectedSubjects] = useState<Array<string>>([]);
  const subjectLimit = useMemo(() => getSubjectLimit(regionCode), [regionCode]);
  const toast = useToast();
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const handleSelect = useCallback(
    (values) => {
      if (values.length > subjectLimit) {
        return toast.warning(
          <>
            You can only add {subjectLimit} subjects to your account.{' '}
            <Link href={links.support.subjectsLimit} isExternal>
              Read more about the subjects limit
            </Link>{' '}
            in our Help Center.
          </>,
          { id: 'subjects-limit' }
        );
      }
      setSelectedSubjects(values);
    },
    [subjectLimit, toast]
  );

  const handleClearSelection = useCallback(() => {
    setSelectedSubjects([]);
  }, []);

  const onSubmit = async () => {
    try {
      await saveFreeRetailStudentSubjectsAction(selectedSubjects);
      router.push(getDashboardUrl(subscriptionId));
    } catch (err) {
      log.warn('saving retail student subjects selection failed', err);
      toast.error('There was an error saving your subjects. Please try again.');
    }
  };

  return (
    <Container
      textAlign="center"
      marginBottom={{
        // prevents the action bar from overlapping the help info on small viewports
        base: 'spacingLarge4X',
        tablet: 'spacingNone',
      }}
    >
      <Helmet>
        <title>Pick your subjects | Student Registration</title>
      </Helmet>
      <Heading as="h1" variant={isMobile ? 'large' : 'large2X'} marginBottom="spacingSmall1X">
        What do you want to learn?
      </Heading>
      <Text as="p" variant="bodyRoot" color="colorTextSubtle" marginBottom="spacingLarge6X">
        Select up to {subjectLimit} subjects. You can always change these later in your account settings.
      </Text>
      <Box marginBottom="spacingLarge8X">
        {SUBJECT_GROUPS.map((groupCode, index) => {
          const groupSubjects = subjects[groupCode];
          if (!groupSubjects) return;
          return (
            <Fragment key={groupCode}>
              <OptionGroup
                ariaLabel={`${groupCode.replace('_', ' ')} subjects`}
                selectionMode="multiple"
                variant="circle"
                value={selectedSubjects}
                onChange={handleSelect}
              >
                {groupSubjects.map((subject) => (
                  <OptionGroupItem
                    key={subject.code}
                    value={subject.code}
                    icon={<SubjectIcon name={subject.icon} color={subject.color} />}
                  >
                    {subject.name}
                  </OptionGroupItem>
                ))}
              </OptionGroup>
              {index + 1 < SUBJECT_GROUPS.length && (
                <Box margin="spacingLarge4X">
                  <Divider />
                </Box>
              )}
            </Fragment>
          );
        })}
      </Box>
      <HelpInfo>
        <FindSubjectHelpLink variant="monochrome" />
      </HelpInfo>
      <ActionBar selectedItemCount={selectedSubjects.length} onClearSelection={handleClearSelection}>
        <Button onClick={() => onSubmit()} isLoading={isSavingSubjects}>
          I’m done!
        </Button>
      </ActionBar>
    </Container>
  );
}

export default withRouter(
  connectData(fetchData)(
    connect(mapStateToProps, {
      saveFreeRetailStudentSubjectsAction: saveFreeRetailStudentSubjects,
    })(RegistrationStudentSubjects)
  )
);
