// @flow
import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Alert, Box } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import Logger from 'src/utils/Logger';

const log = new Logger('domains/shared/StripeProvider');

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_KEY);

type StripeProviderProps = {|
  children: React.Node,
|};

export default function StripeProvider(props: StripeProviderProps) {
  const [render, setRender] = useState<boolean>(false);
  const [scriptLoadError, setScriptLoadError] = useState<boolean>(false);

  // @stripe/react-stripe-js is not built for SSR so we render the form on the client only
  useEffect(() => {
    setRender(true);
  }, []);

  // Ask the user to reload the page if the Stripe.js SDK failed to load
  useEffect(() => {
    if (!window.Stripe) {
      setScriptLoadError(true);
      log.error('Failed to load Stripe.js');
    }
  }, []);

  if (!render) {
    return null;
  }

  if (scriptLoadError) {
    return (
      <Box marginBottom="spacingLarge5X">
        <Alert variant="warning">
          Some code on this page failed to load. Please{' '}
          <Link
            variant="monochrome"
            href={window.location.href}
            onClick={() => window.location.reload()}
            testHook="script-load-error-reload-page"
          >
            refresh the page
          </Link>{' '}
          and try again. If you continue to see this message, please get in touch.
        </Alert>
      </Box>
    );
  }

  return <Elements stripe={stripePromise}>{props.children}</Elements>;
}
