// @flow
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToast } from '@getatomi/neon';

export type OutboundFields = {|
  email: string,
|};

const labels = {
  email: 'Email address',
};

const resolver = yupResolver(
  yup.object().shape({
    email: yup.string().email().required().label(labels.email),
  })
);

export default function useForgotPasswordForm({
  onSubmitSuccess,
}: {
  onSubmitSuccess: (data: OutboundFields) => void | Promise<void>,
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { clearErrors, control, formState, handleSubmit, setError } = useForm({
    resolver,
    defaultValues: {
      email: '',
    },
  });
  const toast = useToast();

  const onSubmit = async (event: Event) => {
    event.preventDefault();
    clearErrors('serverError');
    handleSubmit(async (data) => {
      setIsLoading(true);
      try {
        await onSubmitSuccess(data);
      } catch (error) {
        const validationErrors = error.output;
        if (!validationErrors) {
          return toast.error('There was an error resetting your email. Please try again.');
        }
        setError('serverError', { type: 'manual', message: validationErrors });
      }
      setIsLoading(false);
    })(event);
  };

  const emailMessage = formState.errors.email?.message ?? null;
  const { serverError } = formState.errors;

  return {
    control,
    form: {
      onSubmit,
    },
    isLoading,
    serverError,
    fields: {
      email: {
        label: labels.email,
        autoComplete: 'username',
        autoCorrect: 'off',
        validationText: emailMessage,
        errorVariant: emailMessage && 'error',
        isRequired: true,
        type: 'email',
      },
    },
  };
}
