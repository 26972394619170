// @flow
import { ApolloError, useQuery } from '@apollo/client';
import invariant from 'invariant';
import moment from 'moment';
import pluralize from 'pluralize';

import { getPostUrl } from 'src/utils/routes';
import { formatDuration } from 'src/utils/duration';
import type {
  GetClassSubject,
  GetClassSubjectVariables,
  GetClassSubject_me_account_class as GqlClass,
  GetClassSubject_me_account_class_modules as GqlModules,
  GetClassSubject_me_account_class_subject as GqlSubject,
} from 'src/graphql/types/generated/GetClassSubject';

import { isComingSoonLabel } from '../utils/checkIsComingSoon.js';
import GET_CLASS_SUBJECT from './GetClassSubject.graphql';

type Props = {|
  classId: string,
  subscriptionId: string,
|};

export type Subject = {|
  code: string,
  color: string,
  contentCount: number,
  groupCode: string,
  totalDuration: number,
|};

type CommonModule = {|
  id: string,
  labels: $ReadOnlyArray<string>,
  name: string,
|};

type EmptyModule = {|
  ...CommonModule,
  kind: 'COMING_SOON',
|};

type TeacherModule = {|
  ...CommonModule,
  contentCountLabel: string,
  kind: 'TEACHER',
|};

type StudentModule = {|
  ...CommonModule,
  completionRate: number,
  contentCountLabel: string,
  isPartiallyComplete: boolean,
  kind: 'STUDENT',
  timeRemaining: string,
|};

export type Module = EmptyModule | TeacherModule | StudentModule;

export type Revision = {|
  name: string,
  url: string,
|};

type TransformedData = {|
  modules: $ReadOnlyArray<Module>,
  region: string,
  revision: Revision | null,
  subject: Subject,
|};

type Output = {|
  data: ?TransformedData,
  error: ?ApolloError,
  loading: boolean,
  refetch: () => mixed,
|};

function createTimeRemainingLabel(moduleDuration: number, completedDuration: number): string {
  const timeRemaining = formatDuration(moduleDuration - completedDuration, {
    template: 'h [hour] [and] m [min]',
  });

  if (!timeRemaining) {
    return '';
  }

  const withoutTrailingAnd = timeRemaining.replace(/\s*and\s*$/g, '');
  return `${withoutTrailingAnd} remaining`;
}

function hasPartialModuleProgress(completionRate: number): boolean {
  return completionRate < 1 && completionRate > 0;
}

function createLessonCountLabel(gqlModule: GqlModules): string {
  invariant(
    gqlModule.__typename === 'Category' || gqlModule.__typename === 'ClassCategory',
    'Module should have type `Category` or `ClassCategory`.'
  );

  const { contentCount } = gqlModule.metrics;
  const lessonCount = `${contentCount} ${pluralize('Lesson', contentCount)}`;

  if (gqlModule.__typename === 'ClassCategory') {
    return lessonCount;
  }

  const { completedCount, completionRate, lastCompletedAt } = gqlModule.metrics.progress;

  if (hasPartialModuleProgress(completionRate)) {
    return `${completedCount} of ${lessonCount}`;
  }

  return lastCompletedAt && completionRate === 1 ? `Completed ${moment(lastCompletedAt).fromNow()}` : lessonCount;
}

function transformRevision(gqlClass: GqlClass, accountId: string): Revision | null {
  const assessmentMetrics = gqlClass.metrics;
  if (assessmentMetrics.__typename === 'ClassMetrics') {
    return null;
  }

  const challengeToRevise = assessmentMetrics.assessment.lowestStrengthChallengeLesson;
  if (!challengeToRevise) {
    return null;
  }

  const { id: lessonId, categories, name } = challengeToRevise;

  const moduleId = categories[0].id;
  const lessonUrl = getPostUrl(accountId, gqlClass.id, moduleId, lessonId);

  return {
    url: lessonUrl,
    name,
  };
}

function transformModules(gqlModules: $ReadOnlyArray<GqlModules>): $ReadOnlyArray<Module> {
  return gqlModules.map((gqlModule) => {
    invariant(
      gqlModule.__typename === 'Category' || gqlModule.__typename === 'ClassCategory',
      'Module should have type `Category` or `ClassCategory`.'
    );

    const moduleId = gqlModule.id;
    const moduleName = gqlModule.name;
    const labels = gqlModule.labels.map((label) => label.name);
    const isComingSoon = labels.some(isComingSoonLabel);

    if (isComingSoon) {
      return {
        kind: 'COMING_SOON',
        id: moduleId,
        name: moduleName,
        labels,
      };
    }

    const contentCountLabel = createLessonCountLabel(gqlModule);

    if (gqlModule.__typename === 'ClassCategory') {
      return {
        kind: 'TEACHER',
        contentCountLabel,
        id: moduleId,
        labels,
        name: moduleName,
      };
    }

    const timeRemaining = createTimeRemainingLabel(
      gqlModule.duration,
      gqlModule.metrics.progress.totalDurationCompletedInSeconds
    );

    const { completionRate } = gqlModule.metrics.progress;

    return {
      kind: 'STUDENT',
      completionRate,
      contentCountLabel,
      isPartiallyComplete: hasPartialModuleProgress(completionRate),
      id: moduleId,
      labels,
      name: moduleName,
      timeRemaining,
    };
  });
}

function transformSubject(gqlSubject: GqlSubject): Subject {
  return {
    code: gqlSubject.code,
    color: gqlSubject.color,
    groupCode: gqlSubject.groupCode,
    totalDuration: gqlSubject.metrics.totalDurationInSeconds,
    contentCount: gqlSubject.metrics.contentCount,
  };
}

export default function useGetClassSubject(props: Props): Output {
  const { classId, subscriptionId } = props;

  const { data, loading, error, refetch } = useQuery<GetClassSubject, GetClassSubjectVariables>(GET_CLASS_SUBJECT, {
    variables: {
      accountId: subscriptionId,
      classId,
    },
  });

  if (error) {
    return {
      error,
      data: null,
      loading: false,
      refetch,
    };
  }

  if (loading && !data) {
    return {
      loading: true,
      data: null,
      error: null,
      refetch,
    };
  }

  const accountData = data?.me?.account;
  const classData = accountData?.class;
  invariant(accountData && classData, 'Class subject data should be defined');

  const modules = transformModules(classData.modules);
  const subject = transformSubject(classData.subject);
  const revision = transformRevision(classData, accountData.id);

  return {
    data: {
      modules,
      subject,
      region: accountData.region.code,
      revision,
    },
    loading: false,
    error: null,
    refetch,
  };
}
