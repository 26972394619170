// @flow
import { useState } from 'react';
import { Box, Checkbox, Stack, useToast } from '@getatomi/neon';
import _ from 'lodash';

import Logger from 'src/utils/Logger';
import ActionBar from 'src/components/ActionBar/ActionBar';
import Button from 'src/components/Button/Button';
import DeleteClassDialog from 'src/components/DeleteClassDialog/DeleteClassDialog';
import useSelectedItems from 'src/hooks/useSelectedItems';
import { getClassUrl } from 'src/utils/routes';
import useDeleteClasses from 'src/hooks/useDeleteClasses';

import ClassListRow from './ClassListRow/ClassListRow';
import type { ClassData } from '../useGetClasses/useGetClasses';

const log = new Logger('domains/Dashboard/ClassList');

type Props = {
  canDeleteClass?: boolean,
  classes: $ReadOnlyArray<ClassData>,
  isMobileOnly?: boolean,
  subscriptionId: string,
  withStudentsCount?: boolean,
  withSubjectTag?: boolean,
};

export default function ClassList(props: Props) {
  const {
    classes = [],
    canDeleteClass = false,
    isMobileOnly = false,
    withStudentsCount,
    withSubjectTag = false,
    subscriptionId,
  } = props;

  const toast = useToast();
  const Selecting = useSelectedItems(classes.map((row) => String(row.id)));
  const selectedClassIds = Selecting.itemIds;
  const isAllSelected = Selecting.isAllSelected();
  const [showDeletePrompt, setShowDeletePrompt] = useState<boolean>(false);
  // used to reset filtering when the component receives different classes:
  // https://reactjs.org/docs/hooks-faq.html#how-do-i-implement-getderivedstatefromprops
  const [prevClasses, setPrevClasses] = useState<?$ReadOnlyArray<ClassData>>(null);
  const { deleteClasses, isDeletingClasses } = useDeleteClasses({ isBulkDeleting: true });

  const countSelectedClasses = selectedClassIds.length;

  const toggleDeletePrompt = () => {
    setShowDeletePrompt((previousValue) => !previousValue);
  };

  const onDeleteClasses = async () => {
    try {
      await deleteClasses({
        accountId: subscriptionId,
        classIds: selectedClassIds,
      });
      toggleDeletePrompt();
      toast.success(
        countSelectedClasses === 1
          ? 'The class has been deleted successfully.'
          : 'The classes have been deleted successfully.'
      );
      Selecting.clear();
    } catch (err) {
      log.warn(`error deleting classes ${selectedClassIds.toString()}`, err);
      toast.error(
        countSelectedClasses === 1
          ? 'There was an error deleting the class. Please try again'
          : 'There was an error deleting the classes. Please try again.'
      );
    }
  };

  // reset filtering when the component receives different classes
  if (!_.isEqual(classes, prevClasses)) {
    setPrevClasses(classes);
    Selecting.clear();
  }

  return (
    <Box
      display={isMobileOnly ? { base: 'block', desktop: 'none' } : 'block'}
      marginInline="auto"
      maxWidth="sizeContainerLarge1X"
      paddingInline={{ base: 'spacingNone', desktop: 'spacingLarge3X' }}
      position="relative"
    >
      {canDeleteClass && classes.length > 0 && (
        <Box
          display={{ base: 'none', wide: 'inline-block' }}
          position="absolute"
          left="spacingNegativeRoot"
          top="spacingNegativeLarge6X"
        >
          <Checkbox
            ariaLabel="Select all classes"
            isSelected={isAllSelected}
            isIndeterminate={countSelectedClasses > 0 && !isAllSelected}
            onChange={() => Selecting.selectAll()}
          />
        </Box>
      )}
      <Stack as="ul" spacing="spacingNegativeSmall4X" testHook="class-list">
        {classes.map((currentClass) => (
          <ClassListRow
            key={`class-row-${currentClass.id}`}
            isSelected={Selecting.isSelected(String(currentClass.id))}
            onSelect={() => Selecting.select(String(currentClass.id))}
            currentClass={currentClass}
            canDeleteClass={canDeleteClass}
            url={getClassUrl(subscriptionId, currentClass.id)}
            withStudentsCount={withStudentsCount}
            withSubjectTag={withSubjectTag}
          />
        ))}
      </Stack>
      {canDeleteClass && (
        <>
          <DeleteClassDialog
            numSelectedClasses={countSelectedClasses}
            isOpen={showDeletePrompt}
            isLoading={isDeletingClasses}
            onConfirm={onDeleteClasses}
            onDismiss={toggleDeletePrompt}
          />
          <ActionBar selectedItemCount={selectedClassIds.length} onClearSelection={() => Selecting.clear()}>
            <Button onClick={toggleDeletePrompt}>Delete</Button>
          </ActionBar>
        </>
      )}
    </Box>
  );
}
