// @flow
import { connect } from 'react-redux';
import { Controller } from 'react-hook-form';
import _ from 'lodash';
import { Box, Button, HelpInfo, Modal, Stack, Text, TextField, useId, useMediaQuery, useToast } from '@getatomi/neon';

import type { UserType } from 'src/types';
import { Dropdown, Item } from 'src/components/Dropdown/Dropdown';
import Link from 'src/components/Link/Link';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import { transferAccountOwnership, reloadUsersPage } from 'src/actions/users';
import {
  getActiveSubscriptionName,
  isLoggedInAsAccountManager as isLoggedInAsAccountManagerSelector,
} from 'src/reducers/subscriptions';
import { isProcessingUsers, getAllUsersWhoCanBeAddedOwners } from 'src/reducers/users';
import userFullName from 'src/utils/userFullName';
import links from 'src/constants/links';

import useTransferOwnershipForm from './useTransferOwnershipForm';

type Props = {
  onClose: () => void,
  showModal: boolean,
  user: UserType,
};

export type InjectedProps = Props & {
  accountName: string,
  isLoggedInAsAccountManager: boolean,
  isProcessingUser: boolean,
  reloadUsersAction: Function,
  transferAccountOwnershipAction: Function,
  users: Array<{
    label: string,
    value: string,
  }>,
};

const mapStateToProps = (state) => ({
  users: _.map(getAllUsersWhoCanBeAddedOwners(state), (user) => ({
    label: userFullName(user.first_name, user.last_name) || user.email,
    value: user.id,
  })),
  accountName: getActiveSubscriptionName(state),
  isProcessingUser: isProcessingUsers(state),
  isLoggedInAsAccountManager: isLoggedInAsAccountManagerSelector(state),
});

function TransferOwnershipDialog(props: InjectedProps) {
  const {
    accountName,
    isLoggedInAsAccountManager,
    isProcessingUser,
    onClose,
    reloadUsersAction,
    showModal,
    transferAccountOwnershipAction,
    user,
    users,
  } = props;

  const toast = useToast();
  const formId = useId();
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });

  const userName = userFullName(user.first_name, user.last_name);

  const { clearErrors, control, fields, onSubmit, serverError } = useTransferOwnershipForm({
    onSubmitSuccess: async (data) => {
      const countUpdatedUsers = 1;

      await transferAccountOwnershipAction(data.userId, data.password);
      reloadUsersAction(countUpdatedUsers);

      toast.success(
        <Stack spacing="spacingSmall2X">
          <p>
            <strong>Account ownership transferred!</strong>
          </p>
          <p>
            {accountName &&
              `${
                isLoggedInAsAccountManager && userName ? `${userName} is` : 'You are'
              } now an admin user on the ${accountName} account.`}
          </p>
        </Stack>,
        { duration: 8000 }
      );
    },
  });

  const handleClose = (e: SyntheticEvent<>) => {
    e.preventDefault();
    clearErrors();
    onClose();
  };

  return (
    <Modal
      actions={
        <>
          <Button
            isFullWidth={isMobile}
            size={isMobile ? 'small' : undefined}
            variant={isMobile ? 'tertiary' : 'text'}
            onClick={handleClose}
            isDisabled={isProcessingUser}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            form={formId}
            onClick={onSubmit}
            isLoading={isProcessingUser}
            isFullWidth={isMobile}
            size="small"
          >
            Transfer account ownership
          </Button>
        </>
      }
      heading="Transfer account ownership"
      isOpen={showModal}
      onClose={handleClose}
    >
      <form id={formId}>
        <Text as="p" variant="bodySmall" marginBottom="spacingRoot">
          <strong>Transferring account ownership is a one-way street:</strong> you will not be able to undo the transfer
          once it’s made, and the new account owner will have ultimate authority over the {accountName} account.
        </Text>
        <Box marginBottom="spacingLarge3X">
          <HelpInfo alignment="left" marginBottom="spacingLarge3X">
            <Link href={links.support.schoolRolesAndPermissions} isExternal variant="monochrome">
              Learn more about user roles in the Help Centre
            </Link>
          </HelpInfo>
        </Box>
        <Stack spacing="spacingLarge1X">
          {serverError && <ValidationErrors errors={serverError.message} />}
          <Controller
            control={control}
            name="userId"
            render={({ field }) => (
              <Dropdown
                {...field}
                {...fields.userId}
                items={users}
                selectedKey={field.value ? String(field.value) : null}
                onSelectionChange={(value) => {
                  field.onChange(value);
                }}
              >
                {(item) => <Item key={item.value}>{item.label}</Item>}
              </Dropdown>
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <TextField
                {...field}
                {...fields.password}
                type="password"
                helpText="Enter your password to confirm the transfer."
              />
            )}
          />
        </Stack>
      </form>
    </Modal>
  );
}

export default (connect(mapStateToProps, {
  transferAccountOwnershipAction: transferAccountOwnership,
  reloadUsersAction: reloadUsersPage,
})(TransferOwnershipDialog): React.AbstractComponent<Props>);
