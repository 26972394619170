// @flow
import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { HideVisually } from '@getatomi/neon';
import { get } from 'lodash';

import connectData from 'src/decorators/connectData';
import { archiveUsers, loadUsers } from 'src/actions/users';
import { getUserRole } from 'src/reducers/subscriptions';
import { getAllUsersForCurrentPage, isUsersLoading } from 'src/reducers/users';
import { getUsersWithHigherRoleIds } from 'src/utils/roles';
import userStatuses from 'src/constants/userStatuses';
import type { BoundAction, ReduxState, UserAccountStatusType, UserType } from 'src/types';
import { getUser, isLoggedInAsSuperAdmin as isLoggedInAsSuperAdminSelector } from 'src/reducers/auth';

import ArchiveUsersAlert from './ArchiveUsersAlert/ArchiveUsersAlert';
import SubscriptionUsersTable from './SubscriptionUsersTable/SubscriptionUsersTable';

const fetchData = (getState, dispatch, location) => {
  const page = get(location.query, 'page', 1);
  const status = get(location.query, 'status', 'active');
  const keywords = get(location.query, 'keywords');

  return dispatch(loadUsers({ page: +page, status, keywords, forceReload: true }));
};

const mapStateToProps = (state: ReduxState) => {
  const users = getAllUsersForCurrentPage(state);
  const currentAuthenticatedUser = getUser(state);
  const usersWithHigherRolesIds = getUsersWithHigherRoleIds(getUserRole(state), users);
  return {
    users,
    usersWithHigherRolesIds,
    currentAuthenticatedUserId: currentAuthenticatedUser.id,
    isLoading: isUsersLoading(state),
    isLoggedInAsSuperAdmin: isLoggedInAsSuperAdminSelector(state),
  };
};

type Props = {
  location: {
    query: {
      keywords?: string,
      page?: string,
      status?: UserAccountStatusType,
    },
  },
  params: {
    subscriptionId: number,
  },
};
type InjectedProps = Props & {
  archiveUsersAction: BoundAction<typeof archiveUsers>,
  currentAuthenticatedUserId: number,
  isLoading: boolean,
  isLoggedInAsSuperAdmin: boolean,
  loadUsersAction: BoundAction<typeof loadUsers>,
  users: Array<UserType>,
  usersWithHigherRolesIds: Array<number>,
};

function Users(props: InjectedProps) {
  const {
    archiveUsersAction,
    isLoggedInAsSuperAdmin,
    isLoading,
    users,
    usersWithHigherRolesIds,
    currentAuthenticatedUserId,
    params,
    loadUsersAction,
    location,
  } = props;

  const { subscriptionId } = params;
  const { page = 1, keywords, status = userStatuses.active } = location.query;
  const prevStatus = useRef();
  const prevKeywords = useRef();
  const pageTitle = 'Subscription Users';

  useEffect(() => {
    loadUsersAction({
      page: page ? +page : undefined,
      status,
      keywords,
      forceReload: prevStatus.current !== status || prevKeywords.current !== keywords,
    });
    prevStatus.current = status;
    prevKeywords.current = keywords;
  }, [page, keywords, status, loadUsersAction]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <HideVisually>
        <h2>{pageTitle}</h2>
      </HideVisually>
      {isLoggedInAsSuperAdmin && (
        <ArchiveUsersAlert archiveUsersAction={archiveUsersAction} subscriptionId={String(subscriptionId)} />
      )}
      <SubscriptionUsersTable
        key={location.query.status}
        users={users}
        usersWithHigherRolesIds={usersWithHigherRolesIds}
        currentAuthenticatedUserId={currentAuthenticatedUserId}
        isLoading={isLoading}
        subscriptionId={subscriptionId}
      />
    </>
  );
}

export default connectData(
  fetchData,
  false
)(connect(mapStateToProps, { archiveUsersAction: archiveUsers, loadUsersAction: loadUsers })(Users));
