// @flow
import { useMemo } from 'react';

import { TabNav, Item } from 'src/components/TabNav/TabNav';
import { getMarkbookCompletionUrl, getMarkbookMarksUrl, getMarkbookTasksUrl } from 'src/utils/routes';

type MarkbookSubNavProps = {|
  params: {
    classId: string,
    subscriptionId: string,
  },
|};

export type MarkbookLinks = 'completion' | 'marks' | 'tasks';

function MarkbookSubNav({ params }: MarkbookSubNavProps) {
  const { classId, subscriptionId } = params;

  const marksUrl = getMarkbookMarksUrl(subscriptionId, classId);
  const completionUrl = getMarkbookCompletionUrl(subscriptionId, classId);
  const tasksUrl = getMarkbookTasksUrl(subscriptionId, classId);

  const items = useMemo(
    () => [
      { href: marksUrl, name: 'Marks' },
      { href: tasksUrl, name: 'Tasks' },
      { href: completionUrl, name: 'Subject completion' },
    ],
    [marksUrl, tasksUrl, completionUrl]
  );

  return (
    <TabNav ariaLabel="Mark book navigation" items={items} variant="secondary">
      {items.map((item) => (
        <Item key={item.href} href={item.href} routerOptions={{ state: { refocus: false, scrollTop: false } }}>
          {item.name}
        </Item>
      ))}
    </TabNav>
  );
}

export default MarkbookSubNav;
