// @flow
import typeof Zxcvbn from 'zxcvbn';
import { forwardRef, useCallback, useRef, useState, useEffect } from 'react';
import useMergedRef from '@react-hook/merged-ref';
import { Text, TextField } from '@getatomi/neon';

import Logger from 'src/utils/Logger';
import { formValidation } from 'src/constants/formValidation';

const log = new Logger('components/private/PasswordField');

type Props = React.ElementConfig<typeof TextField> & {
  onChange: ({| event: SyntheticEvent<*> |}, strength: ?number) => mixed,
  onStrengthChange?: () => ?number,
};

export default forwardRef<Props, HTMLInputElement>(function PasswordField(props: Props, ref) {
  const { onChange, onStrengthChange, helpText, value, variant, ...otherProps } = props;
  const [strength, setStrength] = useState<?number>(null);
  const zxcvbn = useRef<?Zxcvbn>(null);
  const inputRef = useRef(null);
  const inputMergedRef = useMergedRef<?HTMLInputElement>(ref, inputRef);

  const calculateStrength = (password: ?string) => {
    const passwordStrength = password && zxcvbn.current ? zxcvbn.current(password).score : null;
    setStrength(passwordStrength);
  };

  useEffect(() => {
    // the large zxcvbn package is lazy loaded as a separate chunk on the client
    async function loadZxcvbn() {
      try {
        zxcvbn.current = (await import(/* webpackChunkName: "zxcvbn" */ 'zxcvbn')).default;
        calculateStrength(inputRef.current?.value);
      } catch (err) {
        log.warn(err);
      }
    }
    loadZxcvbn();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onStrengthChange) {
      onStrengthChange(strength);
    }
  }, [onStrengthChange, strength]);

  const onChangeHandler = useCallback(
    (newValue: string) => {
      calculateStrength(newValue);
      onChange(newValue);
    },
    [onChange]
  );

  const strengthDescription = ['weak', 'weak', 'okay', 'good', 'strong'];
  let strengthDescriptionColor;

  if (variant !== 'inverted' && strength != null) {
    if (strength < formValidation.password.minStrength) {
      strengthDescriptionColor = 'colorTextDanger';
    } else if (strength < 4) {
      strengthDescriptionColor = 'colorTextWarning';
    } else {
      strengthDescriptionColor = 'colorTextSuccess';
    }
  }

  return (
    <TextField
      {...otherProps}
      ref={inputMergedRef}
      type="password"
      value={value}
      variant={variant}
      helpText={
        value && strength != null ? (
          <>
            Your password is{' '}
            <Text as="span" color={strengthDescriptionColor} fontSize="fontSizeSmall2X" fontWeight="fontWeightBold">
              {strengthDescription[strength]}
            </Text>
            .
          </>
        ) : (
          helpText
        )
      }
      onChange={onChangeHandler}
    />
  );
});
