// @flow
import { useMutation } from '@apollo/client';

import type {
  DeleteClasses,
  DeleteClassesVariables,
  DeleteClassesInput,
} from 'src/graphql/types/generated/DeleteClasses';
import { trackEvent } from 'src/utils/tracking';
import { trackingEvents } from 'src/constants/tracking';
import GET_DASHBOARD_CLASSES from 'src/domains/Dashboard/useGetClasses/GetDashboardClasses.graphql';

import DELETE_CLASSES from './DeleteClasses.graphql';

export default function useDeleteClasses({ isBulkDeleting }: { isBulkDeleting: boolean } = { isBulkDeleting: false }) {
  const [deleteClasses, { loading }] = useMutation<DeleteClasses, DeleteClassesVariables>(DELETE_CLASSES, {
    refetchQueries: [GET_DASHBOARD_CLASSES],
    awaitRefetchQueries: true,
  });

  return {
    deleteClasses: async (params: DeleteClassesInput) => {
      trackEvent(isBulkDeleting ? trackingEvents.classBulkDeleteRequested : trackingEvents.classDeleteRequested);
      await deleteClasses({ variables: { input: params } });
    },
    isDeletingClasses: loading,
  };
}
