// @flow
import { useQuery, type ApolloError } from '@apollo/client';
import invariant from 'invariant';

import type {
  GetStudentTasks,
  GetStudentTasks_me_account_tasks_edges_node as InputTask,
  GetStudentTasks_me_account_tasks_edges_node_attachments as InputAttachment,
  GetStudentTasksVariables,
} from 'src/graphql/types/generated/GetStudentTasks';
import { getTaskUrl } from 'src/utils/routes';
import taskStatuses from 'src/constants/taskStatuses';
import userStatusOnTask from 'src/constants/userStatusOnTask';

import GET_STUDENT_TASKS from './GetStudentTasks.graphql';
import { contentTransformer, prepareAuthorData, type Author, type TaskContent } from '../Task/taskTransformer';
import type { UserStatusOnTask } from '../types';

type TaskAttachment = {|
  fileName: ?string,
  id: string,
  isInfected: boolean,
  title: ?string,
  type: 'FILE' | 'LINK',
  url: ?string,
|};

type TaskSubject = {|
  color: string,
  groupCode: string,
  name: string,
|};

export type Task = {|
  attachments: $ReadOnlyArray<TaskAttachment>,
  author: Author,
  autoComplete: boolean,
  body: ?string,
  contents: $ReadOnlyArray<TaskContent>,
  dueDate: string,
  id: string,
  subject: TaskSubject,
  title: string,
  url: string,
  userStatus: UserStatusOnTask,
|};

export type TaskFilterClasses = $ReadOnlyArray<{|
  id: string,
  subject: {|
    color: string,
    shortName: string,
  |},
|}>;

export type GetTasks = {|
  classes: TaskFilterClasses,
  tasks: $ReadOnlyArray<Task>,
  userId: string,
|};

type Props = {|
  classId: ?string,
  paginationCursor: { after?: string, before?: string },
  status: ?$Keys<typeof taskStatuses>,
  subscriptionId: string,
  updatePageLocation: (query: { after?: string, before?: string }) => void,
|};

type Output = {|
  data: GetTasks | null,
  error: ?ApolloError,
  goToNextPage?: () => void,
  goToPreviousPage?: () => void,
  loading: boolean,
  refetch: () => mixed,
|};

function attachmentTransformer(attachment: InputAttachment): TaskAttachment {
  return {
    id: attachment.id,
    type: attachment.type,
    title: attachment.title,
    url: attachment.url,
    fileName: attachment.fileName,
    isInfected: attachment.isInfected ?? false,
  };
}

function taskTransformer(subscriptionId: string, task: InputTask): Task {
  return {
    id: task.id,
    // $FlowIgnore - task titles are always defined
    title: task.title,
    body: task.body,
    author: prepareAuthorData(task.author),
    dueDate: task.dueAt,
    autoComplete: task.isAutocomplete,
    userStatus: userStatusOnTask[task.progress.status],
    subject: {
      color: task.class.subject.color,
      groupCode: task.class.subject.groupCode,
      name: task.class.subject.shortName,
    },
    attachments: task.attachments.map(attachmentTransformer),
    contents: task.contents
      .filter(({ __typename }) => !__typename.includes('Locked'))
      .map((content) => contentTransformer({ classId: task.class.id, content, subscriptionId })),
    url: getTaskUrl(subscriptionId, task.class.id, task.id),
  };
}

export default function useGetStudentTasks(props: Props): Output {
  const { classId, paginationCursor, status, subscriptionId, updatePageLocation } = props;
  const { data, error, loading, refetch } = useQuery<GetStudentTasks, GetStudentTasksVariables>(GET_STUDENT_TASKS, {
    variables: {
      accountId: subscriptionId,
      filters: {
        classId,
        isComplete: status === taskStatuses.completed,
      },
      orderBy: [{ column: 'DUE_AT', order: status === taskStatuses.completed ? 'DESC' : 'ASC' }],
      ...paginationCursor,
    },
  });

  if (error) {
    return {
      data: null,
      error,
      loading: false,
      refetch,
    };
  }

  if (loading && !data) {
    return {
      data: null,
      error: null,
      loading: true,
      refetch,
    };
  }

  const userData = data?.me;
  const accountData = userData?.account;

  invariant(userData && accountData, 'User and account data must be defined');

  const { classes, tasks } = accountData;

  const { endCursor, hasNextPage, hasPreviousPage, startCursor } = tasks.pageInfo;
  const goToNextPage =
    hasNextPage && endCursor ? () => updatePageLocation({ after: endCursor, before: undefined }) : undefined;
  const goToPreviousPage =
    hasPreviousPage && startCursor ? () => updatePageLocation({ after: undefined, before: startCursor }) : undefined;

  return {
    data: {
      tasks: tasks.edges.map(({ node }) => taskTransformer(subscriptionId, node)),
      classes: classes.edges.map(({ node }) => {
        const { id, subject } = node;
        return { id, subject: { color: subject.color, shortName: subject.shortName } };
      }),
      userId: userData.id,
    },
    error: null,
    goToNextPage,
    goToPreviousPage,
    loading: false,
    refetch,
  };
}
