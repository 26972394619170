// @flow
import { useState } from 'react';
import {
  Box,
  Flex,
  Heading,
  HideVisually,
  InfoTip,
  Item,
  Skeleton,
  Table,
  Tabs,
  TabList,
  TabPanels,
  Text,
  TextLoader,
} from '@getatomi/neon';
import invariant from 'invariant';

import { Dropdown } from 'src/components/Dropdown/Dropdown';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import { generatePlaceholderRows, generatePlaceholderColumns } from 'src/utils/generateTableLoadingData';

import useGetAccountUsage from './useGetAccountUsage';
import UsageTable from './UsageTable';

type Props = {|
  accountId: string,
|};

function Loader() {
  return (
    <>
      <HideVisually>Loading the account usage table.</HideVisually>

      <Box marginBottom="spacingLarge5X">
        <TextLoader height={24} width={130} marginBottom="spacingSmall" />
        <TextLoader height={24} width={100} />
      </Box>

      <Flex gap="spacingSmall1X" marginBottom="spacingLarge1X">
        <Skeleton borderRadius="borderRadiusRoot" width={66} height={32} />
        <Skeleton borderRadius="borderRadiusRoot" width={82} height={32} />
      </Flex>

      <Table
        isLoading
        columns={generatePlaceholderColumns({
          columnCount: 4,
          shouldRenderStudentColumn: false,
        })}
        data={generatePlaceholderRows(5)}
      />
    </>
  );
}

export default function AccountUsage(props: Props) {
  const { accountId } = props;

  const [selectedLevelId, setSelectedLevelId] = useState<string>('all');
  const [selectedKey, setSelectedKey] = useState<string>('levels');
  const isSubjectsTabSelected = selectedKey === 'subjects';

  const { data, loading, error } = useGetAccountUsage({ accountId });

  if (loading && !data) {
    return <Loader />;
  }

  if (error) {
    return <GraphQLError error={error} description="We couldn’t load the account usage table." />;
  }

  invariant(data, 'Account usage data should be defined');

  const { accountType, subjectFilters, subjects, yearLevels } = data;

  return (
    <>
      <Box marginBottom="spacingLarge3X">
        <Box marginBottom="spacingSmall">
          <Flex alignItems="baseline" gap="spacingSmall2X">
            <Heading as="h3" variant="small1X">
              Account usage
            </Heading>

            <InfoTip
              content="The Account usage section is visible to Atomi account managers only."
              iconSize="sizeIconSmall2X"
            />
          </Flex>
        </Box>

        <Text as="p" variant="bodySmall">
          {accountType}
        </Text>
      </Box>

      <Tabs
        ariaLabel="Account usage metrics"
        selectedKey={selectedKey}
        onSelectionChange={setSelectedKey}
        variant="secondary"
      >
        <Flex justifyContent="space-between">
          <TabList>
            <Item key="levels">Levels</Item>
            <Item key="subjects">Subjects</Item>
          </TabList>
          {isSubjectsTabSelected && (
            <Dropdown
              variant="filter"
              ariaLabel="Filter by level"
              items={subjectFilters}
              selectedKey={selectedLevelId}
              onSelectionChange={(key) => setSelectedLevelId(key)}
            >
              <Item key="all">All levels</Item>
              {subjectFilters.map((filter) => (
                <Item key={filter}>{filter}</Item>
              ))}
            </Dropdown>
          )}
        </Flex>
        <TabPanels>
          <Item key="levels">
            <UsageTable data={yearLevels} type="level" />
          </Item>
          <Item key="subjects">
            <UsageTable data={subjects} type="subject" subjectFilter={selectedLevelId} />
          </Item>
        </TabPanels>
      </Tabs>
    </>
  );
}
