// @flow
import { Alert } from '@getatomi/neon';
import { useEffect, useState } from 'react';

import { UnstyledLink } from 'src/components/Link/Link';

type Props = {
  children?: string,
  id: string,
  url: string,
};

export default function FeedbackBanner({
  children = 'This feature is in Beta, help us shape its future.',
  id,
  url,
}: Props) {
  const FEEDBACK_BANNER_IS_VISIBLE_KEY = `${id}-is-visible`;

  const [isBannerVisible, setIsBannerVisible] = useState<boolean>(
    typeof sessionStorage === 'undefined' || sessionStorage.getItem(FEEDBACK_BANNER_IS_VISIBLE_KEY) == null
  );

  useEffect(() => {
    if (!isBannerVisible) {
      sessionStorage.setItem(FEEDBACK_BANNER_IS_VISIBLE_KEY, 'false');
    }
  }, [isBannerVisible, FEEDBACK_BANNER_IS_VISIBLE_KEY]);

  if (!isBannerVisible) return null;

  return (
    <Alert
      buttonProps={{
        children: 'Leave feedback',
        href: url,
        isExternal: true,
        as: UnstyledLink,
      }}
      variant="info"
      onDismiss={() => setIsBannerVisible(false)}
    >
      {children}
    </Alert>
  );
}
