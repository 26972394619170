// @flow
import { Children } from 'react';
import { Box, Flex, Scrollable } from '@getatomi/neon';

type FilterGroupProps = {|
  children: React.Node,
  isScrollable?: boolean,
  label?: string,
|};

export default function FilterGroup(props: FilterGroupProps) {
  const { children, isScrollable, label } = props;

  const countChildren = Children.toArray(children).length;
  const gridTemplateColumns = `repeat(${countChildren}, minmax(0, auto))`;

  const filters = (
    <Flex alignItems="baseline" gap="spacingLarge" justifyContent={isScrollable ? undefined : 'end'}>
      {label && (
        <Box as="span" flexShrink={0} fontSize="fontSizeSmall2X" fontFamily="fontFamilySystem">
          {label}
        </Box>
      )}
      <Box
        display="grid"
        gridAutoFlow="column"
        gridTemplateColumns={isScrollable ? { tablet: gridTemplateColumns } : gridTemplateColumns}
        columnGap="spacingSmall"
        width={isScrollable ? undefined : { base: 'sizeFull', mobile: 'unset' }}
      >
        {children}
      </Box>
    </Flex>
  );

  return isScrollable ? <Scrollable direction="horizontal">{filters}</Scrollable> : filters;
}
