// @flow
import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { Badge, Button, Heading, Stack, Text, TextField } from '@getatomi/neon';

import FormMessage from 'src/components/FormMessage/FormMessage';
import type { GetDeletedClasses, GetDeletedClassesVariables } from 'src/graphql/types/generated/GetDeletedClasses';

import GET_DELETED_CLASSES from './GetDeletedClasses.graphql';
import DeletedClassesTable from './DeletedClassesTable';

export default function DeletedClasses() {
  const [accountId, setAccountId] = useState<string>('');
  const [showInvalidAccountIdError, setShowInvalidAccountIdError] = useState<boolean>(false);
  const invalidAccountIdErrorProps = showInvalidAccountIdError
    ? {
        errorVariant: 'error',
        validationText: `Please enter a valid account id. It's the number in the url after 'subscription', e.g. https://learn.getatomi.com/subscription/123456`,
      }
    : {};

  const [getDeletedClasses, { loading, error, data }] = useLazyQuery<GetDeletedClasses, GetDeletedClassesVariables>(
    GET_DELETED_CLASSES
  );

  const clearErrors = () => {
    setShowInvalidAccountIdError(false);
  };

  const onSubmit = async (event) => {
    event.preventDefault();

    clearErrors();

    const isValidAccountId = /^[0-9]+$/.test(accountId);
    if (!isValidAccountId) {
      setShowInvalidAccountIdError(true);
      return;
    }
    try {
      await getDeletedClasses({
        variables: {
          accountId,
        },
      });
    } catch (fetchError) {
      throw new Error(`There was an error fetching deleted classes for ${accountId}: ${fetchError}`);
    }
  };

  return (
    <form onSubmit={onSubmit} autoComplete="off" noValidate>
      <Stack as="fieldset">
        <Text as="legend" variant="bodyLarge">
          View recently deleted classes
        </Text>

        {error && <FormMessage>There was an error getting the deleted classes.</FormMessage>}

        <TextField
          {...invalidAccountIdErrorProps}
          label="Account ID"
          onChange={(value) => setAccountId(value)}
          name="accountId"
          placeholder="e.g. the number in the URL: getatomi.com/subscription/12345"
          value={accountId}
          isRequired
        />

        <Button isLoading={loading} type="submit">
          Get deleted classes
        </Button>

        {data && (
          <>
            <Heading as="h2" variant="large">
              {data.me?.account.name} <Badge>{data.me?.account.plan.customerType}</Badge>
            </Heading>
            <DeletedClassesTable
              classes={(data && data.me && data.me.account.classes.edges.map(({ node }) => node)) ?? []}
            />
          </>
        )}
      </Stack>
    </form>
  );
}
