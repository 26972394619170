// @flow
import { HideVisually, Scrollable, Table } from '@getatomi/neon';

import { generatePlaceholderRows, generatePlaceholderColumns } from 'src/utils/generateTableLoadingData';

type MarkbookLoaderProps = {|
  assistiveText: string,
|};

export default function MarkbookLoader(props: MarkbookLoaderProps) {
  const { assistiveText } = props;
  const columnCount = 7;
  const rowCount = 15;

  const columns = generatePlaceholderColumns({ columnCount, headerHeight: 65 });
  const data = generatePlaceholderRows(rowCount);

  return (
    <Scrollable direction="horizontal" isFocusable>
      <HideVisually>{assistiveText}</HideVisually>
      <Table isLoading isFixedLayout enableSelection columns={columns} data={data}>
        <colgroup>
          <col key="select" width="50" />
          {columns.map(({ accessorKey }) => (
            <col key={accessorKey} width={accessorKey === 'loading-1' ? 220 : 180} />
          ))}
        </colgroup>
      </Table>
    </Scrollable>
  );
}
