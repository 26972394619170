// @flow
import { useEffect } from 'react';
import type { ContextRouter } from 'react-router';
import Helmet from 'react-helmet';
import {
  Card,
  CompactPagination,
  Container,
  EmptyState,
  Heading,
  HideVisually,
  Illustration,
  Stack,
  TextLoader,
} from '@getatomi/neon';

import classesSourceFilters from 'src/constants/classesSourceFilters';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import ClassListRow from 'src/domains/Dashboard/ClassList/ClassListRow/ClassListRow';
import DashboardTabNav from 'src/domains/Dashboard/DashboardTabNav/DashboardTabNav';
import useClassesSource from 'src/domains/Dashboard/useClassesSource';
import { getLtiUrl, getLtiClassUrl } from 'src/utils/routes';

import useGetClasses from './useGetClasses';

type Params = {
  subscriptionId: string,
};

type Props = {
  location: {
    query: {
      [string]: string,
      source: $Keys<typeof classesSourceFilters>,
    },
  },
  params: Params,
  router: ContextRouter,
};

export default function SelectClass(props: Props) {
  const {
    location,
    params: { subscriptionId },
    router,
  } = props;

  const { after, before, keywords, postData } = location.query;

  const { classesSource } = useClassesSource();

  useEffect(() => {
    if (postData) {
      sessionStorage.setItem('postData', postData);
    }
  }, [postData]);

  function updatePageLocation(query) {
    router.push({
      pathname: getLtiUrl(subscriptionId),
      query,
    });
  }

  const { classes, error, loading, goToNextPage, goToPreviousPage } = useGetClasses({
    subscriptionId,
    classesSource,
    keywords,
    updatePageLocation,
    paginationCursor: {
      after,
      before,
    },
  });

  const hasNoClasses = !loading && !error && classes.length === 0;

  return (
    <>
      <Helmet>
        <title>Select class</title>
      </Helmet>
      <Container marginBottom="spacingNegativeSmall4X" paddingInline="spacingLarge">
        <Heading as="h1" variant="large" marginBottom="spacingSmall">
          Select a class
        </Heading>
        <DashboardTabNav subscriptionId={subscriptionId} isLti />
      </Container>

      {error && (
        <Container marginTop="spacingLarge1X">
          <GraphQLError error={error} description="We couldn’t load your classes." />
        </Container>
      )}

      {hasNoClasses ? (
        <Container marginTop="spacingLarge1X">
          <EmptyState
            media={<Illustration name="emptystate-classes" />}
            heading="You have no classes, yet."
            description={
              classesSource === 'user'
                ? `Try finding a class by switching to 'All classes' instead.`
                : 'Ask your school to start adding classes, then come back and try again.'
            }
          />
        </Container>
      ) : (
        <Stack as="ul" spacing="spacingNegativeSmall4X">
          {loading && (
            <>
              <HideVisually>Loading classes.</HideVisually>
              {Array.from({ length: 3 }, (v, i) => (
                <Card key={i} padding="spacingLarge" testHook="loading-class" withHover={false}>
                  <TextLoader height={25} marginBottom="spacingSmall" width={230} />
                  <TextLoader height={22} width={100} />
                </Card>
              ))}
            </>
          )}
          {classes.map((currentClass) => (
            <ClassListRow
              key={`class-row-${currentClass.id}`}
              currentClass={currentClass}
              url={getLtiClassUrl(subscriptionId, currentClass.id)}
              withSubjectTag
            />
          ))}

          {(goToNextPage || goToPreviousPage) && (
            <Container marginTop="spacingLarge6X">
              <CompactPagination onPreviousClick={goToPreviousPage} onNextClick={goToNextPage} />
            </Container>
          )}
        </Stack>
      )}
    </>
  );
}
