// @flow
import { useState, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import useAsyncValidation, { type AsyncValidation } from 'src/hooks/useAsyncValidation';
import { formValidation } from 'src/constants/formValidation';

import emailValidator from './validators/emailValidator';

export type ChildFields = {
  email?: string,
  firstName?: string,
  lastName?: string,
  levelId?: ?number | ?string,
};

const labels = {
  firstName: 'First name',
  lastName: 'Last name',
  email: 'Email',
  levelId: 'Which year are they in?',
  levelIdAlternate: 'Year',
};

const createResolver = (emailAsyncValidation: AsyncValidation) =>
  yupResolver(
    yup.object().shape({
      firstName: yup.string().trim().label(labels.firstName).required().max(formValidation.firstName.maxLength),
      lastName: yup.string().trim().label(labels.lastName).required().max(formValidation.lastName.maxLength),
      email: yup
        .string()
        .trim()
        .label(labels.email)
        .required()
        .test('email', '', function test(value) {
          if (this.options.context.parentEmail === value) {
            return this.createError({ message: 'Your child’s email address must be different than yours.' });
          }

          if (emailAsyncValidation.isValid.current !== null) {
            return this.createError({ message: emailAsyncValidation.isValid.current });
          }

          return true;
        }),
      levelId: yup.number().nullable().required().label(labels.levelIdAlternate),
    })
  );

export default function useParentRegistrationFormStep2({
  context,
  defaultValues,
  onSubmitFail,
  onSubmitSuccess,
}: {
  context: {
    parentEmail: string,
  },
  defaultValues: ChildFields,
  onSubmitFail: () => void,
  onSubmitSuccess: (data: ChildFields) => void,
}) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailWarning, setEmailWarning] = useState<?string>(null);

  const emailAsyncValidation = useAsyncValidation(async (email: string) => {
    return emailValidator({
      email,
      setEmailWarning,
    });
  });

  const resolver = useMemo(() => createResolver(emailAsyncValidation), [emailAsyncValidation]);
  const { control, formState, handleSubmit, trigger, getValues } = useForm({
    context,
    defaultValues,
    resolver,
  });

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    setIsLoading(true);

    const email = getValues('email');
    if (email !== '') {
      await emailAsyncValidation.validate(email);
    }

    handleSubmit(onSubmitSuccess, onSubmitFail)(e);
    setIsLoading(false);
  };

  const firstNameMessage = formState.errors.firstName?.message;
  const lastNameMessage = formState.errors.lastName?.message;
  const emailMessage = formState.errors.email?.message;
  const levelIdMessage = formState.errors.levelId?.message;

  return {
    isLoading,
    control,
    form: {
      onSubmit,
    },
    fields: {
      firstName: {
        validationText: firstNameMessage,
        errorVariant: firstNameMessage && 'error',
        label: labels.firstName,
        isRequired: true,
      },
      lastName: {
        validationText: lastNameMessage,
        errorVariant: lastNameMessage && 'error',
        label: labels.lastName,
        isRequired: true,
      },
      email: {
        validationText: emailMessage || emailWarning,
        errorVariant: (emailMessage && 'error') || (emailWarning && 'warning'),
        label: labels.email,
        onChange: () => {
          if (formState.errors.email) {
            emailAsyncValidation.clear();
            trigger('email');
          }
        },
        onBlur: async (event: SyntheticInputEvent<HTMLInputElement>) => {
          const { value } = event.currentTarget;
          // do not validate the email is an empty string, e.g. if the user only tabbed through the
          // field and didn't fill anything
          if (value !== '' && value !== context.parentEmail) {
            await emailAsyncValidation.validate(value);
            trigger('email');
          }
        },
        isLoading: !isLoading && emailAsyncValidation.isLoading,
        isRequired: true,
      },
      levelId: {
        label: labels.levelId,
        validationText: levelIdMessage,
        errorVariant: levelIdMessage && 'error',
      },
    },
  };
}
