// @flow
import { Box, Button, HideVisually, IconSearch } from '@getatomi/neon';

import type { UseAutocompleteOutput } from 'src/components/useFindContentDialog/hooks/useContentAutocomplete';

type Props = {|
  autocomplete: UseAutocompleteOutput,
  formId: string,
  formRef: any,
  searchBoxRef: any,
|};

export default function ContentSearchBox(props: Props) {
  const { autocomplete, formId, formRef, searchBoxRef } = props;

  const { query, setQuery } = autocomplete;

  const placeholder = 'Search videos, lessons, and quizzes';

  const labelProps = autocomplete.getters.getLabelProps({});

  const inputProps = autocomplete.getters.getInputProps({
    inputElement: searchBoxRef.current,
    placeholder,
    'aria-label': placeholder,
    autoComplete: 'off',
    autoCorrect: 'off',
    spellCheck: false,
  });

  const formProps = autocomplete.getters.getFormProps({
    inputElement: searchBoxRef.current,
    id: formId,
  });

  const hasQuery = query.length > 0;

  return (
    <Box
      as="form"
      ref={formRef}
      backgroundColor="colorBackgroundNeutral"
      color="colorTextSubtle"
      borderRadius="borderRadiusRoot"
      display="inline-flex"
      alignItems="center"
      gap="spacingSmall"
      paddingBlock="spacingSmall1X"
      paddingInline="spacingLarge"
      fontFamily="fontFamilySystem"
      fontSize="fontSizeRoot"
      width="sizeFull"
      {...formProps}
    >
      <HideVisually>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label {...labelProps}>Search content</label>
      </HideVisually>
      <IconSearch size="sizeIconSmall" />
      <Box
        ref={searchBoxRef}
        as="input"
        flex={1}
        border="none"
        fontSize="fontSizeRoot"
        lineHeight="lineHeightLarge"
        backgroundColor="transparent"
        color="colorTextSubtle"
        _placeholder={{
          color: 'colorTextSubtler',
        }}
        {...inputProps}
      />

      {hasQuery && (
        <Button variant="text" onClick={() => setQuery('')}>
          Clear
        </Button>
      )}
    </Box>
  );
}
