// @flow
import { useMemo } from 'react';
import { connect } from 'react-redux';

import type { ReduxState } from 'src/types';
import { TabNav, Item } from 'src/components/TabNav/TabNav';
import { isLoggedInAsSuperAdmin } from 'src/reducers/auth';
import {
  canManageBilling as canManageBillingSelector,
  getActiveSubscriptionId,
  isLoggedInAsParent,
  isSchoolPlan as isSchoolPlanSelector,
  isAdminOrAbove as isAdminOrAboveSelector,
  isRetailPlan as isRetailPlanSelector,
} from 'src/reducers/subscriptions';
import { getSettingsUrl } from 'src/utils/routes';

const mapStateToProps = (state: ReduxState) => {
  return {
    canManageBilling: canManageBillingSelector(state),
    isAdminOrAbove: isAdminOrAboveSelector(state),
    isParent: isLoggedInAsParent(state),
    isRetailPlan: isRetailPlanSelector(state),
    isSchoolPlan: isSchoolPlanSelector(state),
    isSuperAdmin: isLoggedInAsSuperAdmin(state),
    settingsUrl: getSettingsUrl(getActiveSubscriptionId(state)),
  };
};

type InjectedProps = $Call<typeof mapStateToProps, ReduxState, {}>;

export function SettingsNav(props: InjectedProps) {
  const { canManageBilling, isAdminOrAbove, isParent, isRetailPlan, isSchoolPlan, isSuperAdmin, settingsUrl } = props;

  const items = useMemo(
    () =>
      // https://github.com/facebook/flow/issues/1414#issuecomment-231680334
      (([
        { href: `${settingsUrl}/profile`, name: 'Profile' },
        isRetailPlan && !isParent && { href: `${settingsUrl}/subjects`, name: 'Subjects' },
        !isSuperAdmin && { href: `${settingsUrl}/password`, name: 'Password' },
        isSchoolPlan && { href: `${settingsUrl}/school`, name: 'School Account' },
        (isAdminOrAbove || isParent) && { href: `${settingsUrl}/users`, name: 'Users' },
        canManageBilling && { href: `${settingsUrl}/billing`, name: 'Billing' },
        isSuperAdmin && isSchoolPlan && { href: `${settingsUrl}/wonde`, name: 'Wonde' },
        isSuperAdmin && isSchoolPlan && { href: `${settingsUrl}/saml-sso`, name: 'SAML SSO' },
      ].filter(Boolean): Array<any>): Array<{ href: string, name: string }>),
    [canManageBilling, isAdminOrAbove, isParent, isRetailPlan, isSchoolPlan, isSuperAdmin, settingsUrl]
  );

  return (
    <TabNav ariaLabel="Settings navigation" items={items}>
      {items.map((item) => (
        <Item key={item.href} href={item.href} routerOptions={{ state: { refocus: false, scrollTop: false } }}>
          {item.name}
        </Item>
      ))}
    </TabNav>
  );
}

export default (connect(mapStateToProps, {})(SettingsNav): React.AbstractComponent<{}>);
