import { get, difference, map, merge, union, uniq } from 'lodash';

import { getSubjectById, getSubjectByIds } from 'src/reducers/subjects';
import { getLevelsByIds } from 'src/reducers/levels';
import classesSourceFilters from 'src/constants/classesSourceFilters';
import {
  LOAD_CLASSES,
  LOAD_CLASSES_SUCCESS,
  LOAD_CLASSES_FAIL,
  LOAD_SUBSCRIPTION_CLASSES,
  LOAD_SUBSCRIPTION_CLASSES_SUCCESS,
  LOAD_SUBSCRIPTION_CLASSES_FAIL,
  UPDATE_CURRENT_PAGE,
  SEND_USER_INVITE,
  SEND_USER_INVITE_SUCCESS,
  SEND_USER_INVITE_FAIL,
  CLEAR_VALIDATION_ERRORS,
} from 'src/actions/classes';

const initialPagination = {
  currentPage: 0,
  totalPages: 0,
  totalItems: 0,
  pages: {
    // 1: [1, 2, 3],
  },
};
const initialState = {
  entities: {}, // list of classes
  isLoading: false,
  isRefetching: false,
  isUserClassesLoaded: false,
  processingIds: [],

  fullyLoadedClassesIds: [], // classes fetched with all details (eg. on class page but not on the dashboard)
  nonPaginatedClassesIds: [], // savings ids when loading classes without pagination

  pagination: {
    pageSize: 12,
    userClasses: {
      ...initialPagination,
    },
    subscriptionClasses: {
      ...initialPagination,
    },
  },

  routeError: null,
  validationErrors: null,
};

const getClassesPath = (source) => (source === 'user' ? 'userClasses' : 'subscriptionClasses');

export default function classesReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_CLASSES:
    case LOAD_SUBSCRIPTION_CLASSES: {
      return {
        ...state,
        isLoading: true,
        isRefetching: action.data.isRefetching,
      };
    }
    case LOAD_CLASSES_SUCCESS: {
      const { items: newClassesIds, meta } = action.result.result;
      const { pagination } = meta;

      return {
        ...state,
        entities: merge({}, state.entities, get(action.result, 'entities.classes')),
        isLoading: false,
        isRefetching: false,
        isUserClassesLoaded: true,
        pagination: {
          ...state.pagination,
          userClasses: {
            ...state.pagination.userClasses,
            currentPage: pagination.current_page,
            totalPages: pagination.total_pages,
            totalItems: pagination.total,
            pages: {
              ...state.pagination.userClasses.pages,
              [pagination.current_page]: newClassesIds,
            },
          },
        },
      };
    }
    case LOAD_CLASSES_FAIL:
      return {
        ...state,
        isLoading: false,
        isRefetching: false,
        isUserClassesLoaded: false,
        routeError: action.routeError,
      };

    case LOAD_SUBSCRIPTION_CLASSES_SUCCESS: {
      const { items: newClassesIds, meta } = action.result.result;

      if (!meta) {
        return {
          ...state,
          isLoading: false,
          isRefetching: false,
          nonPaginatedClassesIds: uniq(state.nonPaginatedClassesIds.concat(action.result.result.items)),
          entities: merge({}, state.entities, get(action.result, 'entities.classes')),
        };
      }

      const { pagination } = meta;

      return {
        ...state,
        entities: merge({}, state.entities, get(action.result, 'entities.classes')),
        isLoading: false,
        isRefetching: false,
        pagination: {
          ...state.pagination,
          subscriptionClasses: {
            ...state.pagination.subscriptionClasses,
            currentPage: pagination.current_page,
            totalPages: pagination.total_pages,
            totalItems: pagination.total,
            pages: {
              ...state.pagination.subscriptionClasses.pages,
              [pagination.current_page]: newClassesIds,
            },
          },
        },
      };
    }

    case LOAD_SUBSCRIPTION_CLASSES_FAIL:
      return {
        ...state,
        isLoading: false,
        isRefetching: false,
        routeError: action.routeError,
      };

    case UPDATE_CURRENT_PAGE: {
      const source = getClassesPath(action.source);

      return {
        ...state,
        pagination: {
          ...state.pagination,
          [source]: {
            ...state.pagination[source],
            currentPage: action.page,
          },
        },
      };
    }

    case CLEAR_VALIDATION_ERRORS:
      return {
        ...state,
        validationErrors: null,
      };

    case SEND_USER_INVITE:
      return {
        ...state,
        processingIds: union(state.processingIds, action.data.ids),
      };

    case SEND_USER_INVITE_SUCCESS:
      return {
        ...state,
        processingIds: difference(state.processingIds, action.data.ids),
      };

    case SEND_USER_INVITE_FAIL:
      return {
        ...state,
        processingIds: difference(state.processingIds, action.data.ids),
      };

    default:
      return state;
  }
}

// Loading state selectors
export const isUserClassesLoaded = (state) => state.classes.isUserClassesLoaded;
export const isLoadingClasses = (state) => state.classes.isLoading;
export const isRefetchingClasses = (state) => state.classes.isRefetching;

// Students/Teacher adding/removing state
export const isProcessingUser = (state, id) => state.classes.processingIds && state.classes.processingIds.includes(id);

// Classes subjects (for filtering)
export const getSubjectsFromClasses = (state) => {
  const subjectIds = uniq(map(state.classes.entities, (c) => c.subject_code));
  return getSubjectByIds(state, subjectIds);
};

// Pagination selectors
export const getPageSize = (state) => +state.classes.pagination.pageSize;
export const isPageLoaded = (state, page, source) => !!state.classes.pagination[getClassesPath(source)].pages[page];

// Classes selectors
export const getClassById = (state, id) => state.classes.entities[id];
export const getAllClasses = (state) => {
  // get user classes
  const classesObj = getClassesPath(classesSourceFilters.user);
  const { currentPage } = state.classes.pagination[classesObj];
  const classes = state.classes.pagination[classesObj].pages[currentPage];

  if (!classes) return [];

  const filteredClasses = classes.filter(Boolean).map((id) => getClassById(state, id));

  return filteredClasses;
};

export const getNonPaginatedClasses = (state) => {
  const classesId = state.classes.nonPaginatedClassesIds;

  if (!classesId || classesId.length === 0) return [];

  const filteredClasses = classesId.map((id) => {
    const currentClass = getClassById(state, id);
    const subject = getSubjectById(state, currentClass.subject);
    return {
      ...currentClass,
      subject,
      levels: getLevelsByIds(state, currentClass.levels),
    };
  });

  return filteredClasses;
};
