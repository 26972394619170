// @flow
import { useCookies } from 'react-cookie';

import { getCookieOptions } from 'src/utils/cookies';
import classesSourceFilters from 'src/constants/classesSourceFilters';
import { CLASSES_SOURCE_COOKIE } from 'src/constants/cookies';

type ClassesSource = $Keys<typeof classesSourceFilters>;

/**
 * Custom hook to save and retrieve the classes source cookie used to filter classes by "My classes"
 * or "All classes".
 */
export default function useClassesSource(): {
  classesSource: ClassesSource,
  setClassesSource: (source: ClassesSource) => void,
} {
  const [cookies, setCookie] = useCookies([CLASSES_SOURCE_COOKIE]);

  const classesSource = cookies[CLASSES_SOURCE_COOKIE] || classesSourceFilters.user;

  const handleSetClassesSource = (source) => {
    setCookie(CLASSES_SOURCE_COOKIE, source, getCookieOptions());
  };

  return { classesSource, setClassesSource: handleSetClassesSource };
}
