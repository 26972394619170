// @flow
import { useMutation } from '@apollo/client';

import type {
  CreateStudentRevision,
  CreateStudentRevisionVariables,
  CreateStudentRevisionInput,
} from 'src/graphql/types/generated/CreateStudentRevision';

import CREATE_STUDENT_REVISION from '../CreateStudentRevision.graphql';

export default function useCreateStudentRevision() {
  const [createStudentRevision, { loading: isCreatingStudentRevision }] = useMutation<
    CreateStudentRevision,
    CreateStudentRevisionVariables
  >(CREATE_STUDENT_REVISION);

  return {
    createStudentRevision: async (params: CreateStudentRevisionInput) => {
      const { data } = await createStudentRevision({ variables: { input: params } });
      return data?.createStudentRevision;
    },
    isCreatingStudentRevision,
  };
}
