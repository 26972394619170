// @flow

export const formValidation = {
  firstName: {
    maxLength: 70,
  },
  lastName: {
    maxLength: 70,
  },
  password: {
    minLength: 6,
    minStrength: 2,
  },
};
