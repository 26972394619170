// @flow
import _ from 'lodash';
import invariant from 'invariant';

import type {
  GetChallengeMarks,
  GetChallengeMarks_me_account_class_lesson as GqlChallengeLessonData,
  GetChallengeMarks_me_account_class_lesson_ClassChallengeLesson_attempts as GqlChallengeAttempt,
  GetChallengeMarks_me_account_class_lesson_ClassChallengeLesson_attempts_questionAttempts as GqlChallengeQuestionAttempt,
  GetChallengeMarks_me_account_class_lesson_ClassChallengeLesson_challenge_items as GqlChallengeItem,
  GetChallengeMarks_me_account_class_lesson_ClassChallengeLesson_metrics as GqlChallengeMetrics,
  GetChallengeMarks_me_account_class_lesson_ClassChallengeLesson_metrics_assessment_students as GqlChallengeAssessmentMetrics,
  GetChallengeMarks_me_account_class_students as GqlChallengeUser,
} from 'src/graphql/types/generated/GetChallengeMarks';
import type {
  GetRevisionMarks,
  GetRevisionMarks_me_account_class_revision as GqlRevisionData,
  GetRevisionMarks_me_account_class_revision_ClassRevision_attempts as GqlRevisionAttempt,
  GetRevisionMarks_me_account_class_revision_ClassRevision_attempts_questionAttempts as GqlRevisionQuestionAttempt,
  GetRevisionMarks_me_account_class_revision_ClassRevision_items as GqlRevisionItem,
  GetRevisionMarks_me_account_class_revision_ClassRevision_metrics as GqlRevisionMetrics,
  GetRevisionMarks_me_account_class_revision_ClassRevision_metrics_assessment_students as GqlRevisionAssessmentMetrics,
  GetRevisionMarks_me_account_class_students as GqlRevisionUser,
} from 'src/graphql/types/generated/GetRevisionMarks';
import type { ChallengeTier, PostType, UserAccountStatusType } from 'src/types';
import challengeTiers from 'src/constants/challengeTiers';
import postTypes from 'src/constants/postTypes';
import { getPostUrl, getRevisionUrl } from 'src/utils/routes';
import type { MarksStudent } from 'src/domains/ViewingQuizResults/types';
import type { LessonMetrics } from 'src/domains/ViewingQuizResults/Marks/StudentStrength/StudentStrength';
import type { NewAttachedContent } from 'src/domains/Tasks/types';
import userFullName from 'src/utils/userFullName';

import type { GetQuestionUrl } from '../getQuestionUrlFactory';
import { getStudentCohorts, type StudentCohort } from '../studentCohorts';
import { getIndividualQuestionResult } from './getIndividualQuestionResult';

export type MarksQuestion = {|
  result: ?string,
  status: ?'success' | 'error',
  type: ?'multiple-choice' | 'self-marked' | 'default',
  uniqueId: ?string,
  url: string,
|};

type PreparedStudent = {|
  accountStatus: UserAccountStatusType,
  avatar: ?string,
  color: number,
  email: string,
  firstName: string,
  fullName: string,
  id: string,
  lastName: string,
|};

type PreparedChallengeAttempt = {|
  duration: ?number,
  hasAttempt: boolean,
  lastAttempt: ?string,
|};

type PreparedAssessmentMetrics = {|
  classification: $ReadOnlyArray<StudentCohort>,
  mark: ?number,
  strength?: ?number,
|};

export type PreparedQuestion = {|
  id: string,
  result: ?string,
  status: ?string,
  type: ?string,
  url: string,
|};

type PreparedLesson = {|
  duration: number,
  id: string,
  name: string,
  subjectCode: string,
  tier?: ChallengeTier,
  type: PostType,
  url: string,
|};

type PreparedMarksData = {|
  attachedContent: NewAttachedContent,
  averageMarkMetrics: LessonMetrics,
  challengeTier: ChallengeTier,
  sequence: $ReadOnlyArray<string>,
  students: $ReadOnlyArray<MarksStudent>,
  subheading: string,
  title: string,
|};

const prepareStudentData = (student: GqlChallengeUser | GqlRevisionUser): PreparedStudent => {
  invariant(student.__typename === 'ClassStudent', 'User should be a class student');
  return {
    accountStatus: student.accountStatus,
    avatar: student.avatar,
    color: student.color,
    email: student.email,
    firstName: student.firstName || '',
    fullName: userFullName(student.firstName, student.lastName) ?? student.email,
    id: student.id,
    lastName: student.lastName || '',
  };
};

const prepareAttemptData = (attempt: GqlChallengeAttempt | GqlRevisionAttempt): PreparedChallengeAttempt => {
  const hasAttempt = Boolean(attempt);
  const { duration, attemptedAt: lastAttempt } = hasAttempt ? attempt : { duration: null, attemptedAt: null };
  return {
    duration,
    lastAttempt,
    hasAttempt,
  };
};

const prepareAssessmentMetricsData = (assessmentMetrics: ?GqlChallengeAssessmentMetrics): PreparedAssessmentMetrics => {
  if (!assessmentMetrics) {
    return {
      mark: 0,
      strength: 0,
      classification: [],
    };
  }

  const { mark, strength } = assessmentMetrics;
  const classification = getStudentCohorts(mark, strength);
  return {
    mark,
    strength,
    classification,
  };
};

const prepareRevisionMetricsData = (assessmentMetrics: ?GqlRevisionAssessmentMetrics): PreparedAssessmentMetrics => {
  if (!assessmentMetrics) {
    return {
      mark: 0,
      classification: [],
    };
  }

  const { mark } = assessmentMetrics;
  const classification = getStudentCohorts(mark, null);
  return {
    mark,
    classification,
  };
};

function prepareQuestions({
  getQuestionUrl,
  items,
  questionAttempts,
  userId,
}: {
  getQuestionUrl: GetQuestionUrl,
  items: $ReadOnlyArray<GqlChallengeItem> | $ReadOnlyArray<GqlRevisionItem>,
  questionAttempts: $ReadOnlyArray<?GqlChallengeQuestionAttempt> | $ReadOnlyArray<?GqlRevisionQuestionAttempt>,
  userId: string,
}): $ReadOnlyArray<PreparedQuestion> {
  const lookupQuestionAttempts = _.keyBy(questionAttempts, (attempt) => attempt?.contentItemId);

  return items.map((item) => {
    const { id } = item;
    const questionAttempt = lookupQuestionAttempts[id];

    const { result, status, type } = getIndividualQuestionResult(questionAttempt, item);

    const url = getQuestionUrl({ itemId: item.id, userId });

    return {
      id,
      result,
      status,
      type,
      url,
    };
  });
}

function prepareChallengeStudents(
  users: $ReadOnlyArray<GqlChallengeUser>,
  attempts: $ReadOnlyArray<?GqlChallengeAttempt>,
  metrics: GqlChallengeMetrics,
  items: $ReadOnlyArray<GqlChallengeItem>,
  getQuestionUrl: GetQuestionUrl
): $ReadOnlyArray<MarksStudent> {
  const lookupAttempts = _.keyBy(attempts.filter(Boolean), 'userId');
  const lookupAssessmentMetrics = _.keyBy(metrics.assessment.students, 'studentId');
  const lookupProgressMetrics = _.keyBy(metrics.progress.students, 'studentId');

  return users
    .map((student) => {
      if (student.__typename !== 'ClassStudent') {
        return null;
      }

      const challengeAttempt = lookupAttempts[student.id];
      const challengeAttemptData = prepareAttemptData(challengeAttempt);

      const assessmentMetrics = lookupAssessmentMetrics[student.id];
      const assessmentMetricsData = prepareAssessmentMetricsData(assessmentMetrics);

      const progressMetrics = lookupProgressMetrics[student.id];
      const completions = progressMetrics?.cumulativeCompletionCount;

      const questionAttempts: $ReadOnlyArray<?GqlChallengeQuestionAttempt> = challengeAttempt?.questionAttempts ?? [];
      const questionsData = prepareQuestions({
        userId: student.id,
        items,
        questionAttempts,
        getQuestionUrl,
      });

      const studentData = prepareStudentData(student);

      const hasPartialAttempt = !challengeAttemptData.hasAttempt && Boolean(assessmentMetricsData.mark);

      const reviewUrl = getQuestionUrl({ userId: student.id, itemId: null });

      return {
        ...assessmentMetricsData,
        ...challengeAttemptData,
        ...studentData,
        completions,
        hasPartialAttempt,
        questions: _.keyBy(questionsData, 'id'),
        reviewUrl,
      };
    })
    .filter(Boolean);
}

function prepareRevisionStudents(
  users: $ReadOnlyArray<GqlRevisionUser>,
  attempts: $ReadOnlyArray<?GqlRevisionAttempt>,
  metrics: GqlRevisionMetrics,
  items: $ReadOnlyArray<GqlRevisionItem>,
  getQuestionUrl: GetQuestionUrl
): $ReadOnlyArray<MarksStudent> {
  const lookupAttempts = _.keyBy(attempts.filter(Boolean), 'userId');
  const lookupAssessmentMetrics = _.keyBy(metrics.assessment.students, 'studentId');
  const lookupProgressMetrics = _.keyBy(metrics.progress.students, 'studentId');

  return users
    .map((student) => {
      if (student.__typename !== 'ClassStudent') {
        return null;
      }

      const revisionAttempt: GqlRevisionAttempt = lookupAttempts[student.id];
      const revisionAttemptData = prepareAttemptData(revisionAttempt);

      const assessmentMetrics = lookupAssessmentMetrics[student.id];
      const assessmentMetricsData = prepareRevisionMetricsData(assessmentMetrics);

      const progressMetrics = lookupProgressMetrics[student.id];
      const completions = progressMetrics?.cumulativeCompletionCount;

      const questionAttempts: $ReadOnlyArray<?GqlRevisionQuestionAttempt> = revisionAttempt?.questionAttempts ?? [];
      const questionsData = prepareQuestions({
        userId: student.id,
        items,
        questionAttempts,
        getQuestionUrl,
      });

      const studentData = prepareStudentData(student);

      const hasPartialAttempt = !revisionAttemptData.hasAttempt && Boolean(assessmentMetricsData.mark);

      const reviewUrl = getQuestionUrl({ userId: student.id, itemId: null });

      return {
        ...assessmentMetricsData,
        ...revisionAttemptData,
        ...studentData,
        completions,
        hasPartialAttempt,
        questions: _.keyBy(questionsData, 'id'),
        reviewUrl,
      };
    })
    .filter(Boolean);
}

function prepareLessonData({
  accountId,
  classId,
  lesson,
  subjectCode,
}: {
  accountId: string,
  classId: string,
  lesson: GqlChallengeLessonData,
  subjectCode: string,
}): PreparedLesson {
  invariant(lesson.__typename === 'ClassChallengeLesson', 'Lesson should have type ClassChallengeLesson');
  return {
    id: lesson.id,
    duration: lesson.duration,
    name: lesson.name,
    subjectCode,
    ...(lesson.tier && { tier: lesson.tier }),
    type: postTypes.challenge,
    url: getPostUrl(accountId, classId, lesson.categories[0]?.id, lesson.id),
  };
}

function prepareRevisionData({
  accountId,
  classId,
  revision,
  subjectCode,
}: {
  accountId: string,
  classId: string,
  revision: GqlRevisionData,
  subjectCode: string,
}): PreparedLesson {
  invariant(revision.__typename === 'ClassRevision', 'Revision should have type ClassRevision');
  return {
    id: revision.id,
    duration: revision.duration,
    name: revision.title,
    subjectCode,
    tier: challengeTiers.TIER_4_REVISION,
    type: postTypes.revision,
    url: getRevisionUrl(accountId, classId, revision.id),
  };
}

function prepareSequence(
  items: $ReadOnlyArray<GqlChallengeItem> | $ReadOnlyArray<GqlRevisionItem>
): $ReadOnlyArray<string> {
  return items.map((item: GqlChallengeItem) => item.id);
}

export function prepareChallengeMarksData(
  marksData: GetChallengeMarks,
  getQuestionUrl: GetQuestionUrl
): PreparedMarksData {
  const accountData = marksData.me?.account;
  const classData = accountData?.class;
  const lessonData = classData?.lesson;
  invariant(
    accountData && classData && lessonData && lessonData.__typename === 'ClassChallengeLesson',
    'Class challenge lesson data should be defined'
  );

  const {
    name: lessonName,
    metrics: lessonMetrics,
    attempts: lessonAttempts,
    tier: challengeTier,
    challenge: { items },
  } = lessonData;
  const subjectName = classData.subject.shortName;

  const { classAverageMark, regionAverageMark, schoolAverageMark } = lessonMetrics.assessment;

  const browserTitle = `${lessonName} | ${subjectName}`;

  const lesson = prepareLessonData({
    accountId: accountData.id,
    classId: classData.id,
    lesson: lessonData,
    subjectCode: classData.subject.code,
  });

  const gqlItems: $ReadOnlyArray<GqlChallengeItem> = items;

  return {
    title: browserTitle,
    attachedContent: lesson,
    subheading: lessonName,
    challengeTier,
    averageMarkMetrics: {
      classAverageMark,
      regionAverageMark,
      schoolAverageMark,
    },
    sequence: prepareSequence(gqlItems),
    students: prepareChallengeStudents(classData.students, lessonAttempts, lessonMetrics, gqlItems, getQuestionUrl),
  };
}

export function prepareRevisionMarksData(
  marksData: GetRevisionMarks,
  getQuestionUrl: GetQuestionUrl
): PreparedMarksData {
  const accountData = marksData.me?.account;
  const classData = accountData?.class;
  const revisionData = classData?.revision;
  invariant(
    accountData && classData && revisionData && revisionData.__typename === 'ClassRevision',
    'Class revision data should be defined'
  );
  const { title: revisionTitle, metrics: revisionMetrics, attempts: revisionAttempts, items } = revisionData;
  const subjectName = classData.subject.shortName;
  const { classAverageMark } = revisionMetrics.assessment;
  const browserTitle = `${revisionTitle} | ${subjectName}`;
  const revision = prepareRevisionData({
    accountId: accountData.id,
    classId: classData.id,
    revision: revisionData,
    subjectCode: classData.subject.code,
  });
  const gqlItems: $ReadOnlyArray<GqlRevisionItem> = items;
  return {
    title: browserTitle,
    attachedContent: revision,
    subheading: revisionTitle,
    challengeTier: challengeTiers.TIER_4_REVISION,
    averageMarkMetrics: {
      classAverageMark,
    },
    sequence: prepareSequence(gqlItems),
    students: prepareRevisionStudents(classData.students, revisionAttempts, revisionMetrics, gqlItems, getQuestionUrl),
  };
}
