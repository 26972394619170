// @flow
import { BreadcrumbsItem } from '@getatomi/neon';

import { getClassModuleUrl } from 'src/utils/routes';
import ClassBreadcrumbs from 'src/domains/Class/ClassBreadcrumbs/ClassBreadcrumbs';

import type { Ancestor } from '../Post';
import type { CurrentClass } from '../useGetLesson';

export type PostBreadcrumbsProps = {|
  ancestors: $ReadOnlyArray<Ancestor>,
  currentClass: CurrentClass,
  isRetailPlan: boolean,
  subscriptionId: number,
|};

export default function PostBreadcrumbs(props: PostBreadcrumbsProps) {
  const { ancestors, currentClass, isRetailPlan, subscriptionId } = props;
  const { subject } = currentClass;

  const makeUrl = (ancestor: Ancestor, depth: number) => {
    let { parentId } = ancestor;
    const { id: sectionId } = ancestor;
    const { id: classId } = currentClass;

    // if sub-section URL, use section id to make the URL.
    if (depth > 1) {
      parentId = ancestors[1].parentId;
    }

    if (parentId) {
      return `${getClassModuleUrl(subscriptionId, classId, parentId)}#section${sectionId}`;
    }

    return getClassModuleUrl(subscriptionId, classId, sectionId);
  };

  return (
    <ClassBreadcrumbs
      currentClass={currentClass}
      isRetailPlan={isRetailPlan}
      subject={subject}
      subscriptionId={String(subscriptionId)}
    >
      {ancestors.map((ancestor: Ancestor, i: number) => {
        const href = makeUrl(ancestor, i);
        return (
          <BreadcrumbsItem key={ancestor.id} href={href}>
            {ancestor.name}
          </BreadcrumbsItem>
        );
      })}
    </ClassBreadcrumbs>
  );
}
