// @flow
import { useEffect, useState } from 'react';
import yn from 'yn';
import { Alert } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import links from 'src/constants/links';

export default function ContentBlockerAlert() {
  const [isContentBlocked, setIsContentBlocked] = useState(false);

  useEffect(() => {
    if (yn(process.env.NEXT_PUBLIC_SENTRY_ENABLED) && !window.Sentry) {
      setIsContentBlocked(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    isContentBlocked && (
      <Alert isBanner variant="danger">
        This webpage did not load properly. Please check your internet connection, ensure any ad-blockers are turned off
        or{' '}
        <Link variant="monochrome" href={links.support.home} isExternal>
          visit our help centre
        </Link>{' '}
        for support.
      </Alert>
    )
  );
}
