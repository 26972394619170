// @flow
import { useMemo } from 'react';
import { connect } from 'react-redux';

import type { ReduxState } from 'src/types';
import { TabNav, Item } from 'src/components/TabNav/TabNav';
import { getActiveSubscription, isFamilyPlan as isFamilyPlanSelector } from 'src/reducers/subscriptions';
import { getSubscriptionUsersUrl } from 'src/utils/routes';

export const mapStateToProps = (state: ReduxState) => {
  const subscription = getActiveSubscription(state);
  const countActive = subscription.active_students_count + subscription.active_teachers_count;

  return {
    isFamilyPlan: isFamilyPlanSelector(state),
    countActive,
    countInvited: subscription.total_users_count - countActive,
  };
};

type Props = {
  subscriptionId: number,
};

type InjectedProps = Props & {
  countActive: number,
  countInvited: number,
  isFamilyPlan: boolean,
};

function UserStatusFilters(props: InjectedProps) {
  const { countActive, countInvited, isFamilyPlan, subscriptionId } = props;

  const items = useMemo(() => {
    const tabs = [
      {
        name: `Active (${countActive})`,
        status: 'active',
        href: getSubscriptionUsersUrl({ subscriptionId, status: 'active' }),
        activeMatcher: ({ search }) => search === '' || search.includes('active'),
      },
      {
        name: `Pending (${countInvited})`,
        status: 'invited',
        href: getSubscriptionUsersUrl({ subscriptionId, status: 'invited' }),
        activeMatcher: ({ search }) => search.includes('invited'),
      },
    ];
    if (!isFamilyPlan) {
      tabs.push({
        name: 'Archived',
        status: 'archived',
        href: getSubscriptionUsersUrl({ subscriptionId, status: 'archived' }),
        activeMatcher: ({ search }) => search.includes('archived'),
      });
    }
    return tabs;
  }, [countActive, countInvited, isFamilyPlan, subscriptionId]);

  return (
    <TabNav ariaLabel="User status navigation" items={items} variant="secondary">
      {items.map((item) => (
        <Item
          key={item.href}
          href={item.href}
          routerOptions={{
            // The href is used to set the proper HTML attribute, but the pathname + query are used
            // for client-side navigation.
            pathname: getSubscriptionUsersUrl({ subscriptionId }),
            query: { status: item.status },
            state: { refocus: false, scrollTop: false },
          }}
        >
          {item.name}
        </Item>
      ))}
    </TabNav>
  );
}

export default (connect(mapStateToProps)(UserStatusFilters): React.AbstractComponent<Props>);
