// @flow
import { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToast } from '@getatomi/neon';

import { parseValidationErrors } from 'src/api/validation';
import { formValidation } from 'src/constants/formValidation';

export type Fields = {|
  classes?: Set<string>,
  email: string,
  firstName: string,
  lastName: string,
  role: string | null,
|};

export type OutboundFields = {|
  classes?: Array<string>,
  email: string,
  firstName: string,
  lastName: string,
  role: string,
|};

const labels = {
  email: 'Email',
  role: 'Role',
  firstName: 'First name',
  lastName: 'Last name',
  classes: 'Want to add them to some classes?',
};

const resolver = yupResolver(
  yup.object().shape({
    email: yup.string().required().email().label(labels.email),
    firstName: yup.string().trim().label(labels.firstName).max(formValidation.firstName.maxLength),
    lastName: yup.string().trim().label(labels.lastName).max(formValidation.lastName.maxLength),
    role: yup.string().trim().nullable().required().label(labels.role),
    classes: yup.mixed().label(labels.classes),
  })
);

export default function useInviteUserForm({
  defaultValues,
  onSubmitFail,
  onSubmitSuccess,
}: {
  defaultValues: Fields,
  onSubmitFail: () => void,
  onSubmitSuccess: (data: OutboundFields) => Promise<void>,
}) {
  const { clearErrors, control, formState, handleSubmit, reset, setError, watch } = useForm({
    resolver,
    defaultValues,
  });
  const toast = useToast();
  const formRef = useRef<HTMLFormElement | null>(null);

  const onSubmit = async (event: Event) => {
    event.preventDefault();
    clearErrors('serverError');
    handleSubmit(async (data) => {
      try {
        const transformOutboundData = {
          ...data,
          classes: data.classes ? Array.from(data.classes) : [],
        };
        await onSubmitSuccess(transformOutboundData);
      } catch (error) {
        const validationErrors = parseValidationErrors(error);
        setError('serverError', { type: 'manual', message: validationErrors });
        if (validationErrors.length === 0) {
          onSubmitFail();
          toast.error('There was an error inviting this user. Please try again.');
        } else if (formRef.current) {
          formRef.current.scrollIntoView();
        }
      }
    })(event);
  };

  // reset the form if the submission was successful
  useEffect(() => {
    if (formState.isSubmitSuccessful) {
      reset(defaultValues);
    }
  }, [defaultValues, formState, reset]);

  const emailMessage = formState.errors.email?.message ?? null;
  const roleMessage = formState.errors.role?.message ?? null;
  const { serverError } = formState.errors;

  return {
    control,
    reset,
    serverError,
    form: {
      ref: formRef,
      onSubmit,
    },
    fields: {
      email: {
        label: labels.email,
        validationText: emailMessage,
        errorVariant: emailMessage && 'error',
        type: 'email',
        isRequired: true,
      },
      firstName: {
        label: labels.firstName,
        secondaryLabel: 'Optional',
      },
      lastName: {
        label: labels.lastName,
        secondaryLabel: 'Optional',
      },
      role: {
        label: labels.role,
        isRequired: true,
        validationText: roleMessage,
        errorVariant: roleMessage && 'error',
      },
      classes: {
        label: labels.classes,
        secondaryLabel: 'Optional',
      },
    },
    watch,
  };
}
