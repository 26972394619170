// @flow
import { useCallback } from 'react';
import { isString, pickBy, identity } from 'lodash';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToast } from '@getatomi/neon';

import type { FormPhoneNumber } from 'src/types';
import { parseValidationErrors } from 'src/api/validation';
import { formValidation } from 'src/constants/formValidation';

import phoneNumberValidator from './validators/phoneNumberValidator';

export type Fields = {
  email?: string,
  firstName?: string,
  lastName?: string,
  levelId?: ?number | ?string,
  phoneNumber?: ?FormPhoneNumber,
  position?: string,
  schoolId?: number | string,
};

export type OutboundFields = {
  email?: string,
  firstName?: string,
  lastName?: string,
  levelId?: ?number | ?string,
  phoneNumber?: ?string,
  position?: string,
  schoolId?: number | string,
};

// Transform internal field data to a flat structure for the consuming component. In this case, we
// are unwrapping the nested phone number (which, from Neon.PhoneNumber, contains other properties
// such as valid and value)
const transformOutboundData = (data: Fields): OutboundFields => {
  const isNamedSchool = isString(data.schoolId);
  const outboundData = {
    position: data.position,
    first_name: data.firstName,
    last_name: data.lastName,
    phone_number: data.phoneNumber?.number,
    level_id: data.levelId,
    school_name: isNamedSchool ? data.schoolId : undefined,
    school_id: !isNamedSchool ? data.schoolId : undefined,
  };

  return pickBy(outboundData, identity);
};

const labels = {
  email: 'Email',
  firstName: 'First name',
  lastName: 'Last name',
  levelId: 'Year level',
  phoneNumber: 'Phone number',
  position: 'Role',
  schoolId: 'School',
};

const resolver = yupResolver(
  yup.object().shape({
    firstName: yup.string().trim().label(labels.firstName).required().max(formValidation.firstName.maxLength),
    lastName: yup.string().trim().label(labels.lastName).required().max(formValidation.lastName.maxLength),
    schoolId: yup.mixed().when('$isSchoolIdFieldPresent', {
      is: true,
      then: yup.lazy((value) => {
        if (typeof value === 'number') {
          return yup.number().required().label(labels.schoolId);
        }
        return yup.string().required().label(labels.schoolId);
      }),
    }),
    levelId: yup.mixed().when('$isLevelIdFieldPresent', {
      is: true,
      then: yup.number().nullable().required().label(labels.levelId),
    }),
    position: yup.mixed().when('$isPositionFieldPresent', {
      is: true,
      then: yup.string().nullable().trim().label(labels.position).required(),
    }),
    phoneNumber: yup.mixed().when('$isPhoneFieldPresent', {
      is: true,
      then: yup.mixed().test('isValid', '', function test(phoneNumber) {
        return phoneNumberValidator({ phoneNumber, createError: this.createError });
      }),
    }),
  })
);

export default function useProfileForm({
  context,
  defaultValues,
  onSubmitFail,
  onSubmitSuccess,
}: {
  context: {
    isLevelIdFieldPresent?: boolean,
    isPhoneFieldPresent?: boolean,
    isPositionFieldPresent?: boolean,
    isSchoolIdFieldPresent?: boolean,
  },
  defaultValues: Fields,
  onSubmitFail: () => void,
  onSubmitSuccess: (data: OutboundFields) => void | Promise<void>,
}) {
  const { clearErrors, control, formState, handleSubmit, setError, setValue } = useForm({
    resolver,
    defaultValues,
    context,
  });
  const { isSubmitted } = formState;
  const toast = useToast();

  const handleSchoolChange = useCallback(
    (schoolOption: { label: string, value: string }) => {
      const schoolId = schoolOption.value ? parseInt(schoolOption.value, 10) : schoolOption.label;
      setValue('schoolId', schoolId, { shouldValidate: isSubmitted });
    },
    [isSubmitted, setValue]
  );

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    clearErrors('serverError');
    handleSubmit(async (data) => {
      // See "Redux in form hooks" in Forms - The Basics (README.md#redux-and-createresolver)
      try {
        await onSubmitSuccess(transformOutboundData(data));
      } catch (error) {
        const validationErrors = parseValidationErrors(error);
        setError('serverError', { type: 'manual', message: validationErrors });
        if (validationErrors.length === 0) {
          toast.error('There was an error updating your profile. Please try again.');
        }
      }
    }, onSubmitFail)(e);
  };

  const firstNameMessage = formState.errors.firstName?.message ?? null;
  const lastNameMessage = formState.errors.lastName?.message ?? null;
  const schoolIdMessage = formState.errors.schoolId?.message ?? null;
  const levelIdMessage = formState.errors.levelId?.message ?? null;
  const positionMessage = formState.errors.position?.message ?? null;
  const phoneNumberMessage = formState.errors.phoneNumber?.message ?? null;

  const { serverError } = formState.errors;

  return {
    control,
    setValue,
    serverError,
    form: {
      onSubmit,
    },
    fields: {
      firstName: {
        label: labels.firstName,
        validationText: firstNameMessage,
        errorVariant: firstNameMessage && 'error',
        isRequired: true,
      },
      lastName: {
        label: labels.lastName,
        validationText: lastNameMessage,
        errorVariant: lastNameMessage && 'error',
        isRequired: true,
      },
      email: {
        label: labels.email,
        isReadOnly: true,
      },
      schoolId: {
        label: labels.schoolId,
        validationText: schoolIdMessage,
        errorVariant: schoolIdMessage && 'error',
        onSelectionChange: handleSchoolChange,
      },
      levelId: {
        label: labels.levelId,
        validationText: levelIdMessage,
        errorVariant: levelIdMessage && 'error',
      },
      position: {
        label: labels.position,
        validationText: positionMessage,
        errorVariant: positionMessage && 'error',
      },
      phoneNumber: {
        label: labels.phoneNumber,
        validationText: phoneNumberMessage,
        errorVariant: phoneNumberMessage && 'error',
        isRequired: true,
      },
    },
  };
}
