// @flow
import _ from 'lodash';
import invariant from 'invariant';

import type { UserAccountStatusType } from 'src/types';
import type {
  GetClassInsights_me_account_class as ClassData,
  GetClassInsights_me_account_class_metrics_ClassMetrics_assessment as ClassAssessmentMetrics,
  GetClassInsights_me_account_class_metrics_ClassMetrics_progress as ClassProgressMetrics,
  GetClassInsights_me_account_class_metrics_ClassMetrics_tasks as ClassTasksMetrics,
  GetClassInsights_me_account_class_students as ClassStudents,
  GetClassInsights_me_account_class_topics as Topic,
} from 'src/graphql/types/generated/GetClassInsights';
import createCellValue from 'src/utils/createCellValue';
import userFullName from 'src/utils/userFullName';

import type { AverageMarkCell, LessonsCompletedCell, QuizzesCompletedCell } from './prepareStudentTableColumns';

export type Student = {|
  accountStatus: UserAccountStatusType,
  avatar: ?string,
  color: number,
  email: string,
  firstName: string,
  fullName: string,
  id: string,
  lastName: string,
  sortByValue: ?string,
|};

type PreparedMetrics = {|
  averageMarkPercentage: AverageMarkCell,
  lessonsCompleted: LessonsCompletedCell,
  quizzesCompleted: QuizzesCompletedCell,
|};

export type StudentTableRow = {|
  rowMeta: Student,
  ...PreparedMetrics,
|};

export type StudentHighlight = {|
  icon: string,
  label: string,
  students: $ReadOnlyArray<Student>,
|};

export type RankedTopic = {
  activeStudents: number,
  challengesCompleted: number,
  completionPercentage: number,
  id: string,
  lessonsCompleted: number,
  moduleName: string,
  name: string,
  totalChallenges: number,
  totalLessons: number,
};

type TaskProgressStatusPercentages = {
  done: number,
  inProgress: number,
  overdue: number,
  toDo: number,
};

export type TaskProgress = {
  assigned: number,
  doneOnTimePercentage: number,
  statusPercentages: TaskProgressStatusPercentages,
};

export type ClassInsights = {
  highlights: $ReadOnlyArray<StudentHighlight>,
  rankedTopics: $ReadOnlyArray<RankedTopic>,
  students: $ReadOnlyArray<StudentTableRow>,
  taskProgress: ?TaskProgress,
};

function prepareStudentData(student: ClassStudents): Student {
  const upperFirstName = _.upperFirst(student.firstName);
  const upperLastName = _.upperFirst(student.lastName);

  return {
    accountStatus: student.accountStatus,
    avatar: student.avatar,
    color: student.color,
    email: student.email,
    firstName: upperFirstName,
    // $FlowIgnore - we expect students with metrics to have name data
    fullName: userFullName(student.firstName, student.lastName),
    id: student.id,
    lastName: upperLastName,
    sortByValue: upperLastName,
  };
}

export function getRankedTopics(topics: $ReadOnlyArray<Topic>): $ReadOnlyArray<RankedTopic> {
  const transformedTopics = [];

  for (const topic of topics) {
    invariant(topic.__typename === 'ClassCategory', 'Only topics of type `ClassCategory` can have a metrics field.');

    const { assessment, progress, lessonCount, assessmentCount } = topic.metrics;
    if (progress.completionRate > 0) {
      transformedTopics.push({
        id: topic.id,
        name: topic.name,
        completionPercentage: _.round(progress.completionRate * 100, 0),
        moduleName: topic.categories[0].name,
        activeStudents: progress.engagedStudentCount,
        totalLessons: lessonCount,
        totalChallenges: assessmentCount,
        challengesCompleted: assessment.completedCount,
        lessonsCompleted: progress.completedCount - assessment.completedCount,
      });
    }
  }

  return _.orderBy(transformedTopics, 'completionPercentage', 'desc');
}

export function transformTaskProgress(taskMetrics: ClassTasksMetrics): ?TaskProgress {
  if (!taskMetrics.publishedCount) {
    return null;
  }

  return {
    assigned: taskMetrics.publishedCount,
    doneOnTimePercentage: _.round(taskMetrics.onTimeCompletionRate * 100),
    statusPercentages: {
      done: _.round(taskMetrics.completionRate * 100),
      toDo: _.round(taskMetrics.notStartedRate * 100),
      inProgress: _.round(taskMetrics.inProgressRate * 100),
      overdue: _.round(taskMetrics.overdueRate * 100),
    },
  };
}

export function transformStudentRow(
  studentId: string,
  assessmentMetrics: ClassAssessmentMetrics,
  progressMetrics: ClassProgressMetrics
): PreparedMetrics {
  const assessmentStudent = assessmentMetrics.students.find((student) => student.studentId === studentId);
  const progressStudent = progressMetrics.students.find((student) => student.studentId === studentId);

  let averageMarkPercentageDisplay;
  let averageMarkPercentageSortBy;
  let lessonsCompleted = 0;
  let quizzesCompleted = 0;
  if (assessmentStudent && assessmentStudent.averageMark != null) {
    const averageMarkPercentage = _.round(assessmentStudent.averageMark * 100);
    averageMarkPercentageDisplay = `${averageMarkPercentage}%`;
    averageMarkPercentageSortBy = averageMarkPercentage;
  }
  if (progressStudent && assessmentStudent) {
    lessonsCompleted = progressStudent.completedCount - assessmentStudent.completedCount;
    quizzesCompleted = assessmentStudent.completedCount;
  }

  return {
    averageMarkPercentage: createCellValue(averageMarkPercentageDisplay, averageMarkPercentageSortBy),
    lessonsCompleted: createCellValue(lessonsCompleted),
    quizzesCompleted: createCellValue(quizzesCompleted),
  };
}

export function transformHighlights(studentRows: $ReadOnlyArray<StudentTableRow>): $ReadOnlyArray<StudentHighlight> {
  const studentData = studentRows.map((studentRow, rowIndex) => ({
    rowIndex,
    lessonsCompleted: studentRow.lessonsCompleted.sortByValue,
    quizzesCompleted: studentRow.quizzesCompleted.sortByValue,
    averageMark: studentRow.averageMarkPercentage.sortByValue,
  }));

  const mostLessonsCompleted = _.maxBy(studentData, 'lessonsCompleted')?.lessonsCompleted;
  const mostQuizzesCompleted = _.maxBy(studentData, 'quizzesCompleted')?.quizzesCompleted;
  const highestAverageMark = _.maxBy(studentData, 'averageMark')?.averageMark;
  const lowestAverageMark = _.minBy(studentData, 'averageMark')?.averageMark;

  const highlights = [];

  if (mostLessonsCompleted > 0) {
    const studentsWithMostLessonsCompleted = studentData.filter(
      ({ lessonsCompleted }) => lessonsCompleted === mostLessonsCompleted
    );
    highlights.push({
      icon: 'circle-play',
      label: 'Most lessons completed',
      students: studentsWithMostLessonsCompleted.map(({ rowIndex }) => studentRows[rowIndex].rowMeta),
    });
  }

  if (mostQuizzesCompleted > 0) {
    const studentsWithMostQuizzesCompleted = studentData.filter(
      ({ quizzesCompleted }) => quizzesCompleted === mostQuizzesCompleted
    );
    highlights.push({
      icon: 'circle-help',
      label: 'Most quizzes completed',
      students: studentsWithMostQuizzesCompleted.map(({ rowIndex }) => studentRows[rowIndex].rowMeta),
    });
  }

  if (highestAverageMark != null) {
    const studentsWithHighestAvgMark = studentData.filter(({ averageMark }) => averageMark === highestAverageMark);
    highlights.push({
      icon: 'check',
      label: 'Highest average mark',
      students: studentsWithHighestAvgMark.map(({ rowIndex }) => studentRows[rowIndex].rowMeta),
    });
  }

  if (lowestAverageMark != null) {
    const studentsWithLowestAvgMark = studentData.filter(({ averageMark }) => averageMark === lowestAverageMark);
    highlights.push({
      icon: 'alert-error',
      label: 'Lowest average mark',
      students: studentsWithLowestAvgMark.map(({ rowIndex }) => studentRows[rowIndex].rowMeta),
    });
  }

  return highlights;
}

export default function classInsightsTransformer(classData: ClassData): ClassInsights {
  const { topics, metrics, students } = classData;

  invariant(metrics.__typename === 'ClassMetrics', 'Metrics type should be ClassMetrics');

  const rankedTopics = getRankedTopics(topics);

  const studentRows = [];
  for (const student of students) {
    const studentData = prepareStudentData(student);
    const { averageMarkPercentage, lessonsCompleted, quizzesCompleted } = transformStudentRow(
      student.id,
      metrics.assessment,
      metrics.progress
    );

    studentRows.push({
      rowMeta: studentData,
      averageMarkPercentage,
      lessonsCompleted,
      quizzesCompleted,
    });
  }

  const highlights = transformHighlights(studentRows);

  return {
    highlights,
    students: studentRows,
    rankedTopics,
    taskProgress: transformTaskProgress(metrics.tasks),
  };
}
