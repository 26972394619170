// @flow
import { useCallback } from 'react';
import { Box, HideVisually, Scrollable, Table, useTableSelection } from '@getatomi/neon';

import ActionBar from 'src/components/ActionBar/ActionBar';
import Button from 'src/components/Button/Button';
import type { LoaderColumn, LoaderData } from 'src/utils/generateTableLoadingData';
import { type SortBy, initialTableState } from 'src/hooks/useSortedStudents';
import useTaskDialog from 'src/components/useTaskDialog/useTaskDialog';

import type { Column as CompletionColumn } from '../Completion/prepareCompletionColumns';
import type { Column as MarksColumn } from '../Marks/prepareMarksColumns';
import type { TableColumn as TasksColumn } from '../Tasks/prepareTasksColumns';
import type { ClassAverageRow as CompletionClassAverageRow, CompletionData } from '../Completion/completionTransformer';
import type { ClassAverageRow as MarksClassAverageRow, MarksData } from '../Marks/marksTransformer';
import type { ClassAverageRow as TasksClassAverageRow, TasksData } from '../Tasks/tasksTransformer';

export type MarkbookTableProps = {
  caption: string,
  classId: string,
  columns: $ReadOnlyArray<CompletionColumn | MarksColumn | TasksColumn | LoaderColumn>,
  data:
    | $ReadOnlyArray<
        CompletionClassAverageRow | MarksClassAverageRow | TasksClassAverageRow | CompletionData | MarksData | TasksData
      >
    | $ReadOnlyArray<LoaderData>,
  isLoading?: boolean,
  isMobile: boolean,
  isSortable?: boolean,
  subscriptionId: string,
  toggleSortBy?: (value: Array<SortBy>) => void,
};

function MarkbookTable(props: MarkbookTableProps) {
  const { classId, isLoading, isMobile, isSortable, caption, columns, data, subscriptionId, toggleSortBy } = props;

  // Passing in an initial state before the table has loaded throws an error
  const initialState = isLoading ? undefined : initialTableState;

  const flattenedColumns = [];
  const getColGroupData = (column) => ({
    accessorKey: column.accessorKey,
    customWidth: column.meta?.width,
  });
  columns.forEach((column) => {
    // $FlowIgnore
    const subColumns = column.columns;
    if (!subColumns) {
      flattenedColumns.push(getColGroupData(column));
      return;
    }

    subColumns.forEach((subColumn) => {
      const subSubColumns = subColumn.columns;
      if (!subSubColumns) {
        flattenedColumns.push(getColGroupData(subColumn));
        return;
      }

      subSubColumns.forEach((subSubColumn) => {
        flattenedColumns.push(getColGroupData(subSubColumn));
      });
    });
  });

  // don't display checkbox for the class average row
  const enableSelection = useCallback((row) => Boolean(row.original?.rowMeta?.id), []);
  // row.id isn't set in this data: we use rowMeta.id instead
  const getIdentifier = useCallback((row) => row.rowMeta.id, []);
  const { resetSelection, selectedIds, tableProps } = useTableSelection({
    data,
    initialState,
    enableSelection,
    getIdentifier,
  });

  const [taskDialog, { openTaskDialog }] = useTaskDialog({
    subscriptionId,
    classId,
    onSubmit: resetSelection,
  });

  return (
    <>
      <Box overflow="hidden" paddingBottom="spacingLarge">
        <Scrollable direction="horizontal">
          <Table
            {...tableProps}
            isLoading={isLoading}
            isFixedLayout
            isStickyHeadings
            columns={columns}
            data={data}
            isSortable={isSortable}
            toggleSortBy={toggleSortBy}
          >
            <caption>
              <HideVisually>{caption}</HideVisually>
            </caption>
            <colgroup data-test="colgroup">
              <col data-test="col" key="select" width="50" />
              {flattenedColumns.map((column) => {
                const { accessorKey, customWidth } = column;
                let width;
                if (customWidth) {
                  width = customWidth;
                } else {
                  switch (accessorKey) {
                    case 'loading-0':
                      width = 50;
                      break;
                    case 'rowMeta':
                    case 'loading-1':
                      width = isMobile ? 160 : 220;
                      break;
                    default:
                      width = 180;
                      break;
                  }
                }
                return <col data-test="col" key={accessorKey} width={width} />;
              })}
            </colgroup>
          </Table>
        </Scrollable>
      </Box>
      <ActionBar selectedItemCount={selectedIds.length} onClearSelection={resetSelection}>
        <Button onClick={() => openTaskDialog({ students: selectedIds })}>Create task</Button>
      </ActionBar>
      {taskDialog}
    </>
  );
}

export default MarkbookTable;
