// @flow
import { createStore as _createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createReduxEnhancer } from '@sentry/react';
import yn from 'yn';
import type Cookies from 'universal-cookie';

import apiMiddleware from 'src/middleware/apiMiddleware';
import refreshAuthTokenMiddleware from 'src/middleware/refreshAuthTokenMiddleware';
import trackingMiddleware from 'src/middleware/trackingMiddleware';
import rootReducer from 'src/reducers';
import { isSentryLoaded } from 'src/utils/sentry';
import Auth from 'src/utils/Auth';

export default function createStore({
  pathname,
  rawCookies,
  universalCookies,
  data = {},
}: {
  data?: Object,
  pathname?: string,
  rawCookies?: string,
  universalCookies?: Cookies,
}) {
  const auth = new Auth(universalCookies);
  const middlewares = [
    refreshAuthTokenMiddleware,
    apiMiddleware(pathname, rawCookies, auth),
    thunk.withExtraArgument(auth),
    trackingMiddleware,
  ].filter(Boolean);
  // fall back to compose if Redux DevTools are not installed
  const composeEnhancers = (__CLIENT__ && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
  const sentryReduxEnhancer =
    yn(process.env.NEXT_PUBLIC_SENTRY_ENABLED) &&
    isSentryLoaded() &&
    createReduxEnhancer({
      // since most of our actions trigger an API call which are logged in the error breadcrumbs we
      // can avoid having the redux actions in there: this will reduce the risk of reaching the
      // event size limit
      actionTransformer: () => null,
    });

  const store = _createStore<any, any, any, _>(
    rootReducer,
    data,
    sentryReduxEnhancer
      ? composeEnhancers(applyMiddleware(...middlewares), sentryReduxEnhancer)
      : composeEnhancers(applyMiddleware(...middlewares))
  );

  return store;
}
