// @flow
import { useMemo } from 'react';

import { TabNav, Item } from 'src/components/TabNav/TabNav';
import { getTasksUrl } from 'src/utils/routes';

type Props = {|
  subscriptionId: string,
|};

export default function StudentTasksNav(props: Props) {
  const { subscriptionId } = props;

  const items = useMemo(
    () => [
      { href: getTasksUrl(subscriptionId), name: 'To do' },
      {
        href: getTasksUrl(subscriptionId, 'done'),
        name: 'Done',
      },
    ],
    [subscriptionId]
  );

  return (
    <TabNav ariaLabel="Tasks navigation" items={items} variant="secondary">
      {items.map((item) => (
        <Item key={item.href} href={item.href} routerOptions={{ state: { refocus: false, scrollTop: false } }}>
          {item.name}
        </Item>
      ))}
    </TabNav>
  );
}
