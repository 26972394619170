// @flow
import { useQuery, type ApolloError } from '@apollo/client';
import invariant from 'invariant';

import type { ChallengeTier, PostType } from 'src/types';
import type {
  GetLesson,
  GetLessonVariables,
  GetLesson_me_account_class_metrics as GetLessonClassMetrics,
} from 'src/graphql/types/generated/GetLesson';
import postTypes from 'src/constants/postTypes';
import challengeTiers from 'src/constants/challengeTiers';

import GET_LESSON from './GetLesson.graphql';

type Props = {|
  classId: string,
  isStudent: boolean,
  lessonId: string,
  subscriptionId: string,
|};

type Category = {|
  id: string,
  name: string,
  parentId: ?string,
|};

export type Challenge = {|
  id: string,
  questionsCount: number,
  title: string,
  totalMarks: number,
|};

export type Lesson = {|
  categories: $ReadOnlyArray<Category>,
  challenge?: Challenge,
  completionCount: number,
  description: ?string,
  duration: number,
  hasTasks?: boolean,
  id: string,
  isLocked: boolean,
  name: string,
  studentStrength?: number,
  tier?: ChallengeTier,
  type: PostType,
  videoId?: string,
|};

export type Subject = {|
  code: string,
  color: string,
  groupCode: string,
  name: string,
|};

export type CurrentClass = {|
  id: string,
  level: ?{
    id: string,
    name: string,
  },
  name: string,
  studentCount: number,
  subject: Subject,
|};

export type Output = {|
  currentClass: ?CurrentClass,
  error: ?ApolloError,
  isCrossSubject: boolean,
  lesson: ?Lesson,
  loading: boolean,
  nextLesson: ?Lesson,
  previousLesson: ?Lesson,
  refetch: () => Promise<any>,
  region: ?string,
|};

type GqlCategory = { +id: string, +name: string, +parentId: ?string };

function transformCategories(gqlCategories: $ReadOnlyArray<GqlCategory>): $ReadOnlyArray<Category> {
  return gqlCategories.map(({ id, name, parentId }: GqlCategory): Category => ({ id, name, parentId }));
}

function transformLesson(rawLesson): Lesson {
  invariant(
    rawLesson.__typename === 'ClassChallengeLesson' ||
      rawLesson.__typename === 'ClassLockedChallengeLesson' ||
      rawLesson.__typename === 'ClassVideoLesson' ||
      rawLesson.__typename === 'ClassLockedVideoLesson' ||
      rawLesson.__typename === 'ClassTextLesson' ||
      rawLesson.__typename === 'ClassLockedTextLesson' ||
      rawLesson.__typename === 'StudentChallengeLesson' ||
      rawLesson.__typename === 'StudentLockedChallengeLesson' ||
      rawLesson.__typename === 'StudentVideoLesson' ||
      rawLesson.__typename === 'StudentLockedVideoLesson' ||
      rawLesson.__typename === 'StudentTextLesson' ||
      rawLesson.__typename === 'StudentLockedTextLesson',
    `Unexpected lesson type: ${rawLesson.__typename}`
  );

  let challenge: Challenge;
  let tier: ChallengeTier;
  let type = postTypes.text;
  let videoId;
  if (rawLesson.__typename === 'ClassChallengeLesson' || rawLesson.__typename === 'StudentChallengeLesson') {
    const { questionsCount, totalMarks } = rawLesson.challenge;

    challenge = {
      id: rawLesson.challenge.id,
      title: rawLesson.challenge.title,
      questionsCount,
      totalMarks,
    };

    tier = challengeTiers[rawLesson.tier];
    type = postTypes.challenge;
  }
  if (
    rawLesson.__typename === 'ClassLockedChallengeLesson' ||
    rawLesson.__typename === 'StudentLockedChallengeLesson'
  ) {
    type = postTypes.challenge;
  }
  if (rawLesson.__typename === 'ClassVideoLesson' || rawLesson.__typename === 'StudentVideoLesson') {
    videoId = rawLesson.videoId;
    type = postTypes.video;
  }
  if (rawLesson.__typename === 'ClassLockedVideoLesson' || rawLesson.__typename === 'StudentLockedVideoLesson') {
    type = postTypes.video;
  }

  let completionCount = 0;
  if (
    rawLesson.__typename === 'ClassChallengeLesson' ||
    rawLesson.__typename === 'ClassVideoLesson' ||
    rawLesson.__typename === 'ClassTextLesson'
  ) {
    completionCount = rawLesson?.metrics.progress.engagedStudentCount;
  }
  if (
    rawLesson.__typename === 'StudentChallengeLesson' ||
    rawLesson.__typename === 'StudentVideoLesson' ||
    rawLesson.__typename === 'StudentTextLesson'
  ) {
    completionCount = rawLesson?.metrics.progress.cumulativeCompletionCount;
  }

  let studentStrength;
  if (rawLesson.__typename === 'StudentChallengeLesson') {
    studentStrength = rawLesson?.metrics.assessment.strength ?? 0;
  }

  const categories = transformCategories(rawLesson.categories);

  return {
    categories,
    challenge,
    completionCount,
    description: rawLesson.description,
    duration: rawLesson.duration,
    id: rawLesson.id,
    isLocked: rawLesson.__typename.includes('Locked'),
    name: rawLesson.name,
    studentStrength,
    tier,
    type,
    videoId,
  };
}

function getStudentCount(metrics: ?GetLessonClassMetrics): number {
  if (metrics && metrics.__typename === 'ClassMetrics') {
    return metrics.studentCount;
  }

  return 0;
}

export default function useGetLesson(props: Props): Output {
  const { classId, isStudent, lessonId, subscriptionId } = props;

  const { data, error, loading, refetch } = useQuery<GetLesson, GetLessonVariables>(GET_LESSON, {
    variables: {
      accountId: subscriptionId,
      classId,
      isStudent,
      lessonId,
    },
  });

  if (error) {
    return {
      currentClass: null,
      error,
      isCrossSubject: false,
      lesson: null,
      loading: false,
      nextLesson: null,
      previousLesson: null,
      refetch,
      region: null,
    };
  }

  if (loading && !data) {
    return {
      currentClass: null,
      error: null,
      isCrossSubject: false,
      lesson: null,
      loading: true,
      nextLesson: null,
      previousLesson: null,
      refetch,
      region: null,
    };
  }

  const rawClass = data?.me?.account?.class;
  const rawLesson = rawClass?.lesson;
  invariant(rawClass && rawLesson, 'Lesson data is missing');

  const lesson = {
    ...transformLesson(rawLesson),
    hasTasks: Boolean(rawClass?.tasks?.edges.length),
  };
  const currentClass = {
    id: rawClass.id,
    level: rawClass.level && {
      id: rawClass.level.id,
      name: rawClass.level.name,
    },
    name: rawClass.name,
    studentCount: getStudentCount(rawClass.metrics),
    subject: {
      code: rawClass.subject.code,
      color: rawClass.subject.color,
      groupCode: rawClass.subject.groupCode,
      name: rawClass.subject.shortName,
    },
  };

  // BaseLesson types don't have `previousLesson` and `nextLesson` fields.
  invariant(
    rawLesson.__typename === 'ClassChallengeLesson' ||
      rawLesson.__typename === 'ClassLockedChallengeLesson' ||
      rawLesson.__typename === 'ClassVideoLesson' ||
      rawLesson.__typename === 'ClassLockedVideoLesson' ||
      rawLesson.__typename === 'ClassTextLesson' ||
      rawLesson.__typename === 'ClassLockedTextLesson' ||
      rawLesson.__typename === 'StudentChallengeLesson' ||
      rawLesson.__typename === 'StudentLockedChallengeLesson' ||
      rawLesson.__typename === 'StudentVideoLesson' ||
      rawLesson.__typename === 'StudentLockedVideoLesson' ||
      rawLesson.__typename === 'StudentTextLesson' ||
      rawLesson.__typename === 'StudentLockedTextLesson',
    `Unexpected lesson type: ${rawLesson.__typename}`
  );

  const rawNextLesson = rawLesson.nextLesson;
  const nextLesson = rawNextLesson && transformLesson(rawNextLesson);

  const rawPreviousLesson = rawLesson.previousLesson;
  const previousLesson = rawPreviousLesson && transformLesson(rawPreviousLesson);

  const isCrossSubject = rawClass.subject.code !== rawLesson.subject.code;

  return {
    currentClass,
    error: null,
    isCrossSubject,
    lesson,
    loading: false,
    nextLesson,
    previousLesson,
    refetch,
    region: data?.me?.account?.region.code,
  };
}
