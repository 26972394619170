// @flow
import { useState } from 'react';
import pluralize from 'pluralize';
import {
  Alert,
  Box,
  Container,
  Dropdown,
  Divider,
  Heading,
  HelpInfo,
  IconAlertError,
  Item,
  Stack,
  Text,
  Textarea,
  useToast,
} from '@getatomi/neon';
import { Controller } from 'react-hook-form';
import _ from 'lodash';

import Button from 'src/components/Button/Button';
import Link from 'src/components/Link/Link';
import links from 'src/constants/links';
import { trackingCtas } from 'src/constants/tracking';

import useBulkInviteUsersForm, { type OutboundFields } from './useBulkInviteUsersForm';

export type BulkInviteUsersDialogProps = {|
  accountName: string,
  bulkInviteUsers: Function,
  headingId: string,
  isInvitingUsers?: boolean,
  isSetupModeActive: boolean,
  onClose: () => void,
  onGoBack: (event: Event) => void,
  onSuccess: () => mixed,
  roles: Array<{
    key: string,
    name: string,
  }>,
|};

export default function BulkInviteUsersDialog(props: BulkInviteUsersDialogProps) {
  const {
    accountName,
    bulkInviteUsers,
    isInvitingUsers,
    isSetupModeActive,
    headingId,
    onClose,
    onGoBack,
    onSuccess,
    roles,
  } = props;

  const toast = useToast();
  const [serverErrors, setServerErrors] = useState<Object>({});
  const countErrors = Object.keys(serverErrors).length;
  const hasImportErrors = countErrors > 0;

  const showSuccessToast = (countUsers: number) => {
    if (isSetupModeActive) {
      const toastId = toast.success(
        <Stack spacing="spacingSmall2X">
          <p>
            {pluralize('This', countUsers)} {pluralize('user', countUsers)} {pluralize('was', countUsers)} invited
            successfully, however because your account is in setup mode invitations have not been sent yet.
          </p>
          <Link href={links.support.setupMode} onClick={() => toast.hide(toastId)} isExternal>
            Learn more
          </Link>
        </Stack>,
        { duration: Infinity }
      );
    } else {
      toast.success(
        `${pluralize('This', countUsers)} ${pluralize('user', countUsers)} ${pluralize(
          'was',
          countUsers
        )} invited successfully.`
      );
    }
  };

  const { control, countEmails, fields, form, watch } = useBulkInviteUsersForm({
    defaultValues: {
      emails: '',
      role: null,
    },
    onSubmitSuccess: async ({ emails, role }: OutboundFields) => {
      const validationErrors = await bulkInviteUsers(role, emails);
      if (Object.keys(validationErrors).length === 0) {
        onSuccess();
        showSuccessToast(emails.length);
        return onClose();
      }
      setServerErrors(validationErrors);
    },
    onSubmitFail: () => {
      onClose();
    },
  });

  const getButtonName = () => {
    let buttonName = 'Invite users';
    if (countEmails && countEmails > 0) {
      buttonName = `Invite ${countEmails} ${pluralize('user', countEmails)}`;
    }

    return buttonName;
  };

  const handleClose = () => {
    if (countErrors !== countEmails) {
      onSuccess();
    }
    onClose();
  };

  return (
    <Box as="form" {...form} paddingBottom="spacingLarge4X">
      <Container maxWidth="sizeContainerRoot" textAlign="center" marginBottom="spacingLarge4X">
        {hasImportErrors ? (
          <Heading as="h1" id={headingId}>
            {countErrors === countEmails ? 'That didn’t work!' : 'That was only partially successful'}
          </Heading>
        ) : (
          <Stack spacing="spacingLarge2X">
            <Heading as="h1" id={headingId}>
              Bulk import users
            </Heading>
            <Text as="p" variant="lead">
              Import users using a list of email addresses and they’ll be invited to join your school account all in one
              go.
            </Text>
          </Stack>
        )}
      </Container>
      <Container maxWidth="sizeContainerSmall">
        <Stack spacing="spacingLarge4X">
          <Divider />
          {hasImportErrors ? (
            <>
              <Alert variant="danger">
                <>
                  You’ve {isSetupModeActive ? 'added' : 'invited'}{' '}
                  <strong>
                    {countEmails} {pluralize('users', countEmails)}
                  </strong>{' '}
                  to {accountName}, but{' '}
                  <strong>
                    {countErrors} {pluralize('invitations', countErrors)}
                  </strong>{' '}
                  didn’t send. <br />
                  Please review the errors below.
                </>
              </Alert>
              <Stack as="ul" spacing="spacingLarge3X">
                {
                  // $FlowIgnore
                  _.map(serverErrors, (error, email) => (
                    <div key={email}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        borderBottomWidth="borderWidthLarge"
                        borderBottomStyle="solid"
                        borderBottomColor="colorBorderDanger"
                        marginBottom="spacingSmall1X"
                        paddingBottom="spacingSmall1X"
                      >
                        <Text variant="bodySmall" wordBreak="break-word">
                          {email}
                        </Text>
                        <IconAlertError color="colorIconDanger" size="sizeIconSmall" />
                      </Box>
                      <Text variant="bodySmall1X" color="colorTextDanger" fontWeight="fontWeightBold">
                        {error[0].message[0]}
                      </Text>
                    </div>
                  ))
                }
              </Stack>
              <Box textAlign="center">
                <Button onClick={handleClose}>Done</Button>
              </Box>
            </>
          ) : (
            <>
              <Controller
                control={control}
                name="role"
                render={({ field }) => (
                  <Dropdown
                    {...field}
                    {...fields.role}
                    items={roles}
                    selectedKey={field.value}
                    onSelectionChange={field.onChange}
                  >
                    {(item) => <Item>{item.name}</Item>}
                  </Dropdown>
                )}
              />
              <Controller
                control={control}
                name="emails"
                render={({ field }) => <Textarea {...field} {...fields.emails} rows={5} />}
              />
              <Box textAlign="center">
                <Button
                  type="submit"
                  isLoading={isInvitingUsers}
                  marginBottom="spacingLarge3X"
                  trackingData={{
                    cta: trackingCtas.subscriptionInviteUsers,
                    userType: watch('role'),
                  }}
                >
                  {getButtonName()}
                </Button>
                <Text variant="bodySmall1X" color="colorTextSubtler">
                  or{' '}
                  <Button variant="text" size="small" onClick={onGoBack}>
                    Go back.
                  </Button>
                </Text>
              </Box>
              <HelpInfo>
                Having trouble?{' '}
                <Link href={links.support.bulkImportUsers} variant="monochrome" isExternal>
                  Learn more about bulk importing users in the Help Centre.
                </Link>
              </HelpInfo>
            </>
          )}
        </Stack>
      </Container>
    </Box>
  );
}
