// @flow
import pluralize from 'pluralize';
import { Box, Heading, HideVisually } from '@getatomi/neon';

import Date from 'src/components/Date/Date';
import Price from 'src/components/Price/Price';
import type { CurrencyType } from 'src/types';

import BillingDatePeriod from '../BillingDatePeriod/BillingDatePeriod';
import BillingEstimateRow from './BillingEstimateRow/BillingEstimateRow';
import styles from './BillingEstimate.module.scss';

export type BillingEstimateProps = {|
  currency: CurrencyType,
  estimate: Object,
  plan: Object,
|};

export default function BillingEstimate(props: BillingEstimateProps) {
  const { currency, estimate, plan } = props;

  return (
    <Box as="table" borderCollapse="collapse">
      {/** Caption with heading and billing period **/}
      <caption className={styles.caption}>
        <Heading variant="small1X">Billing estimate</Heading>
        <BillingDatePeriod
          interval={plan.billing_interval}
          nextStartDate={estimate.next_billing_at}
          period={plan.billing_period}
        />
      </caption>

      {/** Hidden columns headings **/}
      <HideVisually as="thead">
        <tr>
          <th scope="col">Description</th>
          <th scope="col">Amount</th>
        </tr>
      </HideVisually>

      <tbody>
        {/** Line items **/}
        {estimate.line_items.map(({ amount, description, quantity, unit_amount: unitAmount }, i) => (
          <BillingEstimateRow
            key={i}
            amount={amount}
            currency={currency}
            heading={`${quantity} × ${description} ${pluralize('seat', quantity)}`}
            description={
              <span>
                <Price currency={currency} value={unitAmount} removeZeroDecimals /> per seat per {plan.billing_period}.
              </span>
            }
          />
        ))}

        {/** Discounts **/}
        {estimate.discounts.map(({ amount, description }, i) => (
          <BillingEstimateRow
            key={i}
            amount={amount}
            currency={currency}
            heading="Discount"
            description={`${description}.`}
            isDiscount
          />
        ))}
      </tbody>

      <tfoot>
        {/** Total **/}
        <BillingEstimateRow
          currency={currency}
          isTotal
          withCurrency
          heading={
            <span>
              Total due on <Date value={estimate.next_billing_at} testHook="hide-in-percy" />
            </span>
          }
          description={`This estimate is based on your current seats, in ${currency}${
            plan.tax_code ? ` including ${plan.tax_code.toUpperCase()}` : ''
          }.`}
          amount={estimate.total}
        />
      </tfoot>
    </Box>
  );
}
