// @flow
import { StatusLight } from '@getatomi/neon';

import { Dropdown, Item, ItemLabel } from 'src/components/Dropdown/Dropdown';

import type { TaskFilterClasses } from '../StudentTasks/useGetStudentTasks';

export type TaskFilters = {
  classId?: ?string,
};

type Props = {
  classes: TaskFilterClasses,
  filters: TaskFilters,
  onClassIdChange: (classId: ?string) => mixed,
};

export default function useStudentTaskListFilters(props: Props) {
  const {
    classes,
    filters: { classId },
    onClassIdChange,
  } = props;
  const isFiltering = Boolean(classId);

  const filters = (
    <Dropdown
      variant="filter"
      ariaLabel="Filter by class"
      items={[
        { color: null, label: 'All classes', value: null },
        ...classes.map(({ id, subject }) => ({
          color: subject.color,
          label: subject.shortName,
          value: id,
        })),
      ]}
      selectedKey={String(classId)}
      // The Dropdown is immediately unmounted when the user clicks on a filter (the loading
      // state is displayed), which causes an error when the component tries to reset the
      // focus on the trigger. This ONLY happens in Cypress. The workaround is to trigger the
      // callback at the end of the event loop:
      // https://github.com/adobe/react-spectrum/discussions/1937.
      onSelectionChange={(key) => setTimeout(() => onClassIdChange(key === 'null' ? null : key), 0)}
    >
      {(item) => (
        <Item key={item.value} textValue={item.label}>
          {item.color && <StatusLight color={item.color} />}
          <ItemLabel>{item.label}</ItemLabel>
        </Item>
      )}
    </Dropdown>
  );
  const clearFilters = () => {
    onClassIdChange(null);
  };

  return [isFiltering, filters, clearFilters];
}
