// @flow
import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Container, Flex, Heading, Spinner, Stack, Text } from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import Logger from 'src/utils/Logger';

type LoginViaPopupProps = {|
  redirect: () => void,
|};

type RequestAccessProps = {|
  grantAccess: (state: boolean) => void,
  requestAccess: (state: boolean) => void,
|};

type StorageAccessUnavailableProps = {|
  redirectUrl: string,
|};

type EmbeddedLoginProps = {|
  redirectUrl: string,
|};

type PromptForAccessProps = {|
  redirectUrl: string,
|};

type AccessGrantedProps = {|
  accessRequested?: boolean,
  redirectUrl: string,
|};

const log = new Logger('domains/Login/EmbeddedLogin');

function Loader() {
  const centeredLayout = {
    position: 'absolute',
    inset: '0',
    alignItems: 'center',
    justifyContent: 'center',
  };

  return (
    <Flex {...centeredLayout}>
      <Box marginBottom="spacingLarge7X">
        <Spinner color="colorIconInverted" size="sizeIconLarge1X" />
      </Box>
    </Flex>
  );
}

function LoginViaPopup(props: LoginViaPopupProps) {
  const { redirect } = props;

  const popupWindowRef = useRef<{ closed: boolean, focus: () => void } | null>(null);

  const showPopup = () => {
    const popupWindow = popupWindowRef.current;

    if (popupWindow === null || popupWindow.closed) {
      popupWindowRef.current = window.open('/login?redirect=closeWindow', 'AtomiLogin', 'popup');
    } else {
      popupWindow.focus();
    }
  };

  const handlePopupMessage = useCallback(
    (event) => {
      if (event.source !== popupWindowRef.current) return;

      if (event.data?.loginSuccess) {
        redirect();
      }
    },
    [redirect]
  );

  useEffect(() => {
    window.addEventListener('message', handlePopupMessage);
    return () => {
      window.removeEventListener('message', handlePopupMessage);
    };
  });

  return (
    <Container maxWidth="sizeContainerSmall" marginTop="spacingLarge3X" textAlign="center">
      <Stack>
        <Heading as="h1" variant="large" color="colorTextInverted">
          Please log in to Atomi to view this content
        </Heading>
        <Text as="p" color="colorTextInverted">
          Click the button below to log in to Atomi in a new window. You’ll be returned to this page once you’re done.
        </Text>
        <Button variant="ghost" onClick={showPopup}>
          Log in to Atomi
        </Button>
        <Text as="p" color="colorTextInverted" fontSize="fontSizeSmall1X">
          Still seeing this message after you’ve logged in? Refresh the page to try again.
        </Text>
      </Stack>
    </Container>
  );
}

function RequestAccess(props: RequestAccessProps) {
  const { grantAccess, requestAccess } = props;

  const requestStorageAccess = () => {
    // $FlowIgnoreLibDef
    document
      .requestStorageAccess()
      .then((hasAccess) => grantAccess(hasAccess))
      .catch(() => grantAccess(false))
      .finally(() => requestAccess(true));
  };

  return (
    <Container maxWidth="sizeContainerSmall" textAlign="center">
      <Box marginTop="spacingLarge3X">
        <Stack>
          <Heading as="h1" variant="large">
            View embedded content
          </Heading>
          <Text as="p" color="colorTextInverted">
            Please allow Atomi to display embedded content on your browser or app.
          </Text>
          <Button variant="ghost" onClick={requestStorageAccess}>
            Allow
          </Button>
        </Stack>
      </Box>
    </Container>
  );
}

function StorageAccessUnavailable(props: StorageAccessUnavailableProps) {
  const { redirectUrl } = props;

  const trackingParams = new URLSearchParams({
    utm_source: 'atomi',
    utm_campaign: 'embed',
  });
  const fallbackUrl = redirectUrl.replace('isEmbed=true', trackingParams.toString());

  return (
    <Container maxWidth="sizeContainerSmall2X" textAlign="center">
      <Box marginTop="spacingLarge3X">
        <Stack>
          <Heading as="h1" variant="large">
            Can’t load embedded content
          </Heading>
          <Text as="p" color="inherit">
            Sorry, the browser or app you’re using prevents us from loading this embedded content.
          </Text>
          <Text as="p" color="inherit">
            Please click the button below to log in to Atomi and view this content in a new window.
          </Text>
          <Button as="a" variant="ghost" href={fallbackUrl} target="_blank">
            Open in Atomi
          </Button>
        </Stack>
      </Box>
    </Container>
  );
}

function PromptForAccess(props: PromptForAccessProps) {
  const { redirectUrl } = props;

  const [accessRequested, requestAccess] = useState<boolean>(false);
  const [accessGranted, grantAccess] = useState<boolean>(false);

  if (!accessRequested) {
    return <RequestAccess grantAccess={grantAccess} requestAccess={requestAccess} />;
  }

  if (accessGranted) {
    return <AccessGranted accessRequested={accessRequested} redirectUrl={redirectUrl} />;
  }
  return <StorageAccessUnavailable redirectUrl={redirectUrl} />;
}

function AccessGranted(props: AccessGrantedProps) {
  const { accessRequested, redirectUrl } = props;

  const [isRedirecting, setIsRedirecting] = useState<boolean>(false);

  const onRedirect = useCallback(() => {
    setIsRedirecting(true);
    window.location.replace(redirectUrl);
  }, [redirectUrl, setIsRedirecting]);

  useEffect(() => {
    if (accessRequested && !isRedirecting) {
      onRedirect();
    }
  }, [accessRequested, isRedirecting, onRedirect]);

  if (isRedirecting) {
    return <Loader />;
  }

  // If they log in via the pop-up, they'll automatically be
  // redirected to the target URL. So no need to handle that here.
  return <LoginViaPopup redirect={onRedirect} />;
}

function EmbeddedLogin(props: EmbeddedLoginProps) {
  /**
   * We use the Storage Access API to access cookies in order to
   * authenticate embedded iframes.
   *
   * Some browsers require users to opt into this API, so we need
   * a way for them to grant access to Atomi.
   *
   * Reference: https://webkit.org/blog/8124/introducing-storage-access-api/
   *
   * Original ticket: https://hschub.atlassian.net/browse/PROD-6662
   */
  const { redirectUrl } = props;

  const [canAccessCookiesByDefault, setCanAccessCookiesByDefault] = useState<boolean>(false);
  const [isLoadingInitialAccess, setIsLoadingInitialAccess] = useState<boolean>(true);

  // $FlowIgnoreLibDef
  const canUseStorageAccessApi = typeof document !== 'undefined' && typeof document.hasStorageAccess === 'function';

  useEffect(() => {
    if (!canUseStorageAccessApi) {
      setIsLoadingInitialAccess(false);
      return;
    }

    // $FlowIgnoreLibDef
    document
      .hasStorageAccess()
      .then(setCanAccessCookiesByDefault)
      .catch(() => setCanAccessCookiesByDefault(false))
      .finally(() => setIsLoadingInitialAccess(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoadingInitialAccess) {
    return <Loader />;
  }

  if (!canUseStorageAccessApi) {
    // Generally, when `document.hasStorageAccess` is undefined the
    // client should still have access to cookies. This screen is
    // here to handle any edge cases.
    log.warn('hasStorageAccess is undefined and cookies aren’t available');
    return <StorageAccessUnavailable redirectUrl={redirectUrl} />;
  }

  if (canAccessCookiesByDefault) {
    return <AccessGranted redirectUrl={redirectUrl} />;
  }

  // Due to the user's browser storage access policy, we can't access
  // cookies by default. So we need to ask permission from the user.
  return <PromptForAccess redirectUrl={redirectUrl} />;
}

export default EmbeddedLogin;
