// @flow
import { useEffect } from 'react';
import Helmet from 'react-helmet';
import { Box, Card, Container, Heading, Stack } from '@getatomi/neon';

import getSubjectColors from 'src/utils/getSubjectColors';
import useAlertDialog from 'src/hooks/components/useAlertDialog';
import { useNavigationContext } from 'src/hooks/useNavigationContext';
import BackLink from 'src/components/BackLink/BackLink';
import StudentTaskCardHeader from 'src/domains/Tasks/components/StudentTaskCardHeader/StudentTaskCardHeader';
import { getTasksUrl } from 'src/utils/routes';

import type { CurrentClass, Task } from './useGetStudentTask';
import TaskMarkdown from '../components/TaskMarkdown';
import useTaskSubscriptions from '../useTaskSubscriptions';
import StudentTaskCompletion from './StudentTaskCompletion';

export type StudentTaskProps = {|
  currentClass: CurrentClass,
  subscriptionId: string,
  taskData: Task,
  userId: string,
|};

function StudentTask(props: StudentTaskProps) {
  const { currentClass, subscriptionId, taskData, userId } = props;
  const { id: classId, subject } = currentClass;

  const [, setNavigationContext] = useNavigationContext();
  useEffect(() => {
    setNavigationContext({
      location: 'task',
      metadata: {
        accountId: subscriptionId,
        classId,
        taskId: taskData.taskId,
        title: taskData.title ?? 'Task',
      },
    });
  }, [classId, subscriptionId, setNavigationContext, taskData.taskId, taskData.title]);

  useTaskSubscriptions({
    audience: 'student',
    classId,
    userId,
  });

  const [infectedPrompt, showInfectedPrompt] = useAlertDialog({
    closeOnConfirm: true,
    heading: 'This file did not pass the virus security scan and can’t be downloaded.',
    variant: 'danger',
    onConfirmLabel: 'Ok, thanks',
    isDismissable: false,
  });

  const subjectColorRange = getSubjectColors(subject.groupCode, subject.color);
  const backLinkUrl = getTasksUrl(subscriptionId, taskData.isComplete ? 'done' : undefined);

  return (
    <>
      <Helmet title={taskData.title} />
      <Box display={{ base: 'none', tablet: 'block' }} paddingBottom={{ base: 'none', tablet: 'spacingLarge5X' }}>
        <Container>
          <BackLink to={backLinkUrl}>Back to Tasks</BackLink>
        </Container>
      </Box>
      <Container maxWidth="sizeContainerRoot">
        <Stack spacing="spacingLarge7X">
          <Card as="article" padding="spacingRoot" variant="elevated" withHover={false}>
            <StudentTaskCardHeader
              author={taskData.author}
              dueDate={taskData.dueAt}
              subjectColor={subjectColorRange.background}
              subjectName={subject.name}
            />
          </Card>

          <Stack spacing="spacingLarge3X">
            <Heading as="h1">{taskData.title}</Heading>
            <TaskMarkdown
              attachments={taskData.attachments}
              body={taskData.body}
              classId={classId}
              contents={taskData.contents}
              showInfectedPrompt={showInfectedPrompt}
              subjectColor={subject.color}
              subscriptionId={subscriptionId}
              updatedAt={taskData.updatedAt}
            />
            <Box
              borderColor="colorBorder"
              borderRadius="borderRadiusRoot"
              borderStyle="solid"
              borderWidth="borderWidthRoot"
              marginTop="spacingLarge"
              padding="spacingSmall"
            >
              <StudentTaskCompletion subjectColors={subjectColorRange} taskData={taskData} />
            </Box>
          </Stack>
          {infectedPrompt}
        </Stack>
      </Container>
    </>
  );
}

export default StudentTask;
