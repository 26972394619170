// @flow
import { forwardRef } from 'react';
import { DatePicker as NeonDatePicker } from '@getatomi/neon';

import useIntercomWidget from 'src/hooks/useIntercomWidget';

export type DatePickerProps = {|
  ariaDescribedBy?: string,
  ariaLabel?: string,
  ariaLabelledBy?: string,
  autoFocus?: boolean,
  defaultValue?: Date,
  errorVariant?: 'error' | 'warning',
  helpText?: React.Node,
  id?: string,
  isDisabled?: boolean,
  label?: React.Node,
  maxValue?: Date,
  minValue?: Date,
  onChange?: (date: Date) => mixed,
  onOpenChange?: (isOpen: boolean, isTray: boolean) => mixed,
  secondaryLabel?: React.Node,
  validationText?: string,
  value?: Date,
|};

const DatePicker = forwardRef<DatePickerProps, HTMLButtonElement>(function DatePicker(props, ref: any) {
  const { minValue = new Date(), onOpenChange, ...otherProps } = props;
  const [showIntercom, hideIntercom] = useIntercomWidget({ showOnUnmount: true });

  return (
    <NeonDatePicker
      ref={ref}
      minValue={minValue}
      onOpenChange={(isOpen, isTray) => {
        if (isTray) {
          if (isOpen) {
            hideIntercom();
          } else {
            showIntercom();
          }
        }
        if (onOpenChange) {
          onOpenChange(isOpen, isTray);
        }
      }}
      {...otherProps}
    />
  );
});

export default DatePicker;
