// @flow
import Pusher from 'pusher-js';

import type { Progress } from 'src/types';
import Auth from 'src/utils/Auth';
import Logger from 'src/utils/Logger';
import broadcasting from 'src/constants/broadcasting';

const log = new Logger('utils/pusher');
const auth = new Auth();
const endpoint = `${process.env.NEXT_PUBLIC_ANALYTICS_BASE_URL}/broadcasting/auth`;

// We lazily initialise Pusher to make sure the user is logged in, as well as to avoid unnecessary
// ping-pongs with Pusher's servers
// https://pusher.com/docs/channels/library_auth_reference/pusher-websockets-protocol#ping-and-pong-messages
let pusher;

// Pusher doesn't keep a publically accessible list of subscriptions, so we need to keep track of it
// ourselves. This is purely to issue a warning during development as a reminder to clean up the
// subscriptions
const channelEventSet = new Set();

type PusherAuthData = {
  auth: string,
};
type PusherAuthParams = {
  channelName: string,
  socketId: string,
};
type PusherAuthCallback = (error: ?Error, data: ?PusherAuthData) => void;

export const authoriseChannelAccess = async (
  { channelName, socketId }: PusherAuthParams,
  callback: PusherAuthCallback
) => {
  try {
    const body = new FormData();
    body.append('channel_name', channelName);
    body.append('socket_id', socketId);

    // We don't want to refresh the token because it could trigger 401 errors when there are other
    // refresh attempts in progress, hence why we are using this deprecated auth method
    const token = await auth.getAccessToken();
    if (!token || auth.isTokenExpired(token)) {
      return callback(new Error('Pusher Channel Authorisation could not refresh your access token'), null);
    }

    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body,
    });

    const json = await response.json();

    callback(null, { auth: json.auth });
  } catch (error) {
    log.warn(error);
    callback(error, null);
  }
};

export function init() {
  Pusher.logToConsole = __DEVELOPMENT__;

  pusher = new Pusher(process.env.NEXT_PUBLIC_PUSHER_APP_KEY, {
    channelAuthorization: {
      customHandler: authoriseChannelAccess,
    },
    cluster: 'ap1',
    forceTLS: true,
  });
}

export function subscribe(channelName: string, eventName: string, handler: (data: Progress) => mixed) {
  if (!pusher) {
    init();
  }

  // The `private-` prefix is a convention used by Pusher to denote private channels
  const privateChannelName = `private-${channelName}`;
  const internalKey = `${privateChannelName}--${eventName}`;

  // If a consumer has not unsubscribed we will issue a warning
  if (channelEventSet.has(internalKey)) {
    log.warn(`${eventName} event already bound to ${privateChannelName} channel - ensure you are unsubscribing`);
  }

  // We're going to be overly defensive here and unbind any events that might have been bound.
  // Unbinding is a safe operation, and we only ever intend to have a single hanlder per event per
  // channel.
  const channel = pusher.subscribe(privateChannelName);
  channel.unbind(eventName);
  channel.bind(eventName, handler);
  channelEventSet.add(internalKey);

  // Important: we must unsubscribe from the channel via the pusher instance, not the *channel* instance
  // https://pusher.com/docs/channels/using_channels/public-channels/#unsubscribe
  return () => {
    channelEventSet.delete(internalKey);
    channel.unbind(eventName);
    pusher.unsubscribe(privateChannelName);
  };
}

export function subscribeToOwnProgress(userId: number, handler: (data: Progress) => mixed) {
  return subscribe(`user.${userId}`, broadcasting.events.USER_PROGRESS.UPDATED, handler);
}

export function subscribeToClassProgress(classId: number, handler: (data: Progress) => mixed) {
  return subscribe(`class.${classId}`, broadcasting.events.CLASS_USER_PROGRESS.UPDATED, handler);
}
