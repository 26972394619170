// @flow
import { useToast } from '@getatomi/neon';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import pluralize from 'pluralize';

// The API only allows up to 30 students at a time.
const maxStudents = 30;

/**
 * Processes the emails input by removing any trailing commas, new lines, and empty strings.
 */
function processEmailsInput(emails: string) {
  return emails
    .replace(/,\s*$/, '')
    .replace(/\n/g, ',')
    .split(',')
    .map<string>((email) => email.trim())
    .filter(Boolean);
}

export type Fields = {|
  emails: string,
|};

export type OutboundFields = {|
  countNewUsers: number,
  countUsers: number,
  emails: Array<string>,
|};

const labels = {
  emails: 'Emails',
};

const isEmailSchema = yup.string().email().required();
const resolver = yupResolver(
  yup.object().shape({
    emails: yup
      .string()
      .trim()
      .required('Please enter at least one student email address.')
      .label(labels.emails)
      .test({
        name: 'emails',
        test(value) {
          const formattedEmails = processEmailsInput(value);

          // the user entered only commas and empty spaces
          if (formattedEmails.length === 0) {
            return this.createError({
              message: 'Please enter at least one student email address.',
            });
          }

          // check for max allowed invites
          if (formattedEmails.length > maxStudents) {
            const countEmailsToRemove = formattedEmails.length - maxStudents;
            return this.createError({
              message: `Sorry, we can only add up to 30 students at a time. Please remove ${countEmailsToRemove} and try again.`,
            });
          }

          // Check for duplicates
          const duplicatedEmails = new Set(formattedEmails.filter((email, i, array) => array.indexOf(email) !== i));
          const countDuplicates = duplicatedEmails.size;

          if (countDuplicates > 0) {
            const emails = Array.from(duplicatedEmails);
            const message =
              countDuplicates === 1
                ? `User '${emails[0]}' appears more than once in the list. Please remove the duplicate and try again.`
                : `${pluralize('User', countDuplicates)} '${emails.join(
                    "' and '"
                  )}' appear more than once in the list. Please remove the duplicates and try again.`;
            return this.createError({
              message,
            });
          }

          // Check for invalid emails
          const firstInvalidEmail = formattedEmails.find((email) => !isEmailSchema.isValidSync(email));

          return !firstInvalidEmail
            ? true
            : this.createError({
                message: `The email '${firstInvalidEmail}' is invalid`,
              });
        },
      }),
  })
);

export default function useBulkAddStudentsToClassForm({
  context,
  defaultValues,
  onNewUsersDetected,
  onSubmitFail,
  onSubmitSuccess,
}: {
  context: { existingUsersEmails: $ReadOnlyArray<string> },
  defaultValues: Fields,
  onNewUsersDetected: (emails: Array<string>) => void,
  onSubmitFail: () => void,
  onSubmitSuccess: (data: OutboundFields) => Promise<void>,
}) {
  const toast = useToast();
  const { clearErrors, control, formState, handleSubmit, watch } = useForm({
    resolver,
    defaultValues,
  });

  const onSubmit = async ({ isNewUsersConfirmed }: { isNewUsersConfirmed?: boolean } = {}) => {
    clearErrors('serverError');

    handleSubmit(async (data) => {
      const emails = processEmailsInput(data.emails);
      const newEmails = emails.filter((email) => !context.existingUsersEmails.includes(email));
      const countNewUsers = newEmails.length;

      // the user has not confirmed the new users via the alert dialog
      if (!isNewUsersConfirmed && countNewUsers > 0) {
        return onNewUsersDetected(newEmails);
      }

      try {
        await onSubmitSuccess({
          countNewUsers,
          countUsers: emails.length,
          emails,
        });
      } catch (error) {
        toast.error('There was an error adding students to your class. Please try again.');
        onSubmitFail();
      }
    })();
  };

  const onConfirm = () => {
    onSubmit({ isNewUsersConfirmed: true });
  };

  const emailsMessage = formState.errors.emails?.message ?? null;
  const emails = processEmailsInput(watch('emails'));

  return {
    control,
    emails,
    form: {
      onSubmit: (event: Event) => {
        event.preventDefault();
        onSubmit();
      },
    },
    fields: {
      emails: {
        label: labels.emails,
        helpText: (
          <>
            Copy and paste the email addresses of <b>up to {maxStudents} students</b> at a time.
          </>
        ),
        validationText: emailsMessage,
        errorVariant: emailsMessage && 'error',
        type: 'email',
        isRequired: true,
      },
    },
    onConfirm,
    watch,
  };
}
