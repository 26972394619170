// @flow
import invariant from 'invariant';
import { useCallback, useEffect, useState } from 'react';
import { type LocationShape, type ContextRouter } from 'react-router';
import Helmet from 'react-helmet';
import { useMutation } from '@apollo/client';
import { Container, CompactPagination, DropdownLoader, Flex, HideVisually, useToast } from '@getatomi/neon';

import type { SetTaskCompletion, SetTaskCompletionVariables } from 'src/graphql/types/generated/SetTaskCompletion';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import { useNavigationContext } from 'src/hooks/useNavigationContext';
import useSubscriptions from 'src/hooks/useSubscriptions';
import Logger from 'src/utils/Logger';
import { trackEvent, type TrackEventFn } from 'src/utils/tracking';
import { trackingEvents } from 'src/constants/tracking';
import broadcasting from 'src/constants/broadcasting';
import taskStatuses from 'src/constants/taskStatuses';
import { getTasksUrl } from 'src/utils/routes';

import SET_TASK_COMPLETION from './SetTaskCompletion.graphql';
import StudentTasksNav from './StudentTasksNav';
import StudentTaskList from './StudentTaskList';
import useGetStudentTasks from './useGetStudentTasks';
import TaskList from '../components/TaskList';
import useTaskListFilters from '../hooks/useStudentTaskListFilters';

type Props = {
  location: LocationShape,
  params: {
    subscriptionId: string,
  },
  route: {
    status: ?$Keys<typeof taskStatuses>,
  },
  router: ContextRouter,
};

const log = new Logger('src/domains/Tasks');

export function useTaskCompletion(trackEventFn: TrackEventFn) {
  const toast = useToast();
  const [setTaskCompletion] = useMutation<SetTaskCompletion, SetTaskCompletionVariables>(SET_TASK_COMPLETION, {
    refetchQueries: ['GetStudentTasks'],
  });

  const toggleTaskCompletion = useCallback(
    async (id: string, isCompleted: boolean) => {
      try {
        trackEventFn(trackingEvents.taskUpdateCompletionRequested, { id, isCompleted });
        await setTaskCompletion({
          variables: ({ input: { id, isCompleted } }: $Exact<SetTaskCompletionVariables>),
        });
      } catch (err) {
        log.error('Error setting the task completion status', err);
        toast.error('There was an error updating the task completion. Please try again.');
      }
    },
    [setTaskCompletion, trackEventFn, toast]
  );

  return toggleTaskCompletion;
}

export function StudentTasksQuery(props: Props) {
  const {
    location: {
      query: { after, before },
    },
    params: { subscriptionId },
    route: { status },
    router,
  } = props;
  const [classId, setClassId] = useState(null);

  function updatePageLocation(query) {
    router.push({
      pathname: getTasksUrl(subscriptionId, status ? 'done' : undefined),
      query: { ...query },
    });
  }

  const { data, error, goToNextPage, goToPreviousPage, loading, refetch } = useGetStudentTasks({
    classId,
    paginationCursor: { after, before },
    status,
    subscriptionId,
    updatePageLocation,
  });

  const [isFiltering, taskListFilters, clearFilters] = useTaskListFilters({
    classes: data?.classes || [],
    filters: { classId },
    onClassIdChange: setClassId,
  });

  useEffect(() => {
    refetch();
  }, [classId, refetch]);

  const toggleTaskCompletion = useTaskCompletion(trackEvent);

  useSubscriptions(
    data && data.userId
      ? [
          { channel: `user.${data.userId}`, event: broadcasting.events.USER_PROGRESS.UPDATED },
          { channel: `user.${data.userId}`, event: broadcasting.events.TASKS.CREATED },
          { channel: `user.${data.userId}`, event: broadcasting.events.TASKS.DELETED },
          { channel: `user.${data.userId}`, event: broadcasting.events.TASKS.UPDATED },
        ]
      : []
  );

  const navigation = (
    <Flex alignItems="center" gap="spacingSmall" justifyContent="space-between" marginBottom="spacingLarge3X">
      <StudentTasksNav subscriptionId={subscriptionId} />
      {loading ? <DropdownLoader variant="filter" /> : taskListFilters}
    </Flex>
  );

  if (loading) {
    return (
      <TaskList.Container>
        {navigation}
        <TaskList.Loader />
      </TaskList.Container>
    );
  }

  if (error) return <GraphQLError error={error} description="We couldn’t load your tasks." />;

  invariant(data, 'Student tasks data should not be null');

  return (
    <TaskList.Container>
      {navigation}
      <StudentTaskList
        clearFilters={clearFilters}
        data={data}
        isFiltering={isFiltering}
        onTaskCompletionChange={toggleTaskCompletion}
        status={status}
      />
      {(goToNextPage || goToPreviousPage) && (
        <Container marginTop="spacingLarge6X">
          <CompactPagination onPreviousClick={goToPreviousPage} onNextClick={goToNextPage} />
        </Container>
      )}
    </TaskList.Container>
  );
}

export default function StudentTasksContainer(props: Props) {
  const {
    params: { subscriptionId: accountId },
    route: { status },
  } = props;
  const [, setNavigationContext] = useNavigationContext();

  useEffect(() => {
    setNavigationContext({
      location: 'studentTasks',
      metadata: {
        accountId,
        isComplete: status === taskStatuses.completed,
        title: 'Tasks',
      },
    });
  }, [accountId, setNavigationContext, status]);

  return (
    <>
      <Helmet title="Tasks" />
      <HideVisually>
        <h1>Tasks</h1>
      </HideVisually>
      <StudentTasksQuery {...props} />
    </>
  );
}
