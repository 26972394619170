// @flow
import { type ContextRouter, withRouter } from 'react-router';
import { Box, TabNav as NeonTabNav } from '@getatomi/neon';

type TabNavProps = {|
  ariaLabel: string,
  children: React.Node | ((item: any) => React.Node),
  items?: Array<any>,
  router: ContextRouter,
  variant?: 'borderless' | 'centered' | 'secondary',
|};

export function TabNavBase(props: TabNavProps) {
  const { ariaLabel, children, items, router, variant } = props;
  const currentPathname = router.location.pathname;

  // extract each item from the children or items prop
  const allItemsPathnames = (items ?? React.Children.toArray(children)).filter(Boolean).map((args) => {
    // if the collection is provided as children, the args will be an object with props otherwise it
    // will be the item itself
    const { href, activeMatcher } = args.props ?? args;
    return {
      activeMatcher,
      href,
    };
  });

  const currentItem = allItemsPathnames.find(({ href, activeMatcher }) => {
    const exactMatch = href === currentPathname;
    const customMatch = activeMatcher && activeMatcher({ pathname: currentPathname, search: router.location.search });
    return customMatch ?? exactMatch;
  });

  return (
    <Box
      marginBottom={
        variant === 'secondary' || variant === 'borderless'
          ? undefined
          : { base: 'spacingLarge1X', tablet: 'spacingLarge3X' }
      }
      // the minWidth is required for the tabs horizontal scroll to work properly when used in a
      // flex context
      minWidth="sizeNone"
    >
      <NeonTabNav ariaLabel={ariaLabel} items={items} currentKey={currentItem?.href} variant={variant}>
        {children}
      </NeonTabNav>
    </Box>
  );
}

export const TabNav = withRouter(TabNavBase);
export { Item } from '@getatomi/neon';
