// @flow
import { useEffect, useMemo } from 'react';
import { browserHistory } from 'react-router';

import { TabNav, Item } from 'src/components/TabNav/TabNav';
import { getDashboardUrl, getLtiUrl } from 'src/utils/routes';
import classesSourceFilters from 'src/constants/classesSourceFilters';

import useClassesSource from '../useClassesSource';

/**
 * Set the classes source cookie when switching between "My classes" and "All classes".
 */
function useUpdateCookieOnRouteChange() {
  const { setClassesSource } = useClassesSource();

  useEffect(() => {
    const unlistenHistory = browserHistory.listen(({ query }) => {
      if (query?.source) {
        setClassesSource(query.source);
      }
    });
    return () => unlistenHistory();
  }, [setClassesSource]);
}

type DashboardTabNavProps = {|
  isLti?: boolean,
  subscriptionId: string,
|};

export default function DashboardTabNav(props: DashboardTabNavProps) {
  const { isLti, subscriptionId } = props;
  const getUrl = isLti ? getLtiUrl : getDashboardUrl;

  useUpdateCookieOnRouteChange();
  const { classesSource } = useClassesSource();

  const items = useMemo(
    () => [
      {
        href: getUrl(subscriptionId, { source: classesSourceFilters.user }),
        name: 'My classes',
        source: classesSourceFilters.user,
        activeMatcher: ({ search }) =>
          search.includes(classesSourceFilters.user) || classesSource === classesSourceFilters.user,
      },
      {
        href: getUrl(subscriptionId, { source: classesSourceFilters.all }),
        name: 'All classes',
        source: classesSourceFilters.all,
        activeMatcher: ({ search }) =>
          search.includes(classesSourceFilters.all) || classesSource === classesSourceFilters.all,
      },
    ],
    [classesSource, getUrl, subscriptionId]
  );

  return (
    <TabNav ariaLabel="Class list navigation" items={items} variant={isLti ? 'borderless' : undefined}>
      {items.map((item) => (
        <Item
          key={item.href}
          href={item.href}
          routerOptions={{
            // The href is used to set the proper HTML attribute, but the pathname + query are used
            // for client-side navigation.
            pathname: getUrl(subscriptionId),
            query: { source: item.source },
            state: { refocus: false, scrollTop: false },
          }}
        >
          {item.name}
        </Item>
      ))}
    </TabNav>
  );
}
