// @flow
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { useCallback } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToast } from '@getatomi/neon';

import { parseValidationErrors } from 'src/api/validation';
import { formValidation } from 'src/constants/formValidation';

export type Fields = {
  email?: string,
  firstName?: string,
  lastName?: string,
  levelId?: ?number | ?string,
  password?: string,
  position?: string,
  schoolId?: number | string,
};

type AllFields =
  | Fields
  | {
      strength?: number,
    };

// Data to be omitted when returning a successful submission
const BLOCK_LIST = ['strength'];
const omitBlocked = (data: AllFields): Fields => _.omit(data, BLOCK_LIST);

// Alternate labels are used when the label above the input differs from what appears in the
// validation message
const labels = {
  email: 'Email',
  firstName: 'First name',
  lastName: 'Last name',
  levelId: 'Year level',
  password: 'Set your password',
  passwordAlternate: 'Password',
  position: 'Role',
  schoolId: 'School',
};

const resolver = yupResolver(
  yup.object().shape({
    firstName: yup.string().trim().label(labels.firstName).required().max(formValidation.firstName.maxLength),
    lastName: yup.string().trim().label(labels.lastName).required().max(formValidation.lastName.maxLength),
    schoolId: yup.mixed().when('$isSchoolIdFieldPresent', {
      is: true,
      then: yup.lazy((value) => {
        if (typeof value === 'number') {
          return yup.number().required().label(labels.schoolId);
        }
        return yup.string().required().label(labels.schoolId);
      }),
    }),
    levelId: yup.mixed().when('$isLevelIdFieldPresent', {
      is: true,
      then: yup.number().nullable().required().label(labels.levelId),
    }),
    position: yup.mixed().when('$isPositionFieldPresent', {
      is: true,
      then: yup.string().trim().label(labels.position).required(),
    }),
    password: yup.mixed().when('$isPasswordFieldPresent', {
      is: true,
      then: yup
        .string()
        .label(labels.passwordAlternate)
        .required()
        .min(formValidation.password.minLength)
        .test('strength', '', function test() {
          return this.parent.strength >= formValidation.password.minStrength
            ? true
            : this.createError({
                message:
                  'The password you’ve submitted is too easy to guess and would risk the security of your account. Try creating a longer password and/or avoid using common words or sequences.',
              });
        }),
    }),
  })
);

export default function useInvitedUserRegistrationFormStep2({
  context,
  defaultValues,
  onSubmitFail,
  onSubmitSuccess,
}: {
  context: {
    isLevelIdFieldPresent?: boolean,
    isPasswordFieldPresent?: boolean,
    isPositionFieldPresent?: boolean,
    isSchoolIdFieldPresent?: boolean,
  },
  defaultValues: Fields,
  onSubmitFail: () => void,
  onSubmitSuccess: (data: Fields) => void | Promise<void>,
}) {
  const { clearErrors, control, formState, handleSubmit, trigger, setError, setValue } = useForm({
    resolver,
    defaultValues,
    context,
  });
  const toast = useToast();
  const { isSubmitted } = formState;

  const onSubmit = async (e: Event) => {
    e.preventDefault();
    clearErrors('serverError');
    handleSubmit(async (data) => {
      // See "Redux in form hooks" in Forms - The Basics (README.md#redux-and-createresolver)
      try {
        await onSubmitSuccess(omitBlocked(data));
      } catch (error) {
        const validationErrors = parseValidationErrors(error);

        if (validationErrors.length > 0) {
          return setError('serverError', { type: 'manual', message: validationErrors });
        }

        toast.error('There was an error submitting your registration. Please try again.');
      }
    }, onSubmitFail)(e);
  };

  const onPasswordStrengthChange = useCallback(
    (strength: number) => {
      setValue('strength', strength, { shouldValidate: isSubmitted });
      // retrigger password validation if the form has already been submitted
      if (isSubmitted && strength) trigger('password');
    },
    [isSubmitted, setValue, trigger]
  );

  const firstNameMessage = formState.errors.firstName?.message ?? null;
  const lastNameMessage = formState.errors.lastName?.message ?? null;
  const schoolIdMessage = formState.errors.schoolId?.message ?? null;
  const levelIdMessage = formState.errors.levelId?.message ?? null;
  const positionMessage = formState.errors.position?.message ?? null;
  const passwordMessage = formState.errors.password?.message ?? null;

  const { serverError } = formState.errors;

  return {
    control,
    setValue,
    serverError,
    form: {
      onSubmit,
    },
    fields: {
      firstName: {
        label: labels.firstName,
        validationText: firstNameMessage,
        errorVariant: firstNameMessage && 'error',
        isRequired: true,
      },
      lastName: {
        label: labels.lastName,
        validationText: lastNameMessage,
        errorVariant: lastNameMessage && 'error',
        isRequired: true,
      },
      email: {
        label: labels.email,
        isReadOnly: true,
      },
      schoolId: {
        label: labels.schoolId,
        validationText: schoolIdMessage,
        errorVariant: schoolIdMessage && 'error',
      },
      levelId: {
        label: labels.levelId,
        validationText: levelIdMessage,
        errorVariant: levelIdMessage && 'error',
      },
      position: {
        label: labels.position,
        validationText: positionMessage,
        errorVariant: positionMessage && 'error',
      },
      password: {
        label: labels.password,
        validationText: passwordMessage,
        errorVariant: passwordMessage && 'error',
        onStrengthChange: onPasswordStrengthChange,
        isRequired: true,
      },
    },
  };
}
