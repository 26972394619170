// @flow
import { Container } from '@getatomi/neon';
import { useQuery } from '@apollo/client';
import Helmet from 'react-helmet';

import type { GetRevisionMarks, GetRevisionMarksVariables } from 'src/graphql/types/generated/GetRevisionMarks';
import { type NavigationContext } from 'src/utils/navigationContext';
import { useNavigationContext } from 'src/hooks/useNavigationContext';
import BackLink from 'src/components/BackLink/BackLink';
import { getRevisionMarksUrl, getRevisionProgressReportUrl } from 'src/utils/routes';
import { prepareNavigation } from 'src/utils/prepareNavigation';

import GET_REVISION_MARKS from './GetRevisionMarks.graphql';
import { getRevisionQuestionUrlFactory } from './utilities/getQuestionUrlFactory';
import { prepareRevisionMarksData } from './utilities/prepareMarksData/prepareMarksData';
import Marks from './Marks/Marks';

export type RevisionMarksContainerProps = {|
  params: {|
    classId: string,
    revisionId: string,
    subscriptionId: string,
  |},
|};

export function RevisionMarksContainer(props: RevisionMarksContainerProps) {
  const { params } = props;
  const { classId, revisionId, subscriptionId: accountId } = params;

  const [navigationContext] = useNavigationContext();

  const classTasksPageFallback: NavigationContext = {
    location: 'classTasks',
    metadata: {
      accountId,
      classId,
      title: 'Tasks',
    },
  };

  const { backUrl, backLinkUrl, backLinkLabel } = prepareNavigation(navigationContext ?? classTasksPageFallback);
  const marksUrl = getRevisionMarksUrl(accountId, classId, revisionId);
  const overviewUrl = getRevisionProgressReportUrl(accountId, classId, revisionId);
  const variables: GetRevisionMarksVariables = {
    accountId,
    classId,
    revisionId,
    filters: {
      includeInvited: false,
    },
  };

  const { data: marksData }: { data: ?GetRevisionMarks } = useQuery<GetRevisionMarks, GetRevisionMarksVariables>(
    GET_REVISION_MARKS,
    {
      variables,
    }
  );

  const getRevisionQuestionUrl = getRevisionQuestionUrlFactory({
    accountId,
    classId,
    challengeId: revisionId,
    returnTo: backUrl,
  });

  const preparedData = marksData ? prepareRevisionMarksData(marksData, getRevisionQuestionUrl) : null;

  return (
    <>
      <Helmet>
        <title>{preparedData?.title ?? ''}</title>
      </Helmet>
      <Container>
        <BackLink to={backLinkUrl}>{`Back to ${backLinkLabel}`}</BackLink>
      </Container>
      <Marks
        attachedContent={preparedData?.attachedContent}
        averageMarkMetrics={preparedData?.averageMarkMetrics}
        challengeTier={preparedData?.challengeTier}
        classId={classId}
        isLoading={!preparedData}
        isRevision
        marksUrl={marksUrl}
        overviewUrl={overviewUrl}
        sequence={preparedData?.sequence}
        students={preparedData?.students}
        subheading={preparedData?.subheading}
        subscriptionId={accountId}
      />
    </>
  );
}

export default RevisionMarksContainer;
