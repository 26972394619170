// @flow
import React, { useRef, useState } from 'react';
import { Box, Button, Container, Divider, Modal, Stack, StatusLight, useId } from '@getatomi/neon';
import { ApolloError } from '@apollo/client';
import invariant from 'invariant';
import { flow, map, sortBy, uniqBy } from 'lodash/fp';

import { Dropdown, Item, ItemLabel } from 'src/components/Dropdown/Dropdown';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import { trackingEvents } from 'src/constants/tracking';
import { trackEvent } from 'src/utils/tracking';
import useRevisionDialogState, {
  type Module,
  contentTypes,
} from 'src/components/RevisionDialog/useRevisionDialogState';
import useRevisionDialogValidation from 'src/components/RevisionDialog/useRevisionDialogValidation';
import RevisionSelectors from 'src/components/RevisionDialog/RevisionSelectors';
import RevisionSelectorsLoader from 'src/components/RevisionDialog/RevisionSelectorsLoader';
import { generateSession } from 'src/components/RevisionDialog/generateSession';
import { convertTimeLimitToSeconds } from 'src/components/RevisionDialog/utils';
import useScrollAndFocus from 'src/hooks/useScrollAndFocus';

import { type UserClass } from '../transformUserClasses';
import useCreateStudentRevision from './useCreateStudentRevision/useCreateStudentRevision';
import useGetStudentRevisionDialog from './useGetStudentRevisionDialog/useGetStudentRevisionDialog';

type StudentRevisionDialogProps = {|
  classes: $ReadOnlyArray<UserClass>,
  isOpen: boolean,
  onClose: () => mixed,
  redirectToNewRevision: (params: { classId: string, revisionId: string }) => void,
  subscriptionId: string,
|};

type StudentRevisionDialogBodyProps = {|
  classId: string,
  createStudentRevision: $PropertyType<$Call<typeof useCreateStudentRevision>, 'createStudentRevision'>,
  formId: string,
  redirectToNewRevision: (params: { classId: string, revisionId: string }) => void,
  setSubmitApiError: (error: ApolloError | null) => void,
  subjectCode: string,
  subjectName: string,
  subjectTree: Array<Module>,
|};

type StudentRevisionDialogQueryProps = {|
  classId: string,
  createStudentRevision: $PropertyType<$Call<typeof useCreateStudentRevision>, 'createStudentRevision'>,
  formId: string,
  redirectToNewRevision: (params: { classId: string, revisionId: string }) => void,
  setSubmitApiError: (error: ApolloError | null) => void,
  subjectCode: string,
  subjectName: string,
  subscriptionId: string,
|};

function StudentRevisionDialogBody(props: StudentRevisionDialogBodyProps) {
  const {
    classId,
    createStudentRevision,
    formId,
    subjectCode,
    subjectName,
    redirectToNewRevision,
    subjectTree,
    setSubmitApiError,
  } = props;

  const {
    contentType,
    questionType,
    selectAllTopics,
    selectCoveredTopics,
    selectedTopics,
    sessionLength,
    setContentType,
    setQuestionType,
    setSelectedTopics,
    setSessionLength,
    coveredTopics,
    questions,
    availableDurations,
    availableQuestionTypes,
    isTimeLimitEnabled,
    toggleIsTimeLimitEnabled,
    setTimeLimitMinutes,
    timeLimitMinutes,
  } = useRevisionDialogState({
    isTeacher: false,
    subjectTree,
    defaultContentType: contentTypes.all,
  });

  const {
    clearValidationErrors,
    contentError,
    contentFieldRef,
    questionTypeError,
    questionTypesFieldRef,
    timeLimitError,
    timeLimitFieldRef,
    validateOnSubmit,
  } = useRevisionDialogValidation();

  const onSubmit = async (event) => {
    event.preventDefault();

    const hasValidationError = validateOnSubmit({
      hasQuestionTypes: questionType.length > 0,
      hasTopics: questions.length > 0,
      isTimeLimitEnabled,
      timeLimitMinutes,
    });
    if (hasValidationError) {
      return;
    }

    const timeLimitInSeconds = isTimeLimitEnabled ? convertTimeLimitToSeconds(timeLimitMinutes) : null;

    trackEvent(trackingEvents.studentRevisionCreationRequested, {
      subject: subjectCode,
      content: contentType,
      duration: sessionLength,
      questionTypes: questionType,
      timeLimitInSeconds,
    });

    const { questions: sessionQuestions } = generateSession({
      isTeacher: false,
      questions,
      questionTypeFilters: questionType,
      sessionLengthFilter: sessionLength,
    });

    setSubmitApiError(null);
    try {
      const data = await createStudentRevision({
        classId,
        questionIds: sessionQuestions.map(({ id }) => id),
        title: `${subjectName} Revision`,
        topicIds: selectedTopics,
        timeLimitInSeconds,
      });
      if (data) {
        redirectToNewRevision({ classId, revisionId: data.id });
      }
    } catch (submitApiError) {
      setSubmitApiError(submitApiError);
    }
  };

  return (
    <form id={formId} onSubmit={onSubmit}>
      <RevisionSelectors
        clearValidationErrors={clearValidationErrors}
        isTeacher={false}
        selectContentProps={{
          contentType,
          contentError,
          setContentType,
          contentFieldRef,
        }}
        selectTopicsProps={{
          subjectName,
          subjectTree,
          selectAllTopics,
          selectCoveredTopics,
          coveredTopics,
          selectedTopics,
          setSelectedTopics,
        }}
        selectQuestionTypesProps={{
          availableQuestionTypes,
          questionType,
          setQuestionType,
          questionTypesFieldRef,
          questionTypeError,
        }}
        selectSessionLengthProps={{
          availableDurations,
          sessionLength,
          setSessionLength,
        }}
        timeLimitProps={{
          isTimeLimitEnabled,
          toggleIsTimeLimitEnabled,
          setTimeLimitMinutes,
          timeLimitError,
          timeLimitFieldRef,
          timeLimitMinutes,
        }}
      />
    </form>
  );
}

function StudentRevisionDialogQuery(props: StudentRevisionDialogQueryProps) {
  const {
    classId,
    createStudentRevision,
    formId,
    redirectToNewRevision,
    subscriptionId,
    subjectCode,
    subjectName,
    setSubmitApiError,
  } = props;

  const { data, loading, error } = useGetStudentRevisionDialog({ accountId: subscriptionId, classId });

  if (loading) {
    return <RevisionSelectorsLoader />;
  }

  if (error) {
    return <GraphQLError error={error} description="We couldn’t load your subject." />;
  }

  invariant(data, 'Subject data should be defined');

  return (
    <StudentRevisionDialogBody
      classId={classId}
      createStudentRevision={createStudentRevision}
      formId={formId}
      subjectCode={subjectCode}
      subjectName={subjectName}
      subjectTree={data.subjectTree}
      redirectToNewRevision={redirectToNewRevision}
      setSubmitApiError={setSubmitApiError}
    />
  );
}

export default function StudentRevisionDialog(props: StudentRevisionDialogProps) {
  const { classes, isOpen, onClose, redirectToNewRevision, subscriptionId } = props;

  const { createStudentRevision, isCreatingStudentRevision } = useCreateStudentRevision();

  const subjectOptions = flow(
    map(({ levels, subject }) => {
      const levelNames = levels ? levels.map((level) => level.name).join(', ') : '';
      return {
        key: subject.code,
        label: `${subject.shortName} ${levelNames}`.trim(),
        color: subject.color,
      };
    }),
    uniqBy('key'),
    sortBy('label')
  )(classes);

  const [selectedClass, setSelectedClass] = useState<UserClass | null>(null);
  const [subjectError, setSubjectError] = useState<?string>(null);
  const [submitApiError, setSubmitApiError] = useState<ApolloError | null>(null);

  const formId = useId();

  const dropdownRef = useRef();
  useScrollAndFocus({
    shouldTrigger: subjectError != null,
    ref: dropdownRef,
  });

  const onSubjectChange = (newSubjectCode) => {
    const newSelectedClass = classes.find((thisClass) => thisClass.subject.code === newSubjectCode);

    if (newSelectedClass && newSelectedClass.assessmentCount > 0) {
      setSelectedClass(newSelectedClass);
      setSubjectError(null);
    } else {
      setSelectedClass(null);
      setSubjectError(
        'Unfortunately, the subject you’ve selected does not have any questions for revision yet. Please select another subject.'
      );
    }
  };

  const dropdownErrorProps = subjectError
    ? {
        errorVariant: 'error',
        validationText: subjectError,
      }
    : undefined;

  return (
    <Modal
      actions={
        <Button
          form={formId}
          isLoading={isCreatingStudentRevision}
          type="submit"
          size="small"
          onClick={
            selectedClass == null
              ? () => {
                  setSubjectError('Please select a subject.');
                }
              : undefined
          }
        >
          Create revision
        </Button>
      }
      heading="Create a revision"
      isOpen={isOpen}
      onClose={onClose}
      size="fullscreen"
    >
      <Container maxWidth="sizeContainerRoot">
        <Box paddingBlock="spacingLarge7X">
          {submitApiError && <GraphQLError error={submitApiError} isValidation />}

          <Stack spacing="spacingLarge5X">
            <Box as="fieldset" form={formId} maxWidth="350px">
              <Dropdown
                label="Subject"
                placeholder="Select a subject"
                items={subjectOptions}
                selectedKey={selectedClass?.subject.code ?? null}
                onSelectionChange={(newSubjectCode) => {
                  setSubjectError(null);
                  onSubjectChange(newSubjectCode);
                }}
                ref={dropdownRef}
                {...dropdownErrorProps}
              >
                {(item) => (
                  <Item textValue={item.label}>
                    <StatusLight color={item.color} />
                    <ItemLabel>{item.label}</ItemLabel>
                  </Item>
                )}
              </Dropdown>
            </Box>

            {selectedClass && (
              <>
                <Divider />

                <StudentRevisionDialogQuery
                  key={selectedClass.id}
                  formId={formId}
                  classId={selectedClass.id}
                  createStudentRevision={createStudentRevision}
                  redirectToNewRevision={redirectToNewRevision}
                  subscriptionId={subscriptionId}
                  subjectCode={selectedClass.subject.code}
                  subjectName={selectedClass.subject.shortName}
                  setSubmitApiError={setSubmitApiError}
                />
              </>
            )}
          </Stack>
        </Box>
      </Container>
    </Modal>
  );
}
