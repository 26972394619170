// @flow
import { useQuery, type ApolloError } from '@apollo/client';
import invariant from 'invariant';

import type { GetClassTask, GetClassTaskVariables } from 'src/graphql/types/generated/GetClassTask';
import {
  attachmentTransformer,
  contentTransformer,
  type ClassTaskContent,
  type TaskStatus,
} from 'src/domains/Tasks/ClassTasks/classTasksTransformer';
import type { TaskAttachment } from 'src/domains/Tasks/types';
import userFullName from 'src/utils/userFullName';

import GET_CLASS_TASK from './GetClassTask.graphql';
import { type Author, prepareAuthorData } from '../taskTransformer';

export type ClassTask = {|
  attachments: $ReadOnlyArray<TaskAttachment>,
  author: Author,
  autoComplete: boolean,
  body: ?string,
  contents: $ReadOnlyArray<ClassTaskContent>,
  countCompletedStudents: number,
  countInProgressStudents: number,
  countStudents: number,
  createdAt: string,
  dueDate: string,
  id: string,
  scheduledFor: ?string,
  status: TaskStatus,
  studentIds: $ReadOnlyArray<string>,
  title: ?string,
  updatedAt: string,
  updatedByFullName: ?string,
|};

export type CurrentClass = {|
  id: string,
  name: string,
  studentCount: number,
  subject: {
    code: string,
    color: string,
    groupCode: string,
    levels: $ReadOnlyArray<string>,
  },
|};

type Output = {|
  data: ?{|
    currentClass: CurrentClass,
    isFreePlan: boolean,
    region: string,
    task: ?ClassTask,
    userId: string,
  |},
  error: ?ApolloError,
  loading: boolean,
|};

export default function useGetClassTask({
  classId,
  subscriptionId,
  taskId,
}: {
  classId: string,
  subscriptionId: string,
  taskId: string,
}): Output {
  const classTaskVariables = ({
    accountId: subscriptionId,
    classId,
    filters: {
      ids: [taskId],
    },
  }: $Exact<GetClassTaskVariables>);

  const { data, error, loading } = useQuery<GetClassTask, GetClassTaskVariables>(GET_CLASS_TASK, {
    variables: classTaskVariables,
  });

  if (error) {
    return {
      data: null,
      error,
      loading: false,
    };
  }

  if (loading && !data) {
    return {
      data: null,
      error: null,
      loading: true,
    };
  }

  const userData = data?.me;

  invariant(userData, 'User data should be defined');

  const accountData = userData.account;
  const currentClass = accountData.class;
  invariant(currentClass, 'Class data is missing');

  const taskData = currentClass.tasks.edges[0]?.node;
  const contents =
    taskData &&
    taskData.contents
      .filter(({ __typename }) => !__typename.includes('Locked'))
      .map((content) =>
        contentTransformer({
          content,
          countAssignedStudents: taskData?.students.length,
          subscriptionId,
        })
      );

  return {
    data: {
      currentClass: {
        id: currentClass.id,
        name: currentClass.name,
        studentCount: currentClass.students.length,
        subject: {
          code: currentClass.subject.code,
          color: currentClass.subject.color,
          groupCode: currentClass.subject.groupCode,
          levels: currentClass.subject.levels.map(({ id }) => id),
        },
      },
      isFreePlan: accountData.plan.isFree,
      region: accountData.region.code,
      task: taskData
        ? {
            attachments: taskData.attachments.map(attachmentTransformer),
            author: prepareAuthorData(taskData.author),
            autoComplete: taskData.isAutocomplete,
            body: taskData.body,
            countCompletedStudents: taskData.progress.completed,
            countInProgressStudents: taskData.progress.inProgress,
            countStudents: taskData.progress.total,
            createdAt: taskData.createdAt,
            dueDate: taskData.dueAt,
            contents,
            scheduledFor: taskData.scheduledFor,
            status: taskData.status,
            studentIds: taskData.students.map((student) => student.id),
            id: taskData.id,
            title: taskData.title,
            updatedAt: taskData.updatedAt,
            updatedByFullName: userFullName(taskData.updatedBy?.firstName, taskData.updatedBy?.lastName),
          }
        : null,
      userId: userData.id,
    },
    error: null,
    loading: false,
  };
}
