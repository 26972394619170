// @flow
import { useMemo } from 'react';

import { TabNav, Item } from 'src/components/TabNav/TabNav';

type TabsProps = {|
  marksUrl: string,
  overviewUrl: string,
|};

export default function Tabs(props: TabsProps) {
  const { overviewUrl, marksUrl } = props;

  const items = useMemo(
    () => [
      { href: overviewUrl, name: 'Overview' },
      { href: marksUrl, name: 'Marks' },
    ],
    [overviewUrl, marksUrl]
  );

  return (
    <TabNav ariaLabel="Progress navigation" items={items} variant="centered">
      {items.map((item) => (
        <Item key={item.href} href={item.href} routerOptions={{ state: { refocus: false, scrollTop: false } }}>
          {item.name}
        </Item>
      ))}
    </TabNav>
  );
}
