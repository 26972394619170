// @flow
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToast } from '@getatomi/neon';

import { parseValidationErrors } from 'src/api/validation';
import scrollToActiveElement from 'src/utils/scrollToActiveElement';
import { formValidation } from 'src/constants/formValidation';

export type OutboundFields = {|
  password: string,
  passwordConfirmation: string,
|};

const labels = {
  password: 'New password',
  passwordConfirmation: 'Confirm new password',
};

const resolver = yupResolver(
  yup.object().shape({
    password: yup
      .string()
      .label(labels.password)
      .required()
      .min(formValidation.password.minLength)
      .test('strength', '', function test() {
        if (this.parent.strength === null) return true;
        return this.parent.strength >= formValidation.password.minStrength
          ? true
          : this.createError({
              message:
                'The password you’ve submitted is too easy to guess and would risk the security of your account. Try creating a longer password and/or avoid using common words or sequences.',
            });
      }),
    passwordConfirmation: yup
      .string()
      .required()
      .oneOf([yup.ref('password')], 'Your confirmation password must match your new password')
      .label(labels.passwordConfirmation),
  })
);

export default function useResetPasswordForm({
  onSubmitSuccess,
}: {
  onSubmitSuccess: (data: OutboundFields) => void | Promise<void>,
}) {
  const toast = useToast();
  const { control, formState, handleSubmit, setError, setValue, trigger } = useForm({
    resolver,
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    },
  });
  const { isSubmitted } = formState;

  const handleStrengthChange = useCallback(
    (strength: number) => {
      setValue('strength', strength, { shouldValidate: isSubmitted });
      // retrigger password validation if the form has already been submitted
      if (isSubmitted && strength) trigger('password');
    },
    [isSubmitted, setValue, trigger]
  );

  const onSubmit = async (event: Event) => {
    event.preventDefault();

    handleSubmit(async (data) => {
      try {
        await onSubmitSuccess(data);
        toast.success('Your password has been reset successfully.');
      } catch (error) {
        const validationErrors = parseValidationErrors(error);
        setError('serverError', { type: 'manual', message: validationErrors });
        if (validationErrors.length === 0) {
          toast.error('There was an error resetting your password. Please try again.');
        } else {
          scrollToActiveElement();
        }
      }
    })(event);
  };

  const passwordMessage = formState.errors.password?.message ?? null;
  const passwordConfirmationMessage = formState.errors.passwordConfirmation?.message ?? null;
  const { serverError } = formState.errors;

  return {
    control,
    fields: {
      password: {
        label: labels.password,
        onStrengthChange: handleStrengthChange,
        helpText: `Must contain at least ${formValidation.password.minLength} characters.`,
        validationText: passwordMessage,
        errorVariant: passwordMessage && 'error',
        isRequired: true,
      },
      passwordConfirmation: {
        label: labels.passwordConfirmation,
        validationText: passwordConfirmationMessage,
        errorVariant: passwordConfirmationMessage && 'error',
        type: 'password',
        isRequired: true,
      },
    },
    form: {
      onSubmit,
    },
    serverError,
  };
}
