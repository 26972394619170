// @flow
import { useState } from 'react';
import invariant from 'invariant';
import { useQuery } from '@apollo/client';
import Helmet from 'react-helmet';

import GraphQLError from 'src/components/GraphQLError/GraphQLError';
import type { GetClassCompletion, GetClassCompletionVariables } from 'src/graphql/types/generated/GetClassCompletion';
import type {
  GetClassTopicsCompletion,
  GetClassTopicsCompletionVariables,
} from 'src/graphql/types/generated/GetClassTopicsCompletion';

import { transformCompletionData } from './completionTransformer';
import MarkbookLoader from '../components/MarkbookLoader';
import CompletionView from './CompletionView';
import MarkbookControls from '../components/MarkbookControls';
import renderEmptyState from '../utils/renderEmptyState';
import GET_CLASS_COMPLETION from './GetClassCompletion.graphql';
import GET_CLASS_TOPICS_COMPLETION from './GetClassTopicsCompletion.graphql';

type Props = {|
  params: {
    classId: string,
    subscriptionId: string,
  },
|};

export default function CompletionContainer(props: Props) {
  const { params } = props;
  const { classId, subscriptionId: accountId } = params;
  const expandedModuleState = useState<?string>(null);
  const [expandedModuleId, setExpandedModuleId] = expandedModuleState;

  const variables: GetClassCompletionVariables = {
    accountId,
    classId,
    contentFilters: {
      types: ['MODULE'],
    },
    studentFilters: {
      includeInvited: false,
    },
  };

  const { data, error, loading } = useQuery<GetClassCompletion, GetClassCompletionVariables>(GET_CLASS_COMPLETION, {
    variables,
  });

  const {
    data: topicsData,
    error: topicsError,
    loading: topicsLoading,
  } = useQuery<GetClassTopicsCompletion, GetClassTopicsCompletionVariables>(GET_CLASS_TOPICS_COMPLETION, {
    skip: !expandedModuleId,
    variables: {
      accountId,
      classId,
      contentFilters: {
        ancestorId: expandedModuleId,
        types: ['TOPIC'],
      },
    },
  });

  const documentTitle = (
    <Helmet>
      <title>Subject completion | Mark book</title>
    </Helmet>
  );

  if (loading && !data) {
    return (
      <>
        {documentTitle}
        <MarkbookControls isLoading params={props.params} activeLink="completion" />
        <MarkbookLoader assistiveText="Loading the list of students and their completion data." />
      </>
    );
  }

  if (error) {
    return (
      <>
        {documentTitle}
        <MarkbookControls isErrorState params={props.params} activeLink="completion" />
        <GraphQLError error={error} description="We couldn’t load the Mark Book." />
      </>
    );
  }

  const accountData = data?.me?.account;
  const classData = accountData?.class;
  invariant(accountData && classData, 'Account and class data should be defined');

  const expandedModule = {
    expandedModuleId,
    topics: topicsData?.me?.account.class?.topics,
  };

  if (expandedModuleId && !topicsLoading && !topicsError) {
    invariant(
      topicsData && topicsData.me && topicsData.me.account && topicsData.me.account.class,
      'Class data should be defined in topics response'
    );
  }

  if (topicsError) {
    setExpandedModuleId(null);
  }
  const preparedData = transformCompletionData({
    accountData,
    classData,
    ...expandedModule,
  });

  const { isClassEmpty, isFreePlan, region } = preparedData;
  const emptyState = renderEmptyState(isClassEmpty, isFreePlan, params, region);

  return (
    <>
      {documentTitle}
      {emptyState ?? (
        <CompletionView expandedModuleState={expandedModuleState} params={props.params} preparedData={preparedData} />
      )}
      ;
    </>
  );
}
