// @flow
import { useEffect, useState } from 'react';
import { Box, IconLinkExternal } from '@getatomi/neon';
import { browserHistory } from 'react-router';

import Button from 'src/components/Button/Button';

const getCurrentUrl = () => {
  const trackingParams = new URLSearchParams({
    utm_source: 'atomi',
    utm_campaign: 'embed',
  });
  return `${window.location.origin}${window.location.pathname}?${trackingParams.toString()}`;
};

export default function EmbedButton() {
  const [currentUrl, setCurrentUrl] = useState<?string>(null);

  // useEffect is only called on the client so we can retrieve the current URL there to prevent a
  // server rendering issue
  useEffect(() => {
    setCurrentUrl(getCurrentUrl());
  }, []);

  // update currentUrl when the route changes
  useEffect(() => {
    const unlistenHistory = browserHistory.listen(() => {
      setCurrentUrl(getCurrentUrl());
    });
    return () => unlistenHistory();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box alignSelf="center" paddingInline="spacingRoot">
      <Button
        as="a"
        color="colorText"
        variant="text"
        iconAfter={<IconLinkExternal size="sizeIconSmall2X" />}
        href={currentUrl}
        testHook="header-embed-referrer-button"
        target="_blank"
      >
        Open in Atomi
      </Button>
    </Box>
  );
}
