// @flow
export type BrowserState = {
  current?: { family: string, version: string },
  supported?: string,
};

const initialState = {};

export default function reducer(state: BrowserState = initialState) {
  return state;
}
