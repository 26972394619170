// @flow
import { connect } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import {
  Box,
  Container,
  Divider,
  Dropdown,
  Heading,
  HideVisually,
  Item,
  ItemDescription,
  ItemLabel,
  Modal,
  PickList,
  Skeleton,
  Stack,
  StatusLight,
  Text,
  TextField,
  Truncate,
  useId,
  useMediaQuery,
} from '@getatomi/neon';
import _ from 'lodash';

import type { BoundAction, ReduxState } from 'src/types';
import Button from 'src/components/Button/Button';
import ValidationErrors from 'src/components/ValidationErrors/ValidationErrors';
import ModalActions from 'src/components/deprecated/ModalActions/ModalActions';
import { loadCurrentSubscriptionClasses } from 'src/actions/classes';
import { inviteUserAndReloadSubscription, bulkInviteUsersAndReloadSubscription } from 'src/actions/users';
import { getNonPaginatedClasses, isLoadingClasses as isLoadingClassesSelector } from 'src/reducers/classes';
import { getActiveSubscriptionName, getUserRole, isActiveSubscriptionInSetupMode } from 'src/reducers/subscriptions';
import { isInvitingUsers as isInvitingUsersSelector } from 'src/reducers/users';
import { getRolesForInvite } from 'src/utils/roles';
import { trackingCtas } from 'src/constants/tracking';

import useInviteUserForm, { type OutboundFields } from './useInviteUserForm';
import BulkInviteUsersDialog from '../BulkInviteUsersDialog/BulkInviteUsersDialog';

function PickListLoader() {
  return (
    <>
      <HideVisually>Loading classes…</HideVisually>
      <Skeleton height={40} animation="wave" marginBottom="spacingSmall" />
      {Array.from({ length: 5 }).map((index) => (
        <Box
          key={index}
          display="flex"
          alignItems="center"
          gap="spacingSmall1X"
          borderTopWidth="borderWidthRoot"
          borderTopStyle="solid"
          borderTopColor="colorBorderNeutral"
          paddingBlock="spacingRoot"
          paddingInline="spacingSmall"
        >
          <Skeleton size="sizeIconSmall2X" borderRadius="borderRadiusCircle" />
          <Skeleton height={18} width={`${_.random(20, 50)}%`} animation="wave" />
        </Box>
      ))}
    </>
  );
}

type Props = {
  onClose: () => mixed,
  onSuccess: (alert?: boolean) => mixed,
  onViewPendingInvites: () => mixed,
  showModal: boolean,
};

type InjectedProps = Props & {
  accountName: string,
  bulkInviteUsersAction: BoundAction<typeof bulkInviteUsersAndReloadSubscription>,
  classes: Array<Object>,
  inviteUserAction: BoundAction<typeof loadCurrentSubscriptionClasses>,
  isInvitingUsers: boolean,
  isLoadingClasses: boolean,
  isSetupModeActive: boolean,
  loadAllClassesAction: BoundAction<typeof loadCurrentSubscriptionClasses>,
  roles: Array<{
    key: string,
    name: string,
  }>,
};

const mapStateToProps = (state: ReduxState) => {
  const classes = getNonPaginatedClasses(state, { incSubject: true, incLevels: true });

  return {
    roles: getRolesForInvite(getUserRole(state)),
    classes: classes.map((c) => ({
      color: c.subject.color,
      level: c.levels ? c.levels[0] : null,
      ...c,
    })),
    isInvitingUsers: isInvitingUsersSelector(state),
    isLoadingClasses: isLoadingClassesSelector(state),
    isSetupModeActive: isActiveSubscriptionInSetupMode(state),
    accountName: getActiveSubscriptionName(state),
  };
};

function InviteUsersDialog(props: InjectedProps) {
  const {
    onViewPendingInvites,
    inviteUserAction,
    onSuccess,
    classes,
    loadAllClassesAction,
    isLoadingClasses,
    roles,
    showModal,
    accountName,
    isSetupModeActive,
    isInvitingUsers,
    bulkInviteUsersAction,
    onClose,
  } = props;

  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });
  const headingId = useId(`InviteUsersDialogHeading`);
  const [isBulkInviting, setIsBulkInviting] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const { control, fields, form, reset, serverError, watch } = useInviteUserForm({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      role: null,
      classes: new Set([]),
    },
    onSubmitSuccess: async (data: OutboundFields) => {
      await inviteUserAction(data);
      onSuccess(true);
      onClose();
    },
    onSubmitFail: () => {
      onClose();
    },
  });

  const onCloseHandler = () => {
    onClose();
    setIsBulkInviting(false);
    reset();
  };

  const viewPendingInvites = (event: Event) => {
    event.preventDefault();
    onViewPendingInvites();
    onCloseHandler();
  };

  const loadClasses = () => {
    if (!isLoadingClasses && classes && classes.length === 0) {
      loadAllClassesAction({ withRelatedEntities: false });
    }
  };

  const toggleBulkInviteForm = (event: Event) => {
    event.preventDefault();
    // scroll to the top of the modal when switching between forms
    if (containerRef.current) {
      containerRef.current.scrollIntoView();
    }
    setIsBulkInviting((value) => !value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => loadClasses(), []);

  return (
    <Modal ariaLabelledBy={headingId} variant="view" size="fullscreen" isOpen={showModal} onClose={onCloseHandler}>
      <Box ref={containerRef}>
        {isBulkInviting ? (
          <BulkInviteUsersDialog
            roles={roles}
            accountName={accountName}
            isSetupModeActive={isSetupModeActive}
            isInvitingUsers={isInvitingUsers}
            bulkInviteUsers={bulkInviteUsersAction}
            onClose={onCloseHandler}
            onSuccess={onSuccess}
            onGoBack={toggleBulkInviteForm}
            headingId={headingId}
          />
        ) : (
          <Box paddingBottom="spacingLarge5X">
            <form
              {...form}
              autoComplete="off"
              // prevent HTML validation, this is handled by the hook:
              // https://github.com/react-hook-form/react-hook-form/issues/10679#issuecomment-1638176948
              noValidate
            >
              <Stack spacing="spacingLarge3X">
                <Container maxWidth="sizeContainerRoot" textAlign="center">
                  <Stack spacing="spacingLarge2X">
                    <Heading as="h1" id={headingId}>
                      Invite users
                    </Heading>
                    {!isMobile && (
                      <Text variant="lead" as="p">
                        Invite new users to join your school account!
                      </Text>
                    )}
                  </Stack>
                </Container>
                <Container maxWidth="sizeContainerSmall">
                  {!isMobile && (
                    <Box marginBottom="spacingLarge3X">
                      <Divider />
                    </Box>
                  )}
                  <Stack spacing="spacingLarge1X">
                    {serverError && <ValidationErrors errors={serverError.message} />}
                    <Box
                      display="grid"
                      gridTemplateColumns={{ tablet: '1fr 180px' }}
                      columnGap="spacingRoot"
                      rowGap="spacingLarge1X"
                    >
                      <Controller
                        control={control}
                        name="email"
                        render={({ field }) => <TextField {...field} {...fields.email} />}
                      />
                      <Controller
                        control={control}
                        name="role"
                        render={({ field }) => (
                          <Dropdown
                            {...field}
                            {...fields.role}
                            items={roles}
                            selectedKey={field.value}
                            onSelectionChange={field.onChange}
                          >
                            {(item) => <Item>{item.name}</Item>}
                          </Dropdown>
                        )}
                      />
                    </Box>
                    <Controller
                      control={control}
                      name="firstName"
                      render={({ field }) => <TextField {...field} {...fields.firstName} />}
                    />
                    <Controller
                      control={control}
                      name="lastName"
                      render={({ field }) => <TextField {...field} {...fields.lastName} />}
                    />
                    {isLoadingClasses ? (
                      <PickListLoader />
                    ) : (
                      classes.length > 0 && (
                        <Controller
                          control={control}
                          name="classes"
                          render={({ field }) => (
                            <PickList
                              {...field}
                              {...fields.classes}
                              inputPlaceholder="Search by class or subject name"
                              emptyState="None of the classes match your search."
                              items={classes}
                              selectedKeys={field.value}
                              onSelectionChange={field.onChange}
                              filter={(item, text) => {
                                const searchText = text.toLowerCase().trim();
                                return (
                                  item.name.toLowerCase().includes(searchText) ||
                                  item.subject.name.toLowerCase().includes(searchText)
                                );
                              }}
                              renderTag={(item) => (
                                <Item textValue={item.name}>
                                  <StatusLight color={item.color} />
                                  <Truncate>{item.name}</Truncate>
                                </Item>
                              )}
                            >
                              {(item) => (
                                <Item textValue={item.name}>
                                  <StatusLight color={item.color} />
                                  <Box>
                                    <ItemLabel>
                                      <Text variant="bodySmall" lineHeight="lineHeightSmall2X" color="colorText">
                                        {item.name}
                                      </Text>
                                    </ItemLabel>
                                    {item.subject && item.level && (
                                      <ItemDescription>
                                        <Text variant="bodySmall1X" color="colorTextSubtler">
                                          {item.subject.name}, {item.level.name}
                                        </Text>
                                      </ItemDescription>
                                    )}
                                  </Box>
                                </Item>
                              )}
                            </PickList>
                          )}
                        />
                      )
                    )}
                  </Stack>
                </Container>
                <Stack spacing="spacingLarge6X">
                  <ModalActions
                    isStacked
                    submitLabel="Invite user"
                    isLoading={isInvitingUsers}
                    onCancel={onCloseHandler}
                    trackingData={{
                      cta: trackingCtas.subscriptionInviteUser,
                      userType: watch('role'),
                    }}
                  />
                  <Text as="p" variant="bodySmall1X" textAlign="center">
                    Need to invite many more?{' '}
                    <Button size="small" variant="text" onClick={toggleBulkInviteForm}>
                      Bulk import users instead.
                    </Button>
                    <br />
                    <Button size="small" variant="text" onClick={viewPendingInvites}>
                      View pending invites.
                    </Button>
                  </Text>
                </Stack>
              </Stack>
            </form>
          </Box>
        )}
      </Box>
    </Modal>
  );
}

export default (connect(mapStateToProps, {
  inviteUserAction: inviteUserAndReloadSubscription,
  bulkInviteUsersAction: bulkInviteUsersAndReloadSubscription,
  loadAllClassesAction: loadCurrentSubscriptionClasses,
})(InviteUsersDialog): React.AbstractComponent<Props>);
