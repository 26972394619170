// @flow
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { Button, ItemLabel, Modal, StatusLight, Text, useMediaQuery, useToast } from '@getatomi/neon';
import { withRouter } from 'react-router';

import type {
  GetDuplicateTaskClasses,
  GetDuplicateTaskClassesVariables,
} from 'src/graphql/types/generated/GetDuplicateTaskClasses';
import { Dropdown, Item } from 'src/components/Dropdown/Dropdown';
import useIntercomWidget from 'src/hooks/useIntercomWidget';
import type { EditableTask } from 'src/components/useTaskDialog/TaskDialog';

import GET_DUPLICATE_TASK_CLASSES from './GetDuplicateTaskClasses.graphql';
import useReplaceRevisions from './useReplaceRevisions';

type Params = {
  subscriptionId: string,
};

export type CurrentClass = {
  id: string,
  name: string,
  subject: {
    code: string,
    color: string,
    groupCode: string,
    levels: $ReadOnlyArray<string>,
  },
};

type Props = {|
  defaultDuplicateClass: CurrentClass,
  onClose: () => mixed,
  onSubmit: (args: { duplicateClassId: string, duplicateTask: EditableTask }) => mixed,
  params: Params,
  task: EditableTask,
|};

function DuplicateTaskDialog(props: Props) {
  const {
    defaultDuplicateClass,
    task,
    onClose,
    onSubmit,
    params: { subscriptionId },
  } = props;
  const [duplicateClassId, setDuplicateClassId] = useState<string>(defaultDuplicateClass.id);
  const isMobile = useMediaQuery({ maxWidth: 'breakpointMedium' });
  const [isDuplicating, setIsDuplicating] = useState(false);
  const toast = useToast();
  const [show, hide] = useIntercomWidget();
  useEffect(() => {
    hide();
    return () => {
      show();
    };
  });

  const { data, error, loading } = useQuery<GetDuplicateTaskClasses, GetDuplicateTaskClassesVariables>(
    GET_DUPLICATE_TASK_CLASSES,
    {
      variables: {
        accountId: subscriptionId,
        filters: {
          source: 'SUBSCRIPTION',
          subjectCode: defaultDuplicateClass.subject.code,
        },
      },
    }
  );

  const duplicateAndReplaceRevisions = useReplaceRevisions({
    body: task.body,
    classId: duplicateClassId,
    contents: task.contents,
    subscriptionId,
  });

  if (error) {
    toast.error('There was an error duplicating this task. Please try again.');
    onClose();
  }

  const classes = (data && data.me && data.me.account.classes.edges.map(({ node }) => node)) ?? [];

  const inputOnChange = (classId: string) => {
    setDuplicateClassId(classId);
  };

  const handleOnClose = () => {
    setDuplicateClassId(defaultDuplicateClass.id);
    onClose();
  };

  const handleOnSubmit = async (e: SyntheticEvent<>) => {
    e.preventDefault();
    setIsDuplicating(true);
    const { body, contents } = await duplicateAndReplaceRevisions();
    setIsDuplicating(false);

    const duplicatedFields = {
      attachments: task.attachments || [],
      body,
      contents,
      // Reset due date if existing due date is in the past
      dueDate: task.dueDate && new Date(task.dueDate) < new Date() ? null : task.dueDate,
      title: task.title || 'Untitled',
    };
    const revertedFields = {
      status: 'draft',
      autoComplete: true,
      scheduledFor: null,
      countComplete: 0,
      countInProgress: 0,
    };
    const duplicateTask: EditableTask = {
      // Remove `id` to avoid overwriting existing task
      // and don't carry over list of selected students
      ..._.omit(task, ['id', 'studentIds']),
      ...duplicatedFields,
      ...revertedFields,
    };
    onSubmit({
      duplicateClassId,
      duplicateTask,
    });
    handleOnClose();
  };

  let options = [];
  if (loading && !classes.length) {
    options = [
      {
        id: defaultDuplicateClass.id,
        color: defaultDuplicateClass.subject.color,
        label: `${defaultDuplicateClass.name} (This class)`,
      },
    ];
  } else {
    options = classes.map(({ id, name, subject }) => {
      const isCurrentClass = id === defaultDuplicateClass.id;
      return {
        id,
        color: subject.color,
        label: `${name}${isCurrentClass ? ' (This class)' : ''}`,
      };
    });
  }

  const taskOrDraftLabel = task.status === 'draft' ? 'draft' : 'task';

  return (
    <Modal
      heading={`Duplicate ${taskOrDraftLabel}`}
      isOpen
      onClose={onClose}
      actions={
        <>
          <Button isFullWidth={isMobile} size="small" variant="text" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button isFullWidth={isMobile} size="small" onClick={handleOnSubmit} isLoading={isDuplicating}>
            Duplicate
          </Button>
        </>
      }
    >
      <Text variant="bodyRoot" as="p" marginBottom="spacingRoot">
        Select a class for your duplicated {taskOrDraftLabel}.
      </Text>
      <form onSubmit={handleOnSubmit} noValidate>
        <Dropdown
          label="Class"
          items={options}
          selectedKey={duplicateClassId}
          onSelectionChange={inputOnChange}
          isLoading={loading}
        >
          {(item) => (
            <Item textValue={item.label}>
              <StatusLight color={item.color} />
              <ItemLabel>{item.label}</ItemLabel>
            </Item>
          )}
        </Dropdown>
      </form>
    </Modal>
  );
}

export default withRouter(DuplicateTaskDialog);
