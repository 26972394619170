// @flow
import { Box } from '@getatomi/neon';

import Link from 'src/components/Link/Link';
import logo from 'src/assets/images/atomi-logo-text-only.svg';
import links from 'src/constants/links';

export default function Footer() {
  return (
    <Box
      as="footer"
      display="flex"
      alignItems="center"
      flexDirection="column"
      gap="spacingSmall"
      marginBottom={{ base: 'spacingLarge2X', tablet: 'spacingLarge3X' }}
      marginTop={{ base: 'spacingLarge5X', tablet: 'spacingLarge9X' }}
    >
      <img src={logo} width="56" height="15" alt="Atomi logo" />
      <Box
        as="ul"
        display={{ base: 'none', tablet: 'flex' }}
        justifyContent="center"
        gap="spacingSmall1X"
        color="colorTextSubtle"
        fontSize="fontSizeSmall1X"
        fontWeight="fontWeightBold"
      >
        <li>
          <Link variant="monochrome" href={links.support.home} isExternal>
            Get support.
          </Link>
        </li>
        <li>
          <Link variant="monochrome" href={links.blog}>
            Visit our Blog.
          </Link>
        </li>
        <li>
          <Link variant="monochrome" href={links.careers}>
            We’re hiring!
          </Link>
        </li>
      </Box>
    </Box>
  );
}
