// @flow
const baseNucleusUrl = process.env.NEXT_PUBLIC_NUCLEUS_BASE_URL;

/**
 * We need to use dynamic protocol because in production or staging, or any branch deployments Carbon is running
 * node without SSL certificates because it's handled by the ELB so all internal connections are insecured. The proxy
 * URL in these environments is http://localhost.
 *
 * However our dev server (webpack) only runs using a secure connection because we need to handle secure cookies,
 * and we don't have an SSL proxy in front of it. We will address this when Carbon is moved to the platform monorepo.
 */
const proxyProtocol = __DEVELOPMENT__ ? 'https' : 'http';
const proxyBaseUrl = __CLIENT__ ? '/api' : `${proxyProtocol}://localhost:${process.env.PORT}/api`;

export const AUTH_STRATEGIES_ENDPOINT = `${baseNucleusUrl}/auth-strategy`;
export const LEVELS_ENDPOINT = `${baseNucleusUrl}/levels`;
export const PASSWORD_ENDPOINT = `${baseNucleusUrl}/password`;
export const REGIONS_ENDPOINT = `${baseNucleusUrl}/regions`;
export const SOCIAL_LOGIN_ENDPOINT = `${baseNucleusUrl}/social-login`;
export const SUBSCRIPTIONS_ENDPOINT = `${baseNucleusUrl}/subscriptions`;
export const USER_ENDPOINT = `${baseNucleusUrl}/user`;
export const USERS_ENDPOINT = `${baseNucleusUrl}/users`;

// Proxied endpoints
export const PROXIED_PASSWORD_ENDPOINT = `${proxyBaseUrl}/password`;
export const PROXIED_SOCIAL_LOGIN_ENDPOINT = `${proxyBaseUrl}/social-login`;
export const PROXIED_SUBSCRIPTIONS_ENDPOINT = `${proxyBaseUrl}/subscriptions`;
export const PROXIED_USER_ENDPOINT = `${proxyBaseUrl}/user`;
