// @flow
import { useEffect, useRef, useState } from 'react';
import { Box, Container, EmptyState, Illustration, Card, Divider } from '@getatomi/neon';
import _ from 'lodash';

import Button from 'src/components/Button/Button';
import getSubjectColors from 'src/utils/getSubjectColors';
import taskStatuses from 'src/constants/taskStatuses';

import type { TaskAttachment } from '../types';
import type { GetTasks, Task } from './useGetStudentTasks';
import { isCompletedTask, groupStudentTasks } from '../utils/groupTasks';
import StudentTaskCardHeader from '../components/StudentTaskCardHeader/StudentTaskCardHeader';
import TaskCardBody from '../components/TaskCardBody/TaskCardBody';
import { StudentTaskCardContentList } from '../components/TaskCardContentList/TaskCardContentList';
import TaskAttachmentList from '../components/TaskAttachmentList/TaskAttachmentList';
import StudentTaskCompletionToggle from '../components/StudentTaskCompletionToggle/StudentTaskCompletionToggle';
import TaskList from '../components/TaskList';

type StudentTaskCardProps = {|
  highlightOverdue: boolean,
  onTaskCompletionChange: (id: string, isCompleted: boolean) => mixed,
  task: Task,
|};

type StudentTaskListProps = {|
  clearFilters: () => mixed,
  data: GetTasks,
  isFiltering: boolean,
  onTaskCompletionChange: (id: string, isCompleted: boolean) => mixed,
  status: ?$Keys<typeof taskStatuses>,
|};

function StudentTaskCard(props: StudentTaskCardProps) {
  const { onTaskCompletionChange, task, highlightOverdue } = props;
  const { attachments, author, body, subject, dueDate, id: taskId, contents, title } = task;

  const colors = getSubjectColors(subject.groupCode, subject.color);
  const isTaskCompleted = isCompletedTask(task);
  const [isCompleted, setIsCompleted] = useState(isTaskCompleted);
  const isRequestInFlightRef = useRef<boolean>(false);

  // Using useEffect to update the derived state only when isTaskCompleted changes. The isCompleted
  // hook dependency is ignored on purpose to prevent the state change to be reverted when
  // onCompletionChange is called
  useEffect(() => {
    isRequestInFlightRef.current = false;
    if (isTaskCompleted !== isCompleted) {
      setIsCompleted(isTaskCompleted);
    }
  }, [isTaskCompleted]); // eslint-disable-line react-hooks/exhaustive-deps

  const onCompletionChange = () => {
    if (isRequestInFlightRef.current) return;
    // toggle the completion in the derived state for quick UI feedback
    setIsCompleted((value) => !value);
    // set the ref to avoid other updates while mutation is in progress
    isRequestInFlightRef.current = true;
    onTaskCompletionChange(taskId, !isCompleted);
  };

  // completion toggle is disabled when the task is marked as autocomplete or when not
  // all the attached task content is done
  const isCompletionDisabled = task.autoComplete || Boolean(task.contents.find((content) => content.progress === null));

  return (
    <Card as="article" padding="spacingRoot" variant="elevated" withHover={false} testHook="task-list-card">
      <Box marginBottom="spacingSmall">
        <StudentTaskCardHeader
          author={author}
          dueDate={dueDate}
          subjectColor={colors.background}
          subjectName={subject.name}
          highlightOverdue={highlightOverdue}
        />
      </Box>

      <TaskCardBody title={title} body={body} taskUrl={task.url} />

      {attachments.length > 0 && (
        <Box marginBlock="spacingRoot">
          <TaskAttachmentList attachments={((attachments: $FlowSuppressAny): $ReadOnlyArray<TaskAttachment>)} />
        </Box>
      )}

      {contents.length > 0 && <StudentTaskCardContentList contents={contents} subjectRootColor={colors.icon} />}

      <Divider />

      <Box marginTop="spacingRoot">
        <StudentTaskCompletionToggle
          colors={colors}
          isCompleted={isCompleted}
          isDisabled={isCompletionDisabled}
          onClick={onCompletionChange}
        />
      </Box>
    </Card>
  );
}

function StudentTaskList(props: StudentTaskListProps) {
  const { clearFilters, data, isFiltering, onTaskCompletionChange, status } = props;

  const sections = groupStudentTasks(data.tasks);

  if (_.isEmpty(sections)) {
    return (
      <Container>
        <Box marginTop="spacingLarge5X">
          {isFiltering ? (
            <EmptyState
              media={<Illustration name="emptystate-tasks" />}
              description="There are no results for your selection."
              primaryAction={
                <Button variant="text" onClick={clearFilters}>
                  Clear class filter
                </Button>
              }
            />
          ) : (
            <EmptyState
              media={<Illustration name="emptystate-tasks" />}
              heading={
                status === taskStatuses.completed
                  ? 'You haven’t completed any tasks, yet.'
                  : 'You have no tasks to do, yet.'
              }
              headingProps={{ as: 'h1' }}
              description={
                status === taskStatuses.completed
                  ? 'When you complete a task it’ll show up here.'
                  : 'When your teachers create tasks, they’ll show up here!'
              }
            />
          )}
        </Box>
      </Container>
    );
  }

  return (
    <TaskList>
      {Object.keys(sections).map((sectionName) => (
        <TaskList.Section
          key={sectionName}
          renderTask={(task: Task) => (
            <StudentTaskCard
              key={task.id}
              onTaskCompletionChange={onTaskCompletionChange}
              task={task}
              highlightOverdue={status !== taskStatuses.completed}
            />
          )}
          tasks={sections[sectionName]}
          title={sectionName}
        />
      ))}
    </TaskList>
  );
}

export default StudentTaskList;
