// @flow
import { Badge, Box, Card, Flex, Heading, HideVisually, Stack, Text, Truncate } from '@getatomi/neon';

import AvatarGroup from 'src/components/AvatarGroup/AvatarGroup';
import Avatar from 'src/components/Avatar/Avatar';
import ClassProgress from 'src/domains/Dashboard/ClassProgress/ClassProgress';
import SubjectIcon from 'src/components/SubjectIcon/SubjectIcon';
import SubjectLevelBadges from 'src/components/SubjectLevelBadges/SubjectLevelBadges';
import SubscriptionLink from 'src/components/SubscriptionLink/SubscriptionLink';

import type { ClassData } from '../../useGetClasses/useGetClasses';

type Props = {|
  currentClass: ClassData,
  withStudentsCount?: boolean,
  withSubjectTag?: boolean,
|};

export default function ClassCard(props: Props) {
  const { currentClass, withSubjectTag = false, withStudentsCount = false } = props;
  const { classProgress, id, levels, name, studentCount, subject, teachers } = currentClass;
  const { color } = subject;

  return (
    <Card as="article" testHook={`class-card-${subject.code}`}>
      <Box textAlign="center" position="relative" paddingBottom={withSubjectTag ? 'spacingLarge4X' : 'spacingLarge'}>
        <SubscriptionLink classId={id} testHook="class-card-link" isUnstyled>
          <Flex direction="column" textAlign="center">
            <Flex
              backgroundColor={color}
              marginTop="spacingNegativeSmall4X"
              marginInline="spacingNegativeSmall4X"
              marginBottom="spacingRoot"
              paddingBlock="spacingLarge4X"
              justifyContent="center"
              alignItems="center"
              // prevent the background from cutting off the Card borders when Windows High Contrast
              // Mode is enabled
              _highContrast={{ margin: 'spacingNone' }}
            >
              <SubjectIcon
                name={subject.icon}
                size="sizeIconLarge1X"
                color="colorIconInverted"
                display="inline-block"
                strokeWidth={2}
              />
            </Flex>

            <Box paddingInline="spacingSmall1X">
              <Heading as="h2" marginBottom="spacingSmall1X" variant="small">
                <Truncate>{name}</Truncate>
                {/* https://www.filamentgroup.com/lab/a11y-links.html#use-punctuation */}
                <HideVisually>.</HideVisually>
              </Heading>
              <Stack spacing="spacingLarge">
                <SubjectLevelBadges
                  levelsData={levels}
                  isCentered
                  isTwoLines={withSubjectTag}
                  subjectData={withSubjectTag ? subject : undefined}
                />

                {withStudentsCount && (
                  <Flex alignItems="center" justifyContent="center" gap="spacingSmall1X" testHook="student-count">
                    <Badge variant="counter">{studentCount}</Badge>{' '}
                    <Text as="span" fontSize="fontSizeSmall1X" color="colorTextSubtler">
                      Students
                    </Text>
                  </Flex>
                )}
                {classProgress != null ? (
                  <Box paddingInline="spacingSmall">
                    <ClassProgress progress={classProgress} subjectColor={subject.color} />
                  </Box>
                ) : null}
              </Stack>
            </Box>
          </Flex>
        </SubscriptionLink>
        <Box position="absolute" left={0} right={0} bottom="spacingNegativeRoot">
          <AvatarGroup max={3}>
            {teachers.map((teacher) => (
              <Avatar key={teacher.id} user={teacher} withTooltip isDecorative={false} />
            ))}
          </AvatarGroup>
        </Box>
      </Box>
    </Card>
  );
}
