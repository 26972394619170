// @flow
import { Alert } from '@getatomi/neon';
import pluralize from 'pluralize';

import Link from 'src/components/Link/Link';

export type LessonFilterAlertProps = {|
  filteredTaskCount: number,
  pathname: string,
|};

function LessonFilterAlert(props: LessonFilterAlertProps) {
  const { pathname, filteredTaskCount } = props;

  return (
    <Alert variant="info" testHook="task-list-alert">
      {!filteredTaskCount
        ? 'There are no tasks'
        : `There ${pluralize('is', filteredTaskCount)} ${pluralize('task', filteredTaskCount, true)}`}{' '}
      containing the selected lesson. To view all tasks{' '}
      <Link to={{ pathname, state: { scrollTop: false } }} variant="monochrome" testHook="clear-filter-button">
        clear this filter
      </Link>
      .
    </Alert>
  );
}

export default LessonFilterAlert;
