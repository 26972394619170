// @flow
import moment from 'moment';
import { Box, HideVisually } from '@getatomi/neon';

import type { DateString } from 'src/types';

const dateFormat = 'ddd D MMM';
// https://momentjs.com/docs/#/displaying/calendar-time/
const calendarFormat = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  lastDay: '[Yesterday]',
  nextWeek: dateFormat,
  lastWeek: dateFormat,
  sameElse: dateFormat,
};
const timeFormat = '[at] h a';

export type TaskDateTimeProps = {|
  /**
   * Display the text with the `colorTextDanger` colour if it is in the past.
   * @default false
   */
  highlightOverdue?: boolean,
  /**
   * Text to display before the date and time value. This is useful for adding a text such as "Due"
   * that would also have the status colour applied to it.
   */
  prefix?: string,
  testHook?: string,
  value: Date | DateString | moment$Moment,
|};

export default function TaskDateTime(props: TaskDateTimeProps) {
  const { highlightOverdue = false, prefix, testHook, value } = props;

  const date = moment(value);
  const dateString = date.calendar(null, calendarFormat);
  const timeString = date.format(timeFormat);
  const isDateInPast = highlightOverdue && date.isBefore();

  return (
    <Box as="span" color={isDateInPast ? 'colorTextDanger' : 'inherit'} testHook={testHook}>
      {prefix && `${prefix} `}
      <time dateTime={date.format()}>
        {dateString} {timeString}
      </time>
      {isDateInPast && <HideVisually>Overdue</HideVisually>}
    </Box>
  );
}
