// @flow
import type { TaskActions } from 'src/domains/Tasks/ClassTasks/utils/getTaskActionsById';

import type { ClassTask, ClassTaskContent } from './useGetClassTasks';
import { groupClassTasks } from '../utils/groupTasks';
import ClassTasksListCard from './ClassTasksListCard';
import TaskList from '../components/TaskList';

type ClassTasksListPops = {|
  getTaskActions: (id: string) => TaskActions,
  onStudentCountClick: (content: ClassTaskContent) => mixed,
  studentCount: number,
  taskProgressReportUrlFactory: (taskId: string) => string,
  tasksData: $ReadOnlyArray<ClassTask>,
|};

export default function ClassTasksList(props: ClassTasksListPops) {
  const { tasksData, getTaskActions, studentCount, onStudentCountClick, taskProgressReportUrlFactory } = props;

  const sections = groupClassTasks(tasksData);

  return (
    <TaskList>
      {Object.keys(sections).map((sectionName) => (
        <TaskList.Section
          key={sectionName}
          renderTask={(task) => (
            <ClassTasksListCard
              getTaskActions={getTaskActions}
              onStudentCountClick={onStudentCountClick}
              studentCount={studentCount}
              task={task}
              taskProgressReportUrlFactory={taskProgressReportUrlFactory}
            />
          )}
          tasks={sections[sectionName]}
          title={sectionName}
        />
      ))}
    </TaskList>
  );
}
