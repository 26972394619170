// @flow
import type { GetUserClasses_me_account_classes_edges_node as QueryClass } from 'src/graphql/types/generated/GetUserClasses';

export type UserClass = {
  assessmentCount: number,
  id: string,
  levels: $ReadOnlyArray<{ +id: string, +name: string }>,
  subject: {
    code: string,
    color: string,
    groupCode: string,
    shortName: string,
  },
};

export default function transformUserClasses(
  classes: $ReadOnlyArray<{ +node: QueryClass }>
): $ReadOnlyArray<UserClass> {
  return classes.map(({ node }) => {
    const {
      id,
      subject: { code, color, groupCode, levels, shortName },
      contentNodes,
    } = node;

    const assessmentCount = contentNodes
      ? contentNodes.reduce((acc, content) => {
          if (content.__typename !== 'Category') return acc;
          return acc + (content.assessmentIds?.length ?? 0);
        }, 0)
      : 0;

    return {
      id,
      levels,
      subject: {
        code,
        color,
        groupCode,
        shortName,
      },
      assessmentCount,
    };
  });
}
