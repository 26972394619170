// @flow
import type { ContextRouter } from 'react-router';
import { useToast } from '@getatomi/neon';

import useProgressReportDialog from 'src/hooks/components/useProgressReportDialog';
import { getTaskUrl, getTaskProgressReportUrl } from 'src/utils/routes';
import { trackEvent } from 'src/utils/tracking';
import { trackingCtas, trackingCtaTypes, trackingEvents } from 'src/constants/tracking';
import type { OpenTaskDialogParams } from 'src/components/useTaskDialog/useTaskDialog';

import type { ClassTask, CurrentClass, TaskStatus } from './useGetClassTasks';
import useDeleteTaskAlertDialog from '../components/useDeleteTaskAlertDialog/useDeleteTaskAlertDialog';
import getTaskActionsById from './utils/getTaskActionsById';
import useDuplicateTaskDialog from '../components/useDuplicateTaskDialog/useDuplicateTaskDialog';
import LessonFilterAlert from './LessonFilterAlert';
import ClassTasksList from './ClassTasksList';
import ClassTasksListEmptyState from './ClassTasksListEmptyState';

export type ClassTasksProps = {|
  currentClass: CurrentClass,
  isFreePlan: boolean,
  isLessonFilterApplied: boolean,
  openTaskDialog: (args: OpenTaskDialogParams) => void,
  region: string,
  router: ContextRouter,
  status?: TaskStatus,
  subscriptionId: string,
  tasksData: $ReadOnlyArray<ClassTask>,
|};

export default function ClassTasks(props: ClassTasksProps) {
  const {
    currentClass,
    isFreePlan,
    isLessonFilterApplied,
    openTaskDialog,
    status,
    subscriptionId,
    tasksData,
    region,
    router,
  } = props;
  const { id: classId } = currentClass;

  const toast = useToast();

  const copyTaskUrlToClipboard = async (taskId: string) => {
    const url = `${window.location.origin}${getTaskUrl(subscriptionId, classId, taskId)}`;
    try {
      await navigator.clipboard.writeText(url);
      toast.success('Link copied to the clipboard.');
      trackEvent(trackingEvents.ctaClicked, {
        cta: trackingCtas.copyLink,
        type: trackingCtaTypes.button,
        url,
        taskId,
        subject_code: currentClass.subject.code,
      });
    } catch (error) {
      toast.error('There was an error copying the link to the clipboard. Please try again.');
    }
  };

  const [progressReportDialog, { openProgressReportDialog }] = useProgressReportDialog({
    classId,
    isFreePlan,
    region,
    subscriptionId,
  });

  const [deleteTaskAlertDialog, { open: openDeleteTaskAlertDialog }] = useDeleteTaskAlertDialog({
    onConfirm: () => {
      const isPaginated = router.location.query.after;
      if (isPaginated && tasksData.length === 0) {
        // Reset pagination
        router.push(router.location.pathname);
      }
    },
  });

  const onDuplicateTaskSubmit = ({ duplicateTask, duplicateClassId }) => {
    openTaskDialog({ task: duplicateTask, classId: duplicateClassId });
  };

  const [duplicateTaskDialog, { open: openDuplicateTaskModal }] = useDuplicateTaskDialog({
    currentClass,
    onSubmit: onDuplicateTaskSubmit,
  });

  const taskProgressReportUrlFactory = (taskId: string) => getTaskProgressReportUrl(subscriptionId, classId, taskId);

  const getTaskActions = getTaskActionsById({
    tasks: tasksData,
    onTaskSelect: (id) => router.push(getTaskUrl(subscriptionId, classId, id)),
    onProgressSelect: (task) => router.push(getTaskProgressReportUrl(subscriptionId, classId, task.id)),
    onEditTaskSelect: (task) => openTaskDialog({ task }),
    onCopyLinkSelect: (id) => copyTaskUrlToClipboard(id),
    onDeleteTaskSelect: (task) => openDeleteTaskAlertDialog(task.id, task.status),
    onDuplicateTaskSelect: (task) => openDuplicateTaskModal(task),
  });

  const emptyState = isLessonFilterApplied ? null : <ClassTasksListEmptyState status={status} />;

  return (
    <>
      {isLessonFilterApplied && (
        <LessonFilterAlert filteredTaskCount={tasksData.length ?? 0} pathname={router.location.pathname} />
      )}
      {tasksData.length === 0 ? (
        emptyState
      ) : (
        <ClassTasksList
          tasksData={tasksData}
          getTaskActions={getTaskActions}
          studentCount={currentClass.studentCount}
          onStudentCountClick={(post) => openProgressReportDialog({ post, moduleId: post.moduleId })}
          taskProgressReportUrlFactory={taskProgressReportUrlFactory}
        />
      )}

      {progressReportDialog}
      {deleteTaskAlertDialog}
      {duplicateTaskDialog}
    </>
  );
}
