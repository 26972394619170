// @flow
import invariant from 'invariant';
import { type ContextRouter } from 'react-router';

import { getClassTasksUrl } from 'src/utils/routes';
import GraphQLError from 'src/components/GraphQLError/GraphQLError';

import ClassTask from './ClassTask';
import TaskDoesNotExist from '../components/TaskDoesNotExist';
import TaskContainerLoader from '../components/TaskContainerLoader';
import useGetClassTask from './useGetClassTask';

type Props = {|
  params: {
    classId: string,
    subscriptionId: string,
    taskId: string,
  },
  router: ContextRouter,
|};

export default function ClassTaskContainer(props: Props) {
  const {
    params: { classId, subscriptionId, taskId },
    router,
  } = props;

  const { data, error, loading } = useGetClassTask({ classId, subscriptionId, taskId });

  const tasksPageRedirectUrl = getClassTasksUrl(subscriptionId, classId);

  if (loading && !data) {
    return <TaskContainerLoader backLinkLabel="Back to Tasks" backLinkUrl={tasksPageRedirectUrl} />;
  }

  if (error) {
    return <GraphQLError error={error} description="We couldn’t load the task." />;
  }

  invariant(data, 'Class task data should be defined');

  const { currentClass, isFreePlan, region, task, userId } = data;

  if (!task) {
    return <TaskDoesNotExist tasksPageRedirectUrl={tasksPageRedirectUrl} />;
  }

  return (
    <ClassTask
      currentClass={currentClass}
      isFreePlan={isFreePlan}
      region={region}
      router={router}
      subscriptionId={subscriptionId}
      taskData={task}
      userId={userId}
    />
  );
}
