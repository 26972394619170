// @flow
import { useQuery, type ApolloError } from '@apollo/client';
import invariant from 'invariant';

import type {
  GetStudentTask,
  GetStudentTask_me_account_tasks_edges_node_attachments as GetStudentTaskAttachments,
  GetStudentTaskVariables,
} from 'src/graphql/types/generated/GetStudentTask';
import type { TaskAttachment } from 'src/domains/Tasks/types';
import userStatusOnTask from 'src/constants/userStatusOnTask';

import { contentTransformer, prepareAuthorData, type Author, type TaskContent } from '../taskTransformer';
import GET_STUDENT_TASK from './GetStudentTask.graphql';

export type CurrentClass = {|
  id: string,
  subject: {|
    code: string,
    color: string,
    groupCode: string,
    name: string,
  |},
|};

export type Task = {|
  attachments: $ReadOnlyArray<TaskAttachment>,
  author: Author,
  autoComplete: boolean,
  body: ?string,
  contents: $ReadOnlyArray<TaskContent>,
  dueAt: any,
  isComplete: boolean,
  taskId: string,
  title: ?string,
  updatedAt: any,
|};

type Output = {|
  data: ?{|
    currentClass: ?CurrentClass,
    task: ?Task,
    userId: string,
  |},
  error: ?ApolloError,
  loading: boolean,
|};

function prepareAttachmentData(attachment: GetStudentTaskAttachments): TaskAttachment {
  return {
    externalId: attachment.externalId ?? '',
    fileName: attachment.fileName ?? '',
    hash: attachment.hash ?? '',
    id: attachment.id,
    isInfected: attachment.isInfected ?? false,
    type: attachment.type,
    url: attachment.url,
  };
}

export default function useGetStudentTask({
  classId,
  subscriptionId,
  taskId,
}: {
  classId: string,
  subscriptionId: string,
  taskId: string,
}): Output {
  const { data, error, loading } = useQuery<GetStudentTask, GetStudentTaskVariables>(GET_STUDENT_TASK, {
    variables: {
      accountId: subscriptionId,
      filters: {
        classId,
        ids: [taskId],
      },
    },
  });

  if (error) {
    return {
      data: null,
      error,
      loading: false,
    };
  }

  if (loading && !data) {
    return {
      data: null,
      error: null,
      loading: true,
    };
  }

  const userData = data?.me;

  invariant(userData, 'User data should be defined');

  const taskData = userData.account.tasks.edges[0]?.node;
  if (!taskData) {
    return {
      data: {
        currentClass: null,
        task: null,
        userId: userData.id,
      },
      error: null,
      loading: false,
    };
  }

  const accountData = userData.account;
  const currentClass = taskData.class;

  invariant(accountData && currentClass && taskData, 'Task data should be defined');

  const contents = taskData.contents
    .filter(({ __typename }) => !__typename.includes('Locked'))
    .map((content) =>
      contentTransformer({
        content,
        subscriptionId,
        classId: currentClass.id,
      })
    );

  return {
    data: {
      currentClass: {
        id: currentClass.id,
        subject: {
          code: currentClass.subject.code,
          color: currentClass.subject.color,
          groupCode: currentClass.subject.groupCode,
          name: currentClass.subject.shortName,
        },
      },
      task: {
        attachments: taskData.attachments.map(prepareAttachmentData),
        author: prepareAuthorData(taskData.author),
        autoComplete: taskData.isAutocomplete,
        body: taskData.body,
        contents,
        dueAt: taskData.dueAt,
        isComplete: taskData.progress.status === userStatusOnTask.COMPLETE,
        taskId: taskData.id,
        title: taskData.title,
        updatedAt: taskData.updatedAt,
      },
      userId: userData.id,
    },
    error: null,
    loading: false,
  };
}
