// @flow
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { type ContextRouter, withRouter } from 'react-router';
import { Controller } from 'react-hook-form';
import { Box, Container, Heading, Stack, TextField, useToast } from '@getatomi/neon';

import type { BoundAction } from 'src/types/index';
import Button from 'src/components/Button/Button';
import ErrorTypeMessage from 'src/components/ErrorTypeMessage/ErrorTypeMessage';
import FormMessage from 'src/components/FormMessage/FormMessage';
import LogoOnlyLayout from 'src/layouts/LogoOnlyLayout/LogoOnlyLayout';
import { requestResetPasswordEmail } from 'src/actions/auth';
import { getLoginUrl } from 'src/utils/routes';

import ForgotPasswordHelp from './ForgotPasswordHelp/ForgotPasswordHelp';
import useForgotPasswordForm, { type OutboundFields } from './useForgotPasswordForm';

export type ForgotPasswordProps = {
  requestResetPasswordEmailAction: BoundAction<typeof requestResetPasswordEmail>,
  router: ContextRouter,
};

function ForgotPassword(props: ForgotPasswordProps) {
  const { requestResetPasswordEmailAction, router } = props;

  const toast = useToast();

  const { control, fields, form, isLoading, serverError } = useForgotPasswordForm({
    onSubmitSuccess: async (data: OutboundFields) => {
      await requestResetPasswordEmailAction(data.email);
      toast.success('Your password reset email has been sent.');
      router.push(getLoginUrl());
    },
  });

  return (
    <LogoOnlyLayout>
      <Box marginBottom="spacingLarge3X">
        <Helmet>
          <title>Forgot password?</title>
        </Helmet>
        <Container maxWidth="sizeContainerSmall">
          <Heading as="h1" variant="large" color="colorTextInverted" marginBottom="spacingLarge6X">
            Enter the email address that you registered with and we'll get you back on track.
          </Heading>
        </Container>
        <Container maxWidth="sizeContainerSmall2X">
          <form {...form}>
            {serverError && (
              <FormMessage>
                <ErrorTypeMessage error={serverError.message} />
              </FormMessage>
            )}
            <Stack spacing="spacingLarge">
              <Controller
                control={control}
                name="email"
                render={({ field }) => (
                  <TextField {...field} {...fields.email} withValidationIcon={false} variant="inverted" />
                )}
              />
              <Button type="submit" isFullWidth isLoading={isLoading} variant="ghost">
                Request password reset link
              </Button>
            </Stack>
          </form>
        </Container>
        <ForgotPasswordHelp />
      </Box>
    </LogoOnlyLayout>
  );
}

export default withRouter(
  (connect(null, { requestResetPasswordEmailAction: requestResetPasswordEmail })(
    ForgotPassword
  ): React.AbstractComponent<ForgotPasswordProps>)
);
