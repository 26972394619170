// @flow
import { useMutation } from '@apollo/client';

import type { CreateClass, CreateClassVariables, CreateClassInput } from 'src/graphql/types/generated/CreateClass';
import { trackEvent } from 'src/utils/tracking';
import { trackingEvents } from 'src/constants/tracking';

import CREATE_CLASS from './CreateClass.graphql';

type Params = {|
  classData: CreateClassInput,
  userId: string,
|};

type TrackingData = {|
  level_id: string,
  name: string,
  subject_code: string,
  user_id: string,
|};

export default function useCreateClass() {
  const [mutation, { loading }] = useMutation<CreateClass, CreateClassVariables>(CREATE_CLASS, {
    refetchQueries: ['GetDashboardClasses'],
    awaitRefetchQueries: true,
  });

  const createClass = async (params: Params) => {
    const { classData, userId } = params;

    const trackingData: TrackingData = {
      level_id: classData.levelId,
      name: classData.name,
      subject_code: classData.subjectCode,
      user_id: userId,
    };
    trackEvent(trackingEvents.classCreateRequested, trackingData);

    const { data } = await mutation({
      variables: {
        input: classData,
      },
    });

    return data?.createClass;
  };

  return {
    createClass,
    isCreatingClass: loading,
  };
}
