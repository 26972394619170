// @flow
import { forwardRef } from 'react';
import { useAsyncList } from '@getatomi/neon';

import Button from 'src/components/Button/Button';
import { Combobox, Item, type ComboboxProps } from 'src/components/Combobox/Combobox';
import Logger from 'src/utils/Logger';
import type { RegionCode } from 'src/types';
import useSearchIndex from 'src/hooks/useSearchIndex';

const log = new Logger('components/private/ComboboxSchool');

export type ComboboxSchoolProps = {|
  ...ComboboxProps,
  region: RegionCode,
  schoolId?: number,
  schoolName?: string,
  showPendingSchools?: boolean,
|};

export default forwardRef<ComboboxSchoolProps, HTMLInputElement>(function ComboboxSchool(props, ref) {
  const {
    helpText = 'Write your own selection if your school isn’t on the list.',
    onSelectionChange,
    region,
    showPendingSchools = false,
    schoolId,
    schoolName,
    ...otherProps
  } = props;

  const { error, index, retry } = useSearchIndex('schools');

  // Using async data with the Combobox:
  // https://react-spectrum.adobe.com/react-spectrum/ComboBox.html#asynchronous-loading
  const list = useAsyncList({
    async load({ filterText }) {
      if (index.current && filterText !== '') {
        try {
          const content = await index.current.search('query', {
            attributesToRetrieve: ['name', 'id'],
            distinct: true,
            filters: `region_code:"${region}"${showPendingSchools ? '' : ' AND status:active'}`,
            hitsPerPage: 100,
            query: filterText,
          });
          const items = content.hits ? content.hits.map((hit) => ({ label: hit.name, value: String(hit.id) })) : [];

          return { items };
        } catch (err) {
          log.error('Error loading index search', err);
        }
      } else if (schoolId && schoolName && filterText === schoolName) {
        // set initial items when provided
        return { items: [{ label: schoolName, value: String(schoolId) }] };
      } else if (filterText === '') {
        // clear items when filterText is empty
        return { items: [] };
      }
    },
    initialSelectedKeys: schoolId ? [String(schoolId)] : [],
    initialFilterText: schoolName,
    getKey: (item) => item.value,
  });

  const loadErrorHelpText = (
    <span>
      Sorry, we couldn’t load the schools.{' '}
      <Button onClick={retry} size="small" variant="text">
        Please retry
      </Button>
    </span>
  );

  const handleInputChange = (value: string) => {
    // Clear key if user deletes all text in the field
    if (value === '') {
      list.setSelectedKeys(new Set([null]));
    }
    list.setFilterText(value);

    // There was no selected item, and the user types a custom value: call onSelectionChange with
    // the custom value.
    if (list.selectedKeys.values().next().value == null) {
      onSelectionChange({ label: value, value: null });
    }
  };

  const handleSelectionChange = (key: string) => {
    // If the user replaces a selected item with a custom value, we need to clear the key and call
    // onSelectionChange with the custom value.
    if (!key) {
      list.setSelectedKeys(new Set([null]));
      onSelectionChange({ label: list.filterText, value: null });
      return;
    }

    // the user selects an item from the list
    const item = list.getItem(key);
    const itemText = item?.label;
    list.setSelectedKeys(new Set([key]));
    list.setFilterText(itemText);
    onSelectionChange(item);
  };

  const selectedKey = list.selectedKeys.values().next().value;

  return (
    <Combobox
      {...otherProps}
      ref={ref}
      allowsCustomValue
      selectedKey={selectedKey}
      onSelectionChange={handleSelectionChange}
      items={list.items}
      inputValue={list.filterText}
      onInputChange={handleInputChange}
      isLoading={list.isLoading}
      placeholder="Start typing your school name"
      helpText={error ? loadErrorHelpText : helpText}
    >
      {(item) => <Item key={item.value}>{item.label}</Item>}
    </Combobox>
  );
});
