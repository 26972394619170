// @flow
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useToast } from '@getatomi/neon';

/**
 * Processes the emails input by removing any trailing commas, new lines, and empty strings.
 */
function processEmailsInput(emails: string) {
  return emails
    .replace(/,\s*$/, '')
    .replace(/\n/g, ',')
    .split(',')
    .filter(Boolean)
    .map((email) => email.trim());
}

export type Fields = {|
  emails: string,
  role: string | null,
|};

export type OutboundFields = {|
  emails: Array<string>,
  role: string,
|};

const labels = {
  emails: 'Emails',
  role: 'Role',
};

const isEmailSchema = yup.string().email();
const resolver = yupResolver(
  yup.object().shape({
    emails: yup
      .string()
      .required()
      .label(labels.emails)
      // Custom test to validate each email address
      .test({
        name: 'emails',
        test(value) {
          const formattedEmails = processEmailsInput(value);
          const firstInvalidEmail = formattedEmails.find((email) => !isEmailSchema.isValidSync(email));

          return !firstInvalidEmail
            ? true
            : this.createError({
                message: `The email '${firstInvalidEmail}' is invalid`,
              });
        },
      }),
    role: yup.string().trim().nullable().required().label(labels.role),
  })
);

export default function useBulkInviteUsersForm({
  defaultValues,
  onSubmitFail,
  onSubmitSuccess,
}: {
  defaultValues: Fields,
  onSubmitFail: () => void,
  onSubmitSuccess: (data: OutboundFields) => Promise<void>,
}) {
  const { clearErrors, control, formState, handleSubmit, watch } = useForm({
    resolver,
    defaultValues,
  });
  const toast = useToast();

  const onSubmit = async (event: Event) => {
    event.preventDefault();
    clearErrors('serverError');
    handleSubmit(async (data) => {
      try {
        const transformOutboundData = {
          ...data,
          emails: processEmailsInput(data.emails),
        };
        await onSubmitSuccess(transformOutboundData);
      } catch (error) {
        toast.error(
          error.statusCode === 504
            ? 'This request took too long to process. We recommend you give it another go.'
            : 'There was an error inviting users to your subscription. Please try again.'
        );
        onSubmitFail();
      }
    })(event);
  };

  const emailsMessage = formState.errors.emails?.message ?? null;
  const roleMessage = formState.errors.role?.message ?? null;

  return {
    countEmails: processEmailsInput(watch('emails')).length,
    control,
    form: {
      onSubmit,
    },
    fields: {
      emails: {
        label: labels.emails,
        helpText: `Copy and paste a list of the email addresses of the users that you would like to add.`,
        validationText: emailsMessage,
        errorVariant: emailsMessage && 'error',
        type: 'email',
        isRequired: true,
      },
      role: {
        label: labels.role,
        validationText: roleMessage,
        errorVariant: roleMessage && 'error',
        isRequired: true,
      },
    },
    watch,
  };
}
